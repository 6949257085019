import React, { useContext, useEffect, useState } from "react";
import { Box, Fade, Typography } from "@mui/material";
import { check_code_git_integration_exists } from "@/services/Blar/Integrations";
import { list_repos } from "@/services/Blar/Repo_graph";
import { Context } from "@/contexts/ContextProvider";
import LoadingTypography from "./components/LoadingTypography";
import RepositoryBranchSelector from "@/components/Selectors/RepositoryBranchSelector";
import ContinueButton from "@/components/Buttons/ContinueButton";
import BackButton from "@/components/Buttons/BackButton";
import useStyles from "./styles/AddRepository";

interface CoreIntegrationProps {
  continueOnboarding: () => void;
  backOnboarding: () => void;
}

const AddRepository: React.FC<CoreIntegrationProps> = ({
  continueOnboarding,
  backOnboarding,
}) => {
  const classes = useStyles();
  const { showMessage } = useContext(Context);
  const [isGitConnected, setIsGitConnected] = useState<
    boolean | null | "pending"
  >(null);
  const [hasRepo, setHasRepo] = useState(false);
  const [loadingGit, setLoadingGit] = useState(false);
  const [loadingRepos, setLoadingRepos] = useState(false);
  const [selectedRepoName, setSelectedRepoName] = useState<string>("");

  const checkGitIntegrationExists = async (): Promise<boolean | "pending"> => {
    try {
      setLoadingGit(true);

      const res = await check_code_git_integration_exists();
      setIsGitConnected(res.data.is_integrated);
    } catch (error) {
      showMessage("error", "Failed to get code git integration");
    } finally {
      setLoadingGit(false);
    }

    return false;
  };

  useEffect(() => {
    if (isGitConnected) {
      checkIfReposExists();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isGitConnected]);

  const checkIfReposExists = async () => {
    try {
      setLoadingRepos(true);

      const repoRequest = await list_repos();
      const repositories = repoRequest.data.results;

      if (repositories.length > 0) {
        setHasRepo(true);
        setSelectedRepoName(repositories[0].name);
      }
    } catch (error) {
      showMessage("error", "Failed to get repositories");
    } finally {
      setLoadingRepos(false);
    }
  };

  useEffect(() => {
    checkGitIntegrationExists();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAddRepository = (repoName: string) => {
    setHasRepo(true);
    setSelectedRepoName(repoName);
  };

  return (
    <Box className={classes.root}>
      <Box>
      <LoadingTypography
        text="Add your first repository"
        loading={loadingRepos || loadingGit}
        stepCompleted={hasRepo}
      />
        <Typography className={classes.loggerToolBox}>
          {!hasRepo && (
            "We currently support Python, JavaScript, TypeScript, and Ruby (Beta). Let's get started!"
          )}
        </Typography>
        <Box className={classes.repositorySelectContainer}>
          {isGitConnected === true && !hasRepo && (
            <RepositoryBranchSelector 
              setHasRepo={() => setHasRepo(true)}
              defaultBranches={['main', 'master']}
              onAddRepository={(repoName: string) => handleAddRepository(repoName)}
            />
          )}
        </Box>
      </Box>
      {hasRepo && 
        <Fade in={true} timeout={800}>
          <Box sx={{
              display: 'flex', 
              alignItems: 'center', 
              backgroundColor: 'transparent', 
              padding: 2, 
              borderRadius: '8px', 
              border: '1px solid #30363d'
            }}>
            <Typography className={classes.loggerToolBox}>
              {`Perfect! We're now tracking pull requests in ${selectedRepoName} helping you save time and effort with our AI solutions`}
            </Typography>
          </Box>
        </Fade>
      }
      <Box className={classes.buttonContainer}>
        <BackButton backOnboarding={backOnboarding} />
        <ContinueButton
          continueOnboarding={continueOnboarding}
          disabled={!hasRepo}
        />
      </Box>
    </Box>
  );
};

export default AddRepository;
