import { blarClient } from "./Client";

export const getSummary = async () => {
  const response = await blarClient.request("get", "/users/company-summary");
  return response.data;
};

export const getOpenPullRequests = async (repoId: string) => {
  const response = await blarClient.request("get", `/integrations/list-open-prs/?repo_id=${repoId}`);
  console.log(response.data);
  return response.data;
};

export const postRunPRReview = async (params: RunPRReviewParams) => {
  console.log(params);
  return blarClient.request("post", "/integrations/run-pr-review/", {
    repo_name: params.repo_name,
    pr_number: params.pr_number
  });
};

export interface RunPRReviewParams {
  repo_name: string;
  pr_number: number;
}
