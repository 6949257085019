import React, { useContext, useEffect, useState } from "react";
import { Box, Tooltip, Typography } from "@mui/material";
import { check_code_git_integration_exists } from "@/services/Blar/Integrations";
import { Context } from "@/contexts/ContextProvider";
import LoadingTypography from "./components/LoadingTypography";
import ContinueButton from "@/components/Buttons/ContinueButton";
import { fetchUserInfo } from "@/services/Blar/User";
import { integrations } from "@/components/Integrations/integrations";
import { IntegrationCardProps } from "@/pages/Settings/components/Integrations/types";
import IconButton from "@mui/material/IconButton";
import { handleRedirectToGitHubApp } from "@/integrations/github";
import { handleRedirectToBitbucketApp } from "@/integrations/bitbucket";
import { AxiosError } from "axios";
import { handleRedirectToGitlabApp } from "@/integrations/gitlab";
import BackButton from "@/components/Buttons/BackButton";
import useStyles from "./styles/Integrations";
interface CoreIntegrationProps {
  continueOnboarding: () => void;
  backOnboarding: () => void;
}

const CoreIntegrationComponent: React.FC<CoreIntegrationProps> = ({
  continueOnboarding,
  backOnboarding,
}) => {
  const classes = useStyles();
  const { showMessage, userId } = useContext(Context);

  const [isGitConnected, setIsGitConnected] = useState<boolean | null | "pending">(null);
  const [gitProvider, setGitProvider] = useState<string | null>(null);
  const [loadingGit, setLoadingGit] = useState(false);
  const [hasGitUser, setHasGitUser] = useState(false);

  const [loadingUser, setLoadingUser] = useState(false);

  const checkGitIntegrationExists = async (): Promise<boolean | "pending"> => {
    try {
      setLoadingGit(true);

      const res = await check_code_git_integration_exists();
      setIsGitConnected(res.data.is_integrated);
      setGitProvider(res.data.git_provider);
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === 404) {
          setIsGitConnected(false);
          return false;
        }
      }
      showMessage("error", "Failed to get GitHub integration");
    } finally {
      setLoadingGit(false);
    }

    return false;
  };

  const loadUserInfo = async () => {
    if (gitProvider !== "github") return;

    try {
      setLoadingUser(true);
      const users = await fetchUserInfo();
      if (users.external_profiles.length === 0) {
        setHasGitUser(false);
      } else {
        setHasGitUser(true);
      }
    } catch (error) {
    } finally {
      setLoadingUser(false);
    }
  };

  useEffect(() => {
    if (isGitConnected) {
      loadUserInfo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isGitConnected]);

  useEffect(() => {
    checkGitIntegrationExists();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const codeVersioningIntegrations = () => {
    if (gitProvider) {
      return integrations.filter(
        (integration) => integration.dbName === gitProvider
      );
    } 

    return integrations.filter(
      (integration: IntegrationCardProps) =>
        integration.category === "code_versioning"
    );
  };

  const oauthRedirect = async (integration: string) => {
    if (integration === "github") {
      handleRedirectToGitHubApp(showMessage);
    } else if (integration === "bitbucket") {
      handleRedirectToBitbucketApp(showMessage);
    } else if (integration === "gitlab") {
      handleRedirectToGitlabApp(showMessage);
    }
  };

  const showUserLinkStep = () => {
    return isGitConnected === true && !hasGitUser && gitProvider === "github";
  }

  return (
    <Box className={classes.root}>
      <LoadingTypography
        text="Connect to your code versioning system"
        loading={loadingGit}
        stepCompleted={isGitConnected === true}
      />
      <Box className={classes.integrationSection}>
        {codeVersioningIntegrations().map((integration) => (
          <Box key={integration.dbName}>
            <Tooltip title={integration.name}>
              <IconButton
                key={integration.dbName}
                className={`${classes.integrationCard}`}
                onClick={() => oauthRedirect(integration.dbName)}
                disabled={isGitConnected === true}
              >
                <img src={integration.logo} alt={integration.name} className={`${
                  integration.dbName === gitProvider && isGitConnected === true ? classes.successBorder : ""
                }`}/>
              </IconButton>
            </Tooltip>
          </Box>
        ))}
      </Box>
      {(isGitConnected === "pending") && (
        <Typography variant="body1" color="info" mt={2}>
          Waiting for approval, please ask your organization admin to approve
          the request.
        </Typography>
      )}
      {showUserLinkStep() && (
        <>
          <LoadingTypography
            text="Select your user"
            loading={loadingUser}
            stepCompleted={hasGitUser}
          />
        </>
      )}
      <Box className={classes.buttonContainer}>
        <BackButton backOnboarding={backOnboarding} />
        <ContinueButton
          continueOnboarding={continueOnboarding}
          disabled={isGitConnected === false}
        />
      </Box>
    </Box>
  );
};

export default CoreIntegrationComponent;
