import React from "react";
import { Modal, Box, Typography, IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

interface BaseModalProps {
  open: boolean;
  onClose: () => void;
  title: string;
  children: React.ReactNode;
  actions: React.ReactNode;
}

const BaseModal: React.FC<BaseModalProps> = ({ 
  open, 
  onClose, 
  title, 
  children, 
  actions 
}) => (
  <Modal
    open={open}
    onClose={onClose}
    aria-labelledby="modal-title"
    aria-describedby="modal-description"
  >
    <Box sx={{
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '80%',
      maxWidth: '800px',
      bgcolor: '#161B22',
      border: '1px solid #30363d',
      borderRadius: '8px',
      boxShadow: 24,
      p: 4,
    }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Typography variant="h6" component="h2">
          {title}
        </Typography>
        <IconButton onClick={onClose} sx={{ color: 'text.secondary' }}>
          <CloseIcon />
        </IconButton>
      </Box>
      {children}
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
        {actions}
      </Box>
    </Box>
  </Modal>
);

export default BaseModal;

