import { Paper } from "@mui/material";
import { useTheme } from "@mui/material/styles";

export default function CustomPaper(props: any) {
    const theme = useTheme();
    return (
      <Paper 
        {...props} 
        style={{ 
          padding: 0, 
          backgroundColor: theme.palette.background.default
        }} 
        sx={{
          '& .MuiAutocomplete-option[data-focus="true"]': {
            backgroundColor: theme.palette.background.paper,
          },
          '& .MuiAutocomplete-option.Mui-focused': {
            backgroundColor: theme.palette.background.paper, 
          },
          '& .MuiAutocomplete-option:hover': {
            backgroundColor: theme.palette.background.paper,
          }
        }}
      />
    );
  };