import { Typography, CircularProgress } from "@mui/material";
import React from "react";
import CheckIcon from "@mui/icons-material/Check";

interface LoadingTypographyProps {
  loading: boolean;
  stepCompleted: boolean;
  text: string;
}

const LoadingTypography: React.FC<LoadingTypographyProps> = ({
  loading,
  stepCompleted,
  text,
}) => {
  return (
    <Typography
      variant="h4"
      align="center"
      fontSize={24}
      sx={{
        lineHeight: "32px",
        marginBottom: "20px",
      }}
    >
      {text}
      {stepCompleted && (
        <CheckIcon
          sx={{ fontSize: "1.5rem", marginLeft: "5px" }}
          color="success"
        />
      )}
      {loading && <CircularProgress size={24} sx={{ marginLeft: "10px" }} />}
    </Typography>
  );
};

export default LoadingTypography;
