import React from "react";
import { Typography, Button, Box } from "@mui/material";
import useStyles from "@/pages/Auth/Onboarding/styles/Guidelines";
import { Fade } from "@mui/material";
interface YesNoQuestionProps {
  question: string;
  onYes: () => void;
  onNo: () => void;
}

const YesNoQuestion: React.FC<YesNoQuestionProps> = ({ question, onYes, onNo }) => {
  const classes = useStyles();
  
  return (
    <Fade  in={true} timeout={800}>
      <Box sx={{ marginTop: "24px" }}>
        <Typography variant="body1" color="text.primary">
          {question}
        </Typography>
        <Box sx={{ display: 'flex', gap: 1, marginTop: "10px" }}>
          <Button 
            className={classes.actionButton}
            variant="outlined"
            onClick={onYes}
          >
            Yes
          </Button>
          <Button 
            className={classes.actionButton}
            variant="outlined"
            onClick={onNo}
          >
            No
          </Button>
        </Box>
      </Box>
    </Fade>
  );
};

export default YesNoQuestion;
