import { git_oauth } from "@/services/Blar/Integrations";
import { AlertColor } from "@mui/material";
import { URLSearchParams } from "url";

const REDIRECT_TIMEOUT = 3 * 1000;

export function redirectToGitlabApp() {
  const gitlabAuthUrl = process.env.REACT_APP_GITLAB_APP_URL!;
  const redirectUri = process.env.REACT_APP_GITLAB_REDIRECT_URI!;

  window.location.href = `${gitlabAuthUrl}&redirect_uri=${encodeURIComponent(redirectUri)}`;
}

const handleGitlabIntegration = async (
  showMessage: (type: AlertColor, message: string) => void,
  searchParams: URLSearchParams
) => {
  try {
    const code = searchParams.get("code");

    const response = await git_oauth(code!, 'gitlab');
    const { message, redirect_url } = response.data;
    showMessage("success", message);
    setTimeout(() => (window.location.href = redirect_url), REDIRECT_TIMEOUT);
  } catch (error) {
    showMessage("error", "Failed to connect Gitlab");
  }
};

export const handleRedirectToGitlabApp = async (
  showMessage: (type: AlertColor, message: string) => void
) => {
  showMessage("info", "Redirecting to Gitlab for authentication");
  setTimeout(() => redirectToGitlabApp(), REDIRECT_TIMEOUT);
  return;
};

export default handleGitlabIntegration;
