import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import {
  get_company_feature_flags,
  patch_company_feature_flags,
} from "@/services/Blar/Company";
import SecretModal from "./SecretModal"; // Adjust the path as needed

interface FeatureFlags {
  pull_request_labels: boolean;
  roast: boolean;
}

export default function FeatureToggle() {
  const [pullRequestLabels, setPullRequestLabels] = useState<boolean>(true);
  const [roast, setRoast] = useState<boolean>(false);
  // Whether the secret (roast feature) is available
  const [showSecret, setShowSecret] = useState<boolean>(false);

  // Controls the SecretModal visibility
  const [secretModalOpen, setSecretModalOpen] = useState<boolean>(false);

  useEffect(() => {
    const fetchFeatureFlags = async () => {
      try {
        const response = await get_company_feature_flags();
        setPullRequestLabels(response.data.feature_flags.pull_request_labels);
        setRoast(response.data.feature_flags.roast);
      } catch (error) {
        console.error("Error fetching feature flags:", error);
      }
    };

    fetchFeatureFlags();
  }, []);

  const handleToggle = async (
    feature: keyof FeatureFlags,
    newValue: boolean
  ) => {
    try {
      const response = await patch_company_feature_flags({
        pull_request_labels:
          feature === "pull_request_labels" ? newValue : pullRequestLabels,
        roast: feature === "roast" ? newValue : roast,
      });

      if (feature === "pull_request_labels") {
        setPullRequestLabels(response.data.feature_flags.pull_request_labels);
      } else if (feature === "roast") {
        setRoast(response.data.feature_flags.roast);
      }
    } catch (error) {
      console.error(`Error updating ${feature} feature flag:`, error);
    }
  };

  const handleSecretToggle = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      // Open the modal instead of revealing the secret immediately.
      setSecretModalOpen(true);
    } else {
      setShowSecret(false);
    }
  };

  // Callback from the modal once the flow completes.
  const handleSecretModalComplete = (confirm: boolean) => {
    if (confirm) {
      setShowSecret(true);
    }
    setSecretModalOpen(false);
  };

  return (
    <Box sx={{ width: "100%", margin: "auto", textAlign: "left" }}>
      <Grid container spacing={5} alignItems="center" justifyContent="center">
        {/* Pull Request Labels Toggle */}
        <Grid item xs={6}>
          <Typography variant="h6">Pull Request Labels</Typography>
          <Typography variant="body2" color="textSecondary">
            Labels help categorize PRs by size and impact.
          </Typography>
          <FormControlLabel
            control={
              <Switch
                checked={pullRequestLabels}
                onChange={(e) =>
                  handleToggle("pull_request_labels", e.target.checked)
                }
              />
            }
            label="Enable"
          />
        </Grid>

        {/* Secret Feature / Roast Toggle */}
        <Grid item xs={6}>
          {!showSecret ? (
            <>
              <Typography variant="h6">Secret Feature 🤫</Typography>
              <Typography variant="body2" color="textSecondary">
                Curious minds only... 🔎
              </Typography>
              <FormControlLabel
                control={
                  <Switch checked={false} onChange={handleSecretToggle} />
                }
                label="Reveal"
              />
            </>
          ) : (
            <>
              <Typography variant="h6">The Roast Feature 🔥</Typography>
              <Typography variant="body2" color="textSecondary">
                Are you ready for the truth? 😈🔥
              </Typography>
              <FormControlLabel
                control={
                  <Switch
                    checked={roast}
                    onChange={(e) => handleToggle("roast", e.target.checked)}
                  />
                }
                label="Enable"
              />
            </>
          )}
        </Grid>
      </Grid>
      <SecretModal
        open={secretModalOpen}
        onClose={() => setSecretModalOpen(false)}
        onComplete={handleSecretModalComplete}
      />
    </Box>
  );
}
