import React, { useState } from "react";
import {
  Box,
  Grid,
  Typography,
  Button,
  Tabs,
  Tab,
} from "@mui/material";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import ArticleIcon from "@mui/icons-material/Article";
import TimelineIcon from "@mui/icons-material/Timeline";

import PastExecutionsList from "./PastExecutionsList";
import ExecutionReport from "./ExecutionReport";
import AgentExecutionForm from "./AgentExecutionForm";
import GraphView from "./Graph/GraphView";
import { Execution } from "./types";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tab-panel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
      style={{ height: "100%" }}
    >
      {value === index && <Box sx={{ p: 2, height: "100%" }}>{children}</Box>}
    </div>
  );
}

const ManualRunPage: React.FC = () => {
  const [selectedExecution, setSelectedExecution] = useState<Execution | null>(null);
  const [refreshExecutions, setRefreshExecutions] = useState<number>(Date.now());
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [selectedTab, setSelectedTab] = useState<number>(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  return (
    <Box
      sx={{
        height: "100vh",         // fill viewport height
        overflow: "hidden",      // prevent outer scroll, but internal scrolls remain visible
        display: "flex",
        flexDirection: "column",
      }}
    >
      {/* Top Bar with Execute Button */}
      <Box
        sx={{
          flexShrink: 0,         // ensure top bar doesn't shrink
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          p: 2,
          borderBottom: "1px solid",
          borderColor: "divider",
        }}
      >
        <Typography variant="h5">Manual Run</Typography>
        <Button
          variant="contained"
          color="blarSuccess"
          startIcon={<PlayArrowIcon />}
          onClick={(e) => setAnchorEl(e.currentTarget)}
        >
          Execute Agent
        </Button>
      </Box>

      {/* Agent Execution Form (Anchor Menu) */}
      <AgentExecutionForm
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        setRefreshExecutions={setRefreshExecutions}
      />

      {/* Main Content Area */}
      <Box
        sx={{
          flexGrow: 1,           // occupy remaining vertical space
          overflow: "hidden",    // prevent overall scrolling; inner elements will manage scroll as needed
        }}
      >
        <Grid container spacing={2} sx={{ height: "100%" }}>
          {/* Left Panel: Past Executions */}
          <Grid item xs={8} md={4} sx={{ height: "100%" }}>
            <Box
              sx={{
                height: "100%",
                overflowY: "auto",  // allow inner scroll if needed
                p: 1,
              }}
            >
              <PastExecutionsList
                selectedId={selectedExecution?.id || null}
                onSelect={setSelectedExecution}
                refreshExecutions={refreshExecutions}
              />
            </Box>
          </Grid>

          {/* Right Panel: Report and Graph */}
          <Grid item xs={4} md={8} sx={{ height: "100%" }}>
            {!selectedExecution ? (
              <Box
                sx={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  p: 2,
                }}
              >
                <Box
                  sx={{
                    maxWidth: 500,
                    width: "100%",
                    textAlign: "center",
                    border: "1px dashed #ccc",
                    borderRadius: 2,
                    p: 4,
                  }}
                >
                  <Box
                    component="img"
                    src="/logos/BlarIconWhite.svg"
                    sx={{
                      width: 120,
                      margin: "0 auto 16px auto",
                      borderRadius: 1,
                    }}
                  />
                  <Typography variant="h6" gutterBottom>
                    No Execution Selected
                  </Typography>
                  <Typography variant="body2" color="text.secondary" mb={3}>
                    Select an execution from the list or create a new one to get started.
                  </Typography>
                  <Button
                    variant="contained"
                    color="blarSuccess"
                    startIcon={<PlayArrowIcon />}
                    onClick={(e) => setAnchorEl(e.currentTarget)}
                  >
                    Execute Agent
                  </Button>
                </Box>
              </Box>
            ) : (
              <Box sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
                <Tabs
                  value={selectedTab}
                  onChange={handleTabChange}
                  aria-label="Report and Graph Tabs"
                  indicatorColor="primary"
                  textColor="primary"
                  sx={{ flexShrink: 0 }}
                >
                  <Tab
                    icon={<ArticleIcon />}
                    iconPosition="start"
                    label="Report"
                    id="tab-0"
                    aria-controls="tab-panel-0"
                  />
                  <Tab
                    icon={<TimelineIcon />}
                    iconPosition="start"
                    label="Graph"
                    id="tab-1"
                    aria-controls="tab-panel-1"
                  />
                </Tabs>
                <Box sx={{ flexGrow: 1, overflowY: "auto" }}>
                  <TabPanel value={selectedTab} index={0}>
                    <ExecutionReport selectedExecution={selectedExecution} />
                  </TabPanel>
                  <TabPanel value={selectedTab} index={1}>
                    <GraphView reportId={selectedExecution?.id} />
                  </TabPanel>
                </Box>
              </Box>
            )}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default ManualRunPage;
