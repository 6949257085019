import React from "react";
import { Box, Typography } from "@mui/material";
import { NodeProps, Handle, Position } from "reactflow";
import DataObjectOutlinedIcon from "@mui/icons-material/DataObjectOutlined";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import { MyNode } from "./GraphView"; // Import the custom node type

const getIcon = (labels: string[] = []) => {
    if (labels.includes("CLASS")) {
        return <DataObjectOutlinedIcon sx={{ color: "#000000" }} />;
    } else if (labels.includes("FILE")) {
        return <InsertDriveFileOutlinedIcon sx={{ color: "#000000" }} />;
    } else if (labels.includes("FUNCTION")) {
        return (
            <img
                src="/icons/function-icon.png"
                alt="Function Icon"
                style={{ color: "#000000", width: "22px", height: "22px" }}
            />
        );
    } else {
        return <InsertDriveFileOutlinedIcon sx={{ color: "#000000" }} />;
    }
};

const CustomNode: React.FC<NodeProps<MyNode>> = ({ id, data }) => {
    return (
        <div>
            <Handle type="target" position={Position.Top} />
            <Box
                className="custom-drag-handle"
                sx={{
                    display: "flex",
                    alignItems: "center",
                    padding: 1,
                    borderRadius: 1,
                    border: "1px solid #777",
                    backgroundColor: data.relevant ? "#36CF8F" : "#FFF",
                    cursor: "pointer",
                }}
            >
                <Box sx={{ mr: 1, display: "flex", alignItems: "center" }}>
                    {getIcon(data.labels)}
                </Box>
                <Typography variant="body2" sx={{ fontWeight: "bold", color: "#000" }}>
                    {data.label}
                </Typography>
            </Box>
            <Handle type="source" position={Position.Bottom} id="b" />
        </div>
    );
};

// Wrap the component with React.memo to avoid unnecessary re-renders when props remain unchanged.
export default React.memo(CustomNode);