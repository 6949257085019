import { Chip } from "@mui/material";
import { PropTypes } from "@mui/material";

export type StateType = "open" | "acknowledged" | "closed";

interface StateChipProps {
  state: StateType;
}

const StateChip = ({ state }: StateChipProps) => {
  const chipStyle = {
    borderRadius: "5px",
    border: "2px solid",
    fontFamily: "Ubuntu Sans Mono",
    fontWeight: "bold",
    "& .MuiChip-label": {
      py: "2px",
      px: "4px",
      fontSize: "16px"
    }
  };

  const stateConfig = {
    closed: {
      color: "blarSuccess" as PropTypes.Color,
      label: "Closed"
    },
    acknowledged: {
        color: "blarHigh" as PropTypes.Color,
        label: "Acknowledged"
    },
    open: {
        color: "blarCritical" as PropTypes.Color,
        label: "Open"
    }
  };

  const config = stateConfig[state];

  return (
    <Chip
      sx={{
        ...chipStyle,
        borderColor: `${config.color}.main`,
        color: `${config.color}.main`
      }}
      variant="outlined"
      label={config.label}
    />
  );
};

export default StateChip; 