export const makeContentEditable = (
  element: HTMLElement,
  originalValue: string,
  onChange: (newValue: string) => Promise<void>
) => {
  element.setAttribute('contenteditable', 'true');
  element.focus();

  const range = document.createRange();
  range.selectNodeContents(element);
  const selection = window.getSelection();
  selection?.removeAllRanges();
  selection?.addRange(range);

  const handleBlur = async () => {
    const newValue = element.textContent || '';
    element.removeAttribute('contenteditable');
    if (newValue && newValue !== originalValue) {
      await onChange(newValue);
    }
    element.removeEventListener('blur', handleBlur);
    element.removeEventListener('keydown', handleKeyDown);
  };

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      element.blur();
    }
    if (e.key === 'Escape') {
      element.textContent = originalValue;
      element.blur();
    }
  };

  element.addEventListener('blur', handleBlur);
  element.addEventListener('keydown', handleKeyDown);
}; 