import React, { useState, useEffect, useContext } from "react";
import { Box, Typography, CircularProgress, Stepper, Step, StepLabel } from "@mui/material";
import AuthForm from "./AuthForm";
import CoreIntegrationComponent from "./Onboarding/CoreIntegrations";
import AddRepository from "./Onboarding/AddRepository";
import SelectPlan from "./Onboarding/SelectPlan";
import WriteGuidelines from "./Onboarding/WriteGuidelines";
import SelectSeats from "./Onboarding/SelectSeats";
import Fade from "@mui/material/Fade";
import { useNavigate } from "react-router-dom";
import { Context } from "@/contexts/ContextProvider";
import { update_company } from "@/services/Blar/Company";
import { OnboardingProvider } from "./Onboarding/contexts/OnboardingContext";
import useStyles from "./styles/Onboarding";
import ReviewFirstPRComponent from "./Onboarding/ReviewFirstPR";

const onboardingSteps: React.FC<any>[] = [
  AuthForm,
  CoreIntegrationComponent,
  AddRepository,
  SelectSeats,
  SelectPlan,
  WriteGuidelines,
  ReviewFirstPRComponent,
];

const Onboarding: React.FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const {
    initialOnboardingStep,
    showMessage,
    isOnboarding,
    setIsOnboarding,
    isEmailVerified,
    isUserSignIn,
  } = useContext(Context);
  const [onboardingStep, setOnboardingStep] = useState<number | null>(null);

  useEffect(() => {
    if (initialOnboardingStep) {
      setOnboardingStep(initialOnboardingStep);
    } else {
      setOnboardingStep(0);
    }
  }, [initialOnboardingStep]);

  useEffect(() => {
    if (!isOnboarding && isUserSignIn) {
      navigate("/pull-requests");
    }
  }, [isOnboarding, isUserSignIn]);

  useEffect(() => {
    if (isEmailVerified === false) {
      navigate("/verify-email");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEmailVerified]);

  const continueOnboarding = async () => {
    if (onboardingStep === onboardingSteps.length - 1) {
      // Finish Onboarding
      try {
        await update_company({
          finished_onboarding: true,
        });
        setIsOnboarding(false);
        navigate("/pull-requests");
      } catch (error) {
        showMessage("error", "Network error");
      }
      return;
    }
    try {
      await update_company({ onboarding_step: onboardingStep! + 1 });
      setOnboardingStep(onboardingStep! + 1);
    } catch (error) {
      showMessage("error", "Network error");
    }
  };

  const backOnboarding = async () => {
    if (onboardingStep === 0) return;
    setOnboardingStep(onboardingStep! - 1);
  };

  return (
    <Box className={classes.root}>
      {onboardingStep !== null ? (
        <Box
            sx={{ 
              background: '#090C13',
              height: '100vh',
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              position: 'relative',
              margin: 'auto'
            }}
          >
            {/* Logo */}
            <Box sx={{ 
              position: 'absolute',
              left: 40,
              top: 40,
              display: 'flex',
              gap: 2
            }}>
              <img src="/logos/WhiteLogo.svg" alt="Blar" />
              <img src="/logos/BlarLetters.svg" alt="Blar" />
            </Box>


            {/* Main Card Container */}
            <Fade in={true} timeout={1000}>
            <Box sx={{ 
              width: '100%',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              p: 2
            }}>
              {/* Title Text */}
              <Typography
                variant="h4"
                align="center"
                sx={{
                  fontSize: 24,
                  lineHeight: "32px",
                  color: 'common.white',
                  maxWidth: '600px',
                  mx: 'auto',
                  textAlign: 'center'
                }}
              >
                Master code reviews and technical debt<br />
                with your personalized AI assistant
              </Typography>
              <Box 
                sx={{ 
                  width: {
                    xs: '100%',
                    sm: '90%',
                    md: '80%',
                    lg: '60%',
                    xl: '50%'
                  },
                  backgroundColor: 'background.paper',
                  borderRadius: 2,
                  boxShadow: 3,
                  height: {
                    sm: '90%',
                    lg: '80%',
                    xl: '70%'
                  },
                  overflow: 'auto',
                  mt: 4
                }}
              >
                {/* Header Section */}
                {onboardingStep > 0 && <Box sx={{ 
                  p: 3,
                  borderBottom: 1,
                  borderColor: 'divider',
                  height: '10%'
                }}>
                  <Box sx={{ 
                    maxWidth: '600px',
                    margin: '0 auto'
                  }}>
                    <Stepper 
                      activeStep={onboardingStep - 1} 
                      alternativeLabel
                      sx={{
                        '& .MuiStepIcon-root': {
                          color: '#1E1E1E',
                          '&.Mui-active': {
                            color: '#2F80ED'
                          },
                          '&.Mui-completed': {
                            color: '#2F80ED'
                          }
                        },
                        '& .MuiStepIcon-text': {
                          fill: '#fff',
                          fontWeight: 500
                        },
                        '& .MuiStepConnector-root': {
                          left: 'calc(-50% + 12px)',
                          right: 'calc(50% + 12px)'
                        },
                        '& .MuiStepConnector-line': {
                          borderTopWidth: 1,
                          borderColor: '#2F80ED'
                        },
                        '& .Mui-disabled .MuiStepIcon-root': {
                          border: '1px solid #2F80ED',
                          borderRadius: '50%',
                          color: 'transparent',
                          '& .MuiStepIcon-text': {
                            fill: '#2F80ED'
                          }
                        }
                      }}
                    >
                      {onboardingSteps.slice(1, -1).map((_, index) => (
                        <Step key={index}>
                          <StepLabel />
                        </Step>
                      ))}
                    </Stepper>
                  </Box>
                </Box>}

                {/* Content Area */}
                <Box sx={{
                  mx: 'auto',
                  width: '100%',
                  height: '90%',
                  flex: 1,
                  display: 'flex',
                  flexDirection: 'column'
                }}>
                  <OnboardingProvider>
                    {React.createElement(onboardingSteps[onboardingStep], {
                      continueOnboarding,
                      backOnboarding,
                    })}
                  </OnboardingProvider>
                </Box>
              </Box>
            </Box>
            </Fade>
          </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <CircularProgress size={80} />
        </Box>
      )}
    </Box>
  );
};

export default Onboarding;
