import { blarClient } from "./Client";

export const get_code_errors = async (params?: {
  search?: string;
  state?: string;
  priority?: string;
  assigned_to?: string;
  start_date?: string;
  end_date?: string;
  repo?: string;
}) => {
  const cleanParams = Object.fromEntries(
    Object.entries(params || {}).filter(([_, v]) => v !== undefined && v !== "")
  );

  const response = await blarClient.request(
    "get", 
    "/integrations/code-errors", 
    null,
    cleanParams
  );
  return response.data;
};
