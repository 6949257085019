import { UserFilters, UserResponse } from "@/interfaces/ICompany";
import { blarClient } from "./Client";
import { AxiosResponse } from "axios";
import { PaginationParams } from "@/interfaces/IApi";

export const get_company = async () => {
  return blarClient.request("get", `/users/company`);
};

export const update_company = async (params: any) => {
  return blarClient.request("put", `/users/company`, params);
};

export const list_users = async (
  paginationParams: PaginationParams,
  filters?: UserFilters
): Promise<AxiosResponse<UserResponse>> => {
  const params = { ...paginationParams, ...filters };
  return blarClient.request("get", `/users/company-users`, null, params);
};

export const list_invitations = async () => {
  return blarClient.request("get", `/users/invitations`);
};

interface InvitationOutput {
  invitation_link: string;
}

export const create_invitation = async (): Promise<AxiosResponse<InvitationOutput>> => {
  return blarClient.request("post", `/users/invitations`, {});
};

export const delete_invitation = async (token: string) => {
  return blarClient.request("delete", `/users/invitations/${token}`);
};

export const get_invitation = async (token: string) => {
  return blarClient.request("get", `/users/invitations/validate/${token}`);
};

export const get_company_minimum_severity_level = async () => {
  return blarClient.request("get", `/users/severity-level`);
};

export const patch_company_minimum_severity_level = async (
  severityLevel: string
) => {
  return blarClient.request("patch", `/users/severity-level`, {
    minimum_issue_severity: severityLevel,
  });
};

export const get_company_feature_flags = async () => {
  return blarClient.request("get", `/users/company-feature-flags`);
};

/** NEW: Updates the pull_request_labels feature flag */
export const patch_company_feature_flags = async (data: {
  pull_request_labels: boolean;
  roast: boolean;
  [key: string]: boolean;
}) => {
  return blarClient.request("patch", `/users/company-feature-flags`, data);
};
