import { WikiFile, WikiFolder } from "@/pages/Wiki/types";
import { blarClient } from "./Client";


// List repo folders
export const listRepoFolders = async () => {
  const response = await blarClient.request("get", "/wiki/repo-folders/");
  return response.data;
};

// Get folder contents
export const getFolderContents = async (parentId: number | null = null) => {
  const response = await blarClient.request(
    "get",
    "/wiki/folder-contents/",
    null,
    {
      parent_id: parentId,
    }
  );
  return response.data;
};

// Get file content
export const getFileContent = async (fileId: number) => {
  const response = await blarClient.request("get", `/wiki/files/${fileId}/`);
  return response.data.content;
};

// Create folder
export const createFolder = async (name: string, parentId: number | null) => {
  const response = await blarClient.request("post", "/wiki/folders/", {
    name,
    parent: parentId,
  });
  return response.data;
};

// Create file
export const createFile = async (
  name: string,
  parentId: number | null,
  content: string | null = " "
) => {
  const response = await blarClient.request("post", "/wiki/files/", {
    name,
    folder: parentId,
    content,
  });
  return response.data;
};

// Update folder
export const updateFolder = async (folderId: number, folder: WikiFolder) => {
  const response = await blarClient.request(
    "put",
    `/wiki/folders/${folderId}/`,
    {
      name: folder.name,
      parent: folder.parent,
    }
  );
  return response.data;
};

// Update file
export const updateFile = async (fileId: number, file: WikiFile) => {
  const response = await blarClient.request("put", `/wiki/files/${fileId}/`, {
    name: file.name,
    content: file.content,
    folder: file.folder,
  });
  return response.data;
};

// Delete folder
export const deleteFolder = async (folderId: number) => {
  const response = await blarClient.request(
    "delete",
    `/wiki/folders/${folderId}/`
  );
  return response.data;
};

// Delete file
export const deleteFile = async (fileId: number) => {
  const response = await blarClient.request("delete", `/wiki/files/${fileId}/`);
  return response.data;
};

// Create/get wiki chat room
export const createWikiChatRoom = async (fileId: number) => {
  const response = await blarClient.request("post", `/agents/wiki-chat/${fileId}/`);
  return response.data;
};

// Get list of templates
export const getTemplates = async () => {
  const response = await blarClient.request("get", "wiki/templates/");
  return response.data;
};

// Get template content
export const getTemplateContent = async (templatePath: string) => {
  const response = await blarClient.request("get", `wiki${templatePath}`);
  return response.data;
};

// Create file from template
export const createFileFromTemplate = async (templateName: string, repoId: string) => {
  const response = await blarClient.request("post", "/wiki/templates/", {
    template_name: templateName,
    repo_id: repoId,
  });
  return response.data;
};

// Import from cursor rules
export const createWikiFromCursorRules = async (file: File, repoId: string, folderId: string) => {
  const formData = new FormData();
  formData.append('cursor_rules', file);
  if (repoId !== "") formData.append('repo', repoId);
  if (folderId !== "") formData.append('folder', folderId);
  
  const response = await blarClient.request(
    "post", 
    "/wiki/create-wiki-from-cursor-rules/",
    formData,
    { headers: { 'Content-Type': 'multipart/form-data' } }
  );
  return response.data;
};

// Import from docs (string of docs)
export const createWikiFromDocs = async (content: string, repoId: string) => {
  const response = await blarClient.request("post", "/wiki/import/", {
    content: content,
    repo: repoId,
  });
  return response.data;
};

// Get wiki rules
export const getWikiRules = async (repoId: string) => {
  const response = await blarClient.request("get", `/wiki/onboarding-wiki/?repo_id=${repoId}`);
  return response.data;
};