import { Box, Grid, Link, Typography } from "@mui/material";
import { list_repos } from "@/services/Blar/Repo_graph";
import { useContext, useEffect, useState } from "react";
import SeveritySlider from "./SeveritySlider";
import { Context } from "@/contexts/ContextProvider";
import { NotificationsContextProvider } from "@/contexts/NotificationContextProvider";
import { RepoPaginatedResponse } from "@/pages/Settings/components/Repos/types";
import { TableRowData } from "@/components/Tables/types";
import TableWithActions from "./TableWithActions";
import { convertReposToRow } from "./helper";
import LabelToggle from "./LabelToogle";

const defaultReposTitles = ["Name", "GitHub Url", "PR Branches", "Last Sync"];

const ReposTable = () => {
  const [reposRow, setReposRow] = useState<TableRowData[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [createdObject, setCreatedObject] = useState<number | null>(null);
  const { showMessage, access, refresh, companyTier } = useContext(Context);

  useEffect(() => {
    async function fetchReposData() {
      try {
        setLoading(true);
        const reposResult = (await list_repos()).data as RepoPaginatedResponse;
        const repoRows = convertReposToRow(reposResult);
        setReposRow(repoRows);
      } catch (error) {
        showMessage("error", `Failed to fetch repos`);
      } finally {
        setLoading(false);
      }
    }

    if (createdObject !== null) {
      fetchReposData();
    }
  }, [createdObject]);

  // Initial fetch
  useEffect(() => {
    setCreatedObject(Date.now());
  }, []);

  return (
    <Box display={"flex"} flexDirection={"column"} gap={4} sx={{ p: 0, m: 0 }}>
      <Typography
        variant="h4"
        fontWeight={"medium"}
        sx={{ mb: 2 }}
        textAlign={"left"}
      >
        Pull Request Review Settings
      </Typography>
      <Grid container spacing={4} xs={12}>
        <Grid item>
          <Typography variant="h5" sx={{ mb: 2 }} textAlign={"left"}>
            Pull Requests Agents
          </Typography>
          <Typography variant="body1" textAlign={"left"} gutterBottom>
            {
              "These are the repositories that are being monitored. Choose which agents should review pull requests for each repo"
            }
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <NotificationsContextProvider access={access} refresh={refresh}>
            <TableWithActions
              loading={loading}
              tableRows={reposRow}
              defaultTitles={defaultReposTitles}
            />
          </NotificationsContextProvider>
        </Grid>
      </Grid>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Typography sx={{ mb: 2 }} variant="h5" align="left">
            Pull Request Review Level
          </Typography>
          <Typography variant="body1" textAlign={"left"} gutterBottom>
            {
              "Each issue found in a pull request is assigned a severity level. Tell us how severe an issue must be for you to be notified."
            }
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <SeveritySlider />
        </Grid>
        <Grid item xs={12} container alignItems="flex-start">
          <LabelToggle />
        </Grid>
      </Grid>
    </Box>
  );
};

export default ReposTable;
