import React, { useState, useEffect } from "react";
import { Box, Typography, useTheme, Button, Link, Card, CircularProgress, IconButton, Fade, Collapse } from "@mui/material";
import { list_user_comments, generate_ai_content, add_comment_ai_content_to_wiki, reject_comment } from "@/services/Blar/Integrations";
import { useContext } from "react";
import { Context } from "@/contexts/ContextProvider";
import CodeBlock from "@/components/CodeBlock/CodeBlock";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import ReactMarkdown from 'react-markdown';
import Avatar from "@mui/material/Avatar";
import { getRelativeTimeString } from "../utils/getRelativeTime";
import { GetCommentCard } from "./GetCommentsCard";

interface GithubImportContentProps {
  onBack: () => void;
  repoFolderName?: string;
}

export type GitUserComment = {
  id: string;
  url: string;
  diff_text: string;
  content: string;
  git_created_at: string;
  wiki_status: string;
  ai_content: string;
  file_path: string;
  author: {
    username: string;
    user_id: string;
    avatar_url: string;
  }
}

const GithubImportContent: React.FC<GithubImportContentProps> = ({ 
  onBack,
  repoFolderName
}) => {
  const theme = useTheme();
  const [comments, setComments] = useState<GitUserComment[]>([]);
  const [loading, setLoading] = useState(false);
  const [generatingAiContent, setGeneratingAiContent] = useState<Record<string, boolean>>({});
  const [addingCommentAiContentToWiki, setAddingCommentAiContentToWiki] = useState<Record<string, boolean>>({});
  const [rejectingComment, setRejectingComment] = useState<Record<string, boolean>>({});
  const [hoveredCardId, setHoveredCardId] = useState<string | null>(null);
  const [fadingComments, setFadingComments] = useState<Record<string, boolean>>({});
  const [collapsingComments, setCollapsingComments] = useState<Record<string, boolean>>({});

  const { showMessage } = useContext(Context);

  const fetchComments = async () => {
    try {
      setLoading(true);
      const response = await list_user_comments(repoFolderName!);
      setComments(response.data);
    } catch (error) {
      showMessage("error", "Error fetching comments");
    } finally { 
      setLoading(false);
    }
  };

  useEffect(() => {
    if (repoFolderName) {
      fetchComments();
    }
  }, [repoFolderName]);

  const handleGenAiContentRule = async (comment: GitUserComment) => {
    try {
      setGeneratingAiContent(prev => ({ ...prev, [comment.id]: true }));
      
      const response = await generate_ai_content(comment.id);
      const ai_comment = response.data;

      const ai_content = ai_comment.ai_content;
      
      const wiki_status = ai_comment.wiki_status;
      if (wiki_status === "unprocessable") {
        showMessage("warning", "Unprocessable rule from comment");
        setComments(prev => prev.map(c => c.id === comment.id ? { ...c, ai_content: ai_content, wiki_status: wiki_status } : c));
      } else {
        setComments(prev => prev.map(c => c.id === comment.id ? { ...c, ai_content: ai_content } : c));
        showMessage("success", "Generated rule from comment");
      }
    } catch (error) {
      showMessage("error", "Error generating rule from comment");
    } finally {
      setGeneratingAiContent(prev => ({ ...prev, [comment.id]: false }));
    }
  };

  const removeComment = (commentId: string) => {
    setFadingComments(prev => ({ ...prev, [commentId]: true }));
    setTimeout(() => {
      setCollapsingComments(prev => ({ ...prev, [commentId]: true }));
    }, 300);
  };

  const handleCommentExited = (commentId: string) => {
    setComments(prev => prev.filter(c => c.id !== commentId));
    setFadingComments(prev => {
      const updated = { ...prev };
      delete updated[commentId];
      return updated;
    });
    setCollapsingComments(prev => {
      const updated = { ...prev };
      delete updated[commentId];
      return updated;
    });
  };

  const handleAddCommentAiContentToWiki = async (comment: GitUserComment) => {
    try {
      setAddingCommentAiContentToWiki(prev => ({ ...prev, [comment.id]: true }));
      
      const response = await add_comment_ai_content_to_wiki(comment.id);
      if (response.status === 200) {
        showMessage("success", "Rule added to wiki successfully");
        removeComment(comment.id);
      }
    } catch (error) {
      showMessage("error", "Error adding rule to wiki");
    } finally {
      setAddingCommentAiContentToWiki(prev => ({ ...prev, [comment.id]: false }));
    }
  }

  const handleRejectComment = async (comment: GitUserComment) => {
    try {
      setRejectingComment(prev => ({ ...prev, [comment.id]: true }));
      const response = await reject_comment(comment.id);
      if (response.status === 200) {
        showMessage("success", "Comment rejected successfully");
        removeComment(comment.id);
      }
    } catch (error) {
      showMessage("error", "Error rejecting comment");
    } finally {
      setRejectingComment(prev => ({ ...prev, [comment.id]: false }));
    }
  }

  return (
    <Box
      sx={{
        borderColor: theme.palette.divider,
        width: '100%',
        borderRadius: '10px',
        padding: 0,
        height: '100%',
        boxShadow: theme.shadows[3],
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden'
      }}
    >
      <Box 
        sx={{ 
          position: 'sticky',
          top: 0,
          zIndex: 10,
          backgroundColor: theme.palette.background.paper,
          borderBottom: `1px solid ${theme.palette.divider}`,
          padding: 4,
          paddingBottom: 2,
          width: '100%'
        }}
      >
        <Box sx={{ textAlign: 'left', width: '100%' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
            <IconButton 
              onClick={onBack} 
              sx={{ mr: 1, p: 0.5 }}
              aria-label="Go back"
            >
              <ArrowBackIcon />
            </IconButton>
            <Typography variant="h5">
              Import from past GitHub comments {repoFolderName ? `for ${repoFolderName}` : ''}
            </Typography>
          </Box>
          <Typography variant="body1" color="text.secondary" sx={{ mb: 1 }}>
            Generate wiki guidelines from your past pull request comments. Transform your valuable code review insights into reusable knowledge for your team and our AI reviewer.
          </Typography>
        </Box>
      </Box>

      <Box 
        sx={{ 
          overflowY: 'auto',
          flexGrow: 1,
          padding: 4,
          paddingTop: 2
        }}
      >
        {loading ? (
          <Box display="flex" justifyContent="center" alignItems="center" height="200px">
            <CircularProgress />
          </Box>
        ) : comments.length === 0 ? (
          <GetCommentCard setComments={setComments} repo_name={repoFolderName!} />
        ) : (
          <Box sx={{ mt: 1 }}>
            {comments.map((comment) => (
            <Collapse 
              key={comment.id}
              in={!collapsingComments[comment.id]}
              timeout={500}
              onExited={() => handleCommentExited(comment.id)}
            >
              <Fade 
                in={!fadingComments[comment.id]} 
                timeout={{ enter: 500, exit: 400 }}
              >
                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: 2, mb: 3 }}>
                  <Card
                    sx={{
                      p: 0,
                      borderRadius: '10px',
                      border: `1px solid ${theme.palette.divider}`,
                      backgroundColor: theme.palette.background.paper,
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <Box sx={{ display: 'flex', justifyContent: 'strr', alignItems: 'center', px: 2, py: 1 }}>
                      <Link 
                        href={comment.url} 
                        target="_blank" 
                        rel="noopener noreferrer"
                        sx={{ 
                          color: theme.palette.text.primary,
                          textDecoration: 'none',
                          '&:hover': { textDecoration: 'underline' }
                        }}
                      >
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                          <Typography variant="subtitle1">
                            {comment.file_path}
                          </Typography>
                          <OpenInNewIcon fontSize="small" />
                        </Box>
                      </Link>
                    </Box>
                    
                    <Box>
                      <Box sx={{ 
                        position: 'relative',
                        backgroundColor: theme.palette.background.default,
                        borderTop: `1px solid ${theme.palette.divider}`,
                        borderBottom: `1px solid ${theme.palette.divider}`,
                        overflow: 'hidden'
                      }}>
                      <Box sx={{ 
                        position: 'absolute', 
                        top: '8px', 
                        right: '8px', 
                        zIndex: 2,
                        display: 'flex'
                      }}>
                        <IconButton 
                          size="small" 
                          sx={{ 
                            px: 2, 
                            backgroundColor: 'transparent',
                            color: theme.palette.action.active
                          }}
                          onClick={() => {
                            navigator.clipboard.writeText(comment.diff_text);
                            showMessage("success", "Code copied to clipboard");
                          }}
                        >
                          <ContentCopyIcon fontSize="small" />
                        </IconButton>
                      </Box>
                      <Box sx={{ 
                        maxHeight: '200px',
                        overflow: 'auto',
                        textAlign: 'left',
                        '& pre': {
                          margin: 0,
                          backgroundColor: 'transparent !important',
                          border: 'none !important'
                        }
                      }}>
                        <CodeBlock language="diff" hideCopyButton={true}>
                          {comment.diff_text}
                        </CodeBlock>
                      </Box>
                      </Box>
                    </Box>
                    <Box sx={{ flexDirection: 'column', width: '100%', height: '100%', display: 'flex', justifyContent: 'space-between' }}>
                      <Box p={2} display="flex" flexDirection="row" alignItems="top" justifyContent="start" gap={2}>
                        <Avatar src={comment.author.avatar_url} alt={comment.author.username} sx={{ width: 24, height: 24 }}/>
                        <Box sx={{ display: 'flex', alignSelf: 'flex-start', flexDirection: 'column', alignItems: 'flex-start', gap: 1 }}>
                          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 }}>
                            <Typography variant="body1" sx={{ whiteSpace: 'pre-wrap', textAlign: 'left', flex: 1 }}>
                              {comment.author.username}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                              commented {getRelativeTimeString(comment.git_created_at)}
                            </Typography>
                          </Box>
                          <Typography variant="body1" sx={{ whiteSpace: 'pre-wrap', textAlign: 'left', flex: 1 }}>
                            {comment.content}
                          </Typography>
                        </Box>
                      </Box>
                      
                      <Box sx={{ 
                        display: 'flex', 
                        justifyContent: 'flex-end', 
                        alignItems: 'flex-end', 
                        mb: 2, 
                        px: 2, 
                        pt: 1,
                      }}>
                        {!comment.ai_content && (
                          <>
                            <Button
                              variant="outlined"
                              size="medium"
                              disabled={rejectingComment[comment.id]}
                              onClick={() => handleRejectComment(comment)}
                              sx={{ 
                                width: 'fit-content',
                                minWidth: '120px',
                                color: "#FF6371",
                                borderColor: "#FF6371",
                                marginRight: 1,
                                '&:hover': {
                                  backgroundColor: theme.palette.action.hover,
                                  borderColor: "#FF6371"
                                }
                              }}
                            >
                              {rejectingComment[comment.id] ? (
                                <CircularProgress size={20} color="inherit" />
                              ) : (
                                "Reject"
                              )}
                            </Button>
                            <Button
                              variant="outlined"
                              size="medium"
                              disabled={generatingAiContent[comment.id]}
                              onClick={() => handleGenAiContentRule(comment)}
                              onMouseEnter={() => setHoveredCardId(comment.id)}
                              onMouseLeave={() => setHoveredCardId(null)}
                              sx={{ 
                                width: 'fit-content',
                                minWidth: '120px',
                                color: hoveredCardId === comment.id ? '#2196f3' : theme.palette.secondary.main,
                                borderColor: hoveredCardId === comment.id ? '#2196f3' : theme.palette.secondary.main,
                                '&:hover': {
                                  backgroundColor: theme.palette.action.hover,
                                  borderColor: '#2196f3',
                                  color: '#2196f3'
                                }
                              }}
                            >
                              {generatingAiContent[comment.id] ? (
                                <CircularProgress size={20} color="inherit" />
                              ) : (
                                "Generate rule from comment"
                              )}
                            </Button>
                          </>
                        )}
                      </Box>
                    </Box>
                  </Card>
                  
                  <Box 
                    sx={{ 
                      display: 'flex', 
                      alignItems: 'center', 
                      justifyContent: 'center',
                      mx: 0
                    }}
                  >
                    <KeyboardDoubleArrowRightIcon 
                      sx={{ 
                        color: hoveredCardId === comment.id ? '#2196f3' : 'white',
                        transition: 'color 0.3s ease',
                        fontSize: '2rem'
                      }} 
                    />
                  </Box>
                  
                  <Card 
                    sx={{ 
                      width: '100%', 
                      p: 2, 
                      borderRadius: '10px', 
                      border: `1px solid ${theme.palette.divider}`, 
                      backgroundColor: theme.palette.background.paper,
                      overflow: 'auto',
                      transition: 'border-color 0.3s ease, box-shadow 0.3s ease',
                      animation: hoveredCardId === comment.id ? 'pulse 2s ease-in-out infinite' : 'none',
                      display: 'flex',
                      flexDirection: 'column',
                      minHeight: '350px',
                      '@keyframes pulse': {
                        '0%': {
                          borderColor: theme.palette.divider,
                          boxShadow: '0 0 0 0 rgba(33, 150, 243, 0)'
                        },
                        '20%': {
                          borderColor: '#2196f3',
                          boxShadow: '0 0 0 0 rgba(33, 150, 243, 0.2)'
                        },
                        '50%': {
                          borderColor: '#2196f3',
                          boxShadow: '0 0 0 5px rgba(33, 150, 243, 0.3)'
                        },
                        '80%': {
                          borderColor: '#2196f3',
                          boxShadow: '0 0 0 3px rgba(33, 150, 243, 0.1)'
                        },
                        '100%': {
                          borderColor: theme.palette.divider,
                          boxShadow: '0 0 0 0 rgba(33, 150, 243, 0)'
                        }
                      }
                    }}
                  >
                    <Box sx={{ 
                      textAlign: 'left', 
                      '& p, & h1, & h2, & h3, & h4, & h5, & h6, & ul, & ol': { 
                        textAlign: 'left' 
                      },
                      display: 'flex',
                      flexDirection: 'column',
                      flexGrow: 1,
                      height: '100%'
                    }}>
                      {comment.ai_content ? (
                        <>
                          <Typography 
                            variant="h6" 
                            sx={{ 
                              textAlign: 'center', 
                              mb: 2, 
                              fontWeight: 'bold',
                              color: theme.palette.primary.main
                            }}
                          >
                            {comment.wiki_status === "unprocessable" ? "Unprocessable Rule" : "AI Generated Rule"}
                          </Typography>
                          <Box sx={{
                            border: `1px solid ${theme.palette.divider}`,
                            borderRadius: '8px',
                            p: 3,
                            mb: 2,
                            backgroundColor: theme.palette.background.default
                          }}>
                            <ReactMarkdown
                              components={{
                                code(props) {
                                  const { children, className, ...rest } = props;
                                  const match = /language-(\w+)/.exec(className || "");
                                  return match ? (
                                    <CodeBlock language={match[1]}>
                                      {String(children)}
                                    </CodeBlock>
                                  ) : (
                                    <code
                                      {...rest}
                                      className={className}
                                      style={{
                                        textOverflow: "ellipsis",
                                        wordBreak: "break-word",
                                        whiteSpace: "pre-wrap",
                                        background: 'rgba(0, 0, 0, 0.2)',
                                        padding: '2px 4px',
                                        borderRadius: '3px'
                                      }}
                                    >
                                      {children}
                                    </code>
                                  );
                                },
                              }}
                            >
                              {comment.ai_content}
                            </ReactMarkdown>
                          </Box>
                        </>
                      ) : (
                        <Box sx={{
                          border: '1px dashed ' + (generatingAiContent[comment.id] ? theme.palette.secondary.main : theme.palette.divider),
                          borderRadius: '8px',
                          p: 3,
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          justifyContent: 'center',
                          minHeight: '200px',
                          flex: 1,
                          textAlign: 'center',
                          backgroundColor: theme.palette.action.hover
                        }}>
                          {
                            generatingAiContent[comment.id] ? (
                              <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" height="100%" gap={2}>
                                <Typography variant="h6" sx={{ color: theme.palette.primary.main }}>
                                  Generating rule from comment
                                </Typography>
                                <CircularProgress size={36} sx={{ color: theme.palette.primary.main }} />
                              </Box>
                            ) : (
                              <>
                              <Typography variant="h6" sx={{ mb: 1, color: theme.palette.text.secondary }}>
                                No content generated yet
                              </Typography>
                              <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
                                  Generate the content from the comment and see it here.
                                </Typography>
                              </>
                            )
                          }
                        </Box>
                      )}
                    </Box>
                    <Box sx={{ 
                      display: 'flex', 
                      justifyContent: 'flex-end', 
                      alignItems: 'center',
                    }}>
                      {comment.ai_content && (
                        <>
                        <Button
                          variant="outlined"
                          size="medium"
                          disabled={rejectingComment[comment.id]}
                          onClick={() => handleRejectComment(comment)}
                          sx={{ 
                            width: 'fit-content',
                            minWidth: '120px',
                            color: "#FF6371",
                            borderColor: "#FF6371",
                            marginRight: 1,
                            '&:hover': {
                              backgroundColor: theme.palette.action.hover,
                              borderColor: "#FF6371"
                            }
                          }}
                        >
                          {rejectingComment[comment.id] ? (
                            <CircularProgress size={20} color="inherit" />
                          ) : (
                            "Reject"
                          )}
                        </Button>
                        {comment.wiki_status !== "unprocessable" && (
                          <Button
                            variant="outlined"
                            size="medium"
                            disabled={addingCommentAiContentToWiki[comment.id]}
                            onClick={() => handleAddCommentAiContentToWiki(comment)}
                          sx={{ 
                            width: 'fit-content',
                            minWidth: '120px',
                            color: theme.palette.secondary.main,
                            borderColor: theme.palette.secondary.main,
                            '&:hover': {
                              backgroundColor: theme.palette.action.hover,
                              borderColor: theme.palette.secondary.dark
                            }
                          }}
                        >
                          {addingCommentAiContentToWiki[comment.id] ? (
                            <CircularProgress size={20} color="inherit" />
                          ) : (
                            "Add to Wiki file"
                          )}
                        </Button>
                        )}
                        </>
                      )}
                    </Box>
                  </Card>
                </Box>
              </Fade>
            </Collapse>
            ))}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default GithubImportContent; 