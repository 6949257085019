import { Typography, Grid, IconButton, Dialog, DialogContent, DialogTitle } from "@mui/material";
import { TableRowData } from "@/components/Tables/types";

import TableWithActions from "@/components/Tables/TableWithActions";
import AddIcon from "@mui/icons-material/Add";
import { useContext, useEffect, useState } from "react";
import { get_selected_seats } from "@/services/Blar/Git";
import { convertUsersToRow } from "./helper";
import { Context } from "@/contexts/ContextProvider";
import { create_invitation } from "@/services/Blar/Company";
import SelectUsersSeats from "@/components/Select Users Seats/SelectUsersSeats";
import EditIcon from "@mui/icons-material/Edit";

const defaultUsersTitles = ["Avatar", "Username", "Has Blar User"];

const UsersTable = () => {
  const { showMessage } = useContext(Context);
  const [loading, setLoading] = useState(false);
  const [userRows, setUserRows] = useState<TableRowData[]>([]);
  const [createdObject, setCreatedObject] = useState(Date.now());
  const [openAddUser, setOpenAddUser] = useState<boolean>(false)


  useEffect(() => {
    async function fetchUsersData() {
      try {
        setLoading(true);
        const usersResult = (await get_selected_seats()).data
          .results;
        const usersRows = convertUsersToRow(usersResult, setCreatedObject);
        setUserRows(usersRows);
      } catch (error) {
        showMessage("error", `Failed to fetch users`);
      } finally {
        setLoading(false);
      }
    }

    fetchUsersData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createdObject]);

  const handleInviteUserClick = async () => {
    try {
      const response = await create_invitation();
      if (response.status === 201) {
        if (document.hasFocus()) {
          navigator.clipboard.writeText(response.data.invitation_link);
          showMessage("success", "Invitation link copied to your clipboard");
        } else {
          showMessage("warning", "Please focus the window to copy the invitation link.");
        }
      }
    } catch (error) {
      showMessage("error", "Error creating link");
    }
  };

  return (
    <>
      <Grid container alignItems="center" sx={{ marginBottom: "20px" }}>
        <Grid item>
          <Typography variant="h6">Seats</Typography>
        </Grid>
        <Grid item>
          <IconButton
            sx={{
              border: "1px solid #FFFFFF4D",
              color: "gray",
              marginLeft: "10px",
            }}
            size="small"
            onClick={() => setOpenAddUser(true)}
          >
            <EditIcon />
          </IconButton>
        </Grid>
      </Grid>
      <Typography variant="body1" align="left">
        These are the users that Blar will review their PRs. At the end of the month, we will charge based on the distinct number of users that received at least one review.
      </Typography>
      <br/>
      <TableWithActions
        loading={loading}
        tableRows={userRows}
        defaultTitles={defaultUsersTitles}
        customAction={{
          onAction: handleInviteUserClick,
          actionIcon: <AddIcon />,
          actionLabel: "Invite User to Blar platform",
          disabledKey: "_disabled_key"
        }}
      />
      <Dialog open={openAddUser} onClose={() => setOpenAddUser(false)} maxWidth="md" fullWidth>
        <DialogTitle>Select your seats</DialogTitle>
          <SelectUsersSeats onEdit={() => setCreatedObject(Date.now())} />
        <DialogContent>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default UsersTable;
