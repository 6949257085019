import React, { createContext, useContext, useState, useCallback, useEffect, useMemo, useRef } from 'react';
import { FileNode } from '../types';
import {
  getFolderContents,
  getFileContent,
  updateFile,
  createFolder,
  createFile,
  updateFolder,
  deleteFolder,
  deleteFile,
} from '@/services/Blar/Wiki';
import { Context as AppContext } from '@/contexts/ContextProvider';
import { MDXEditorMethods } from "@mdxeditor/editor";

interface WikiContextType {
  selectedNode: FileNode | null;
  setSelectedNode: React.Dispatch<React.SetStateAction<FileNode | null>>;
  inputFileContent: string | null;
  setInputFileContent: React.Dispatch<React.SetStateAction<string | null>>;
  outputFileContent: string;
  setOutputFileContent: React.Dispatch<React.SetStateAction<string>>;
  loadingCount: number;
  setLoadingCount: React.Dispatch<React.SetStateAction<number>>;
  title: string;
  setTitle: React.Dispatch<React.SetStateAction<string>>;
  handleFileClick: (node: FileNode) => Promise<void>;
}

export const WikiContext = createContext<WikiContextType | null>(null);

interface WikiProviderProps {
  children: React.ReactNode;
}

interface WikiState {
  structure: FileNode[];
  selectedNode: FileNode | null;
  inputFileContent: string | null;
  outputFileContent: string;
  loadingCount: number;
  title: string,
}

export const WikiProvider: React.FC<WikiProviderProps> = ({ children }) => {
  const [selectedNode, setSelectedNode] = useState<WikiState['selectedNode']>(null);
  const [inputFileContent, setInputFileContent] = useState<WikiState['inputFileContent']>(null);
  const [outputFileContent, setOutputFileContent] = useState<WikiState['outputFileContent']>('');
  const [loadingCount, setLoadingCount] = useState<WikiState['loadingCount']>(0);
  const [title, setTitle] = useState<WikiState['title']>('');

  const handleFileClick = async (node: FileNode) => {
    setSelectedNode(node);
    if (node.type === 'file') {
      setInputFileContent(node.content || ''); // File node ever has content but it can be empty
      setOutputFileContent(node.content || ''); // If it's empty, it will be empty string
    } else {
      setInputFileContent(null);
      setOutputFileContent('');
    }
  };

  useEffect(() => {
    if (selectedNode && selectedNode.type === 'file') {
      setTitle(selectedNode.name);
    }
  }, [selectedNode]);

  const value = useMemo(() => ({
    selectedNode,
    setSelectedNode,
    inputFileContent,
    setInputFileContent,
    outputFileContent,
    setOutputFileContent,
    loadingCount,
    setLoadingCount,
    title,
    setTitle,
    handleFileClick,
  }), [
    selectedNode,
    inputFileContent,
    outputFileContent,
    loadingCount,
    title,
    handleFileClick,
  ]);


  return <WikiContext.Provider value={value}>{children}</WikiContext.Provider>;
};

export const useWiki = () => {
  const context = useContext(WikiContext);
  if (!context) {
    throw new Error('useWiki must be used within a WikiProvider');
  }
  return context;
}; 