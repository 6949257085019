import { ChatRoom } from "@/pages/Chat/components/types";
import React, { ReactNode, createContext, useContext, useEffect, useState } from "react";
import { Context } from "./ContextProvider";
import { get_chat_room } from "@/services/Blar/Chat";


interface ChatRoomContextProps {
  chatRoom: ChatRoom | undefined;
  loading: boolean;
}

export const ChatRoomContext = createContext<ChatRoomContextProps>({ 
  chatRoom: undefined, 
  loading: false 
});


interface ChatRoomContextProviderProps {
  children: ReactNode;
  chatRoomId: number;
}


export const ChatRoomContextProvider: React.FC<ChatRoomContextProviderProps> = ({ children, chatRoomId }: ChatRoomContextProviderProps) => {
  const { showMessage } = useContext(Context);
  const [chatRoom, setChatRoom] = useState<ChatRoom | undefined>(undefined);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchChatRoomInformation = async () => {
      try {
        setLoading(true);
        const chatRoomResponse = await get_chat_room(chatRoomId);
        if (chatRoomResponse.status === 200) {
          const chatRoom = chatRoomResponse.data;
          setChatRoom(chatRoom);
        }
      } catch (error) {
        showMessage("error", "Failed to get chat room information");
      } finally {
        setLoading(false);
      }
    };

    fetchChatRoomInformation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
      <ChatRoomContext.Provider value={{ chatRoom, loading }}>
          {children}
      </ChatRoomContext.Provider>
  );

};
