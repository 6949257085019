import React, { FC, ReactNode, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Sidebar from "./components/SideBar/SideBar";
import { Box } from "@mui/material";

interface ProtectedProps {
  isAllowed: boolean;
  redirectTo?: string; // Optional redirect path, defaults to '/login'
  children: ReactNode; // This is for the component or elements you're wrapping
}

const Protected: FC<ProtectedProps> = ({
  isAllowed,
  redirectTo = "/login",
  children,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname + location.search;
  const isRedirect = window.location.href.includes("/redirect");

  useEffect(() => {
    if (!isAllowed) {
      if (currentPath === redirectTo) return
      if (redirectTo === "/login") {
        navigate(`${redirectTo}?redirect=${encodeURIComponent(currentPath)}`);
        return;
      }
      navigate(redirectTo);
    }
  }, [redirectTo, isAllowed, navigate, currentPath]);

  return isAllowed ? (
    <Box display="flex" flexDirection="row" height="100dvh" overflow="auto">
      {!isRedirect && <Sidebar />}
      <Box 
        component="main" 
        flex="1" 
        sx={{ 
          overflowY: "auto",
          height: "100dvh",
          maxHeight: "100dvh",
          "&::-webkit-scrollbar": {
            width: "8px",
          },
          "&::-webkit-scrollbar-track": {
            background: "#161B22",
          },
          "&::-webkit-scrollbar-thumb": {
            background: "#30363d",
            borderRadius: "4px",
          },
          "&::-webkit-scrollbar-thumb:hover": {
            background: "#40464d",
          }
        }}
      >
        {children}
      </Box>
    </Box>
  ) : null;
};

export default Protected;
