import React, { useState } from "react";
import { useTheme } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import Paper from "@mui/material/Paper";

import formatters from "./formatters";
import { TablePaginationOptions, TableRowData } from "./types";
import { LinearProgress } from "@mui/material";
import ConfirmDeleteModal from "./ConfirmDeleteModal";

import { StyledTableRow, StyledTableHeadRow, StyledTableHeadCell, StyledTableCell } from "./Styles/TableWithActions";

type CustomAction = {
  onAction: (index: number) => void;
  actionIcon: React.ReactNode;
  actionLabel: string;
  disabledKey?: string;
};

type TableWithActionsProps = {
  tableRows: TableRowData[];
  defaultTitles: string[];
  loading: boolean;
  onDelete?: (index: number) => void;
  onEdit?: (index: number) => void;
  onClick?: (index: number) => void;
  tablePagination?: TablePaginationOptions;
  maxHeight?: string | number;
  customAction?: CustomAction;
};

const TableWithActions: React.FC<TableWithActionsProps> = ({
  tableRows,
  defaultTitles,
  loading,
  onDelete,
  onEdit,
  onClick,
  tablePagination,
  customAction,
  maxHeight = '400px',
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [rowIndexToDelete, setRowIndexToDelete] = useState<number | null>(null);
  const { onAction, actionIcon, actionLabel, disabledKey } = customAction || {};
  const theme = useTheme();

  const tableTitles = tableRows[0] ? Object.keys(tableRows[0]) : defaultTitles;
  const visibleTitles = tableTitles.filter(
    (title) => tableRows[0]?.[title]?.type !== "hidden"
  );

  const handleDeleteClick = (index: number) => {
    setRowIndexToDelete(index);
    setIsModalOpen(true);
  };

  const handleConfirmDelete = () => {
    if (rowIndexToDelete !== null && onDelete) {
      onDelete(rowIndexToDelete);
      setIsModalOpen(false);
    }
  };

  const handleRowClick = (index: number) => {
    if (onClick) {
      onClick(index);
    }
  };

  return (
    <>
      <TableContainer
        component={Paper}
        sx={{
          padding: "0",
          maxHeight: maxHeight,
          overflowY: 'auto',
          border: '1px solid #FFFFFF4D',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Table size="small" sx={{ minWidth: 700 }} aria-label="customized table" stickyHeader>
          <TableHead>
            <StyledTableHeadRow>
              {visibleTitles.map((title) => (
                <StyledTableHeadCell key={title}>
                  {title}
                </StyledTableHeadCell>
              ))}
              {(onDelete || onEdit || onAction) && <StyledTableHeadCell align="right">Actions</StyledTableHeadCell>}
            </StyledTableHeadRow>
          </TableHead>
          <TableBody>
            {loading && (
              <StyledTableRow>
                <StyledTableCell colSpan={visibleTitles.length + 1}>
                  <LinearProgress />
                </StyledTableCell>
              </StyledTableRow>
            )}
            {!loading &&
              tableRows.map((row, index) => (
                <StyledTableRow
                  theme={theme}
                  key={index}
                  onClick={onClick ? () => handleRowClick(index) : undefined}
                >
                  {tableTitles.map(
                    (title) =>
                      row[title].type !== "hidden" && (
                        <StyledTableCell key={title}>
                          {formatters[row[title].type]
                            ? formatters[row[title].type](row[title])
                            : row[title].content}
                        </StyledTableCell>
                      )
                  )}
                  {(onDelete || onEdit || onAction) && (
                    <StyledTableCell align="right">
                      {onEdit && (
                        <IconButton
                          onClick={(e) => {
                            e.stopPropagation();
                            onEdit(index);
                          }}
                          sx={{ marginRight: 1 }}
                        >
                          <EditIcon fontSize="small" sx={{ color: "#1a7f64" }} />
                        </IconButton>
                      )}
                      {onDelete && (
                        <IconButton
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDeleteClick(index);
                          }}
                        >
                          <DeleteIcon fontSize="small" sx={{ color: "#ef4146" }} />
                        </IconButton>
                      )}
                      {onAction && (disabledKey && !row[disabledKey].content) && (
                        <IconButton
                          onClick={(e) => {
                          e.stopPropagation();
                          onAction(index);
                          }}
                          aria-label={actionLabel}
                          title={actionLabel}
                        >
                          {actionIcon}
                        </IconButton>
                      )}
                    </StyledTableCell>
                  )}
                </StyledTableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      {tablePagination && (
        <TablePagination
          component="div"
          count={tablePagination.count}
          rowsPerPageOptions={[10, 20, 30]}
          page={tablePagination.page}
          rowsPerPage={tablePagination.rowsPerPage}
          onPageChange={tablePagination.handlePagination}
          onRowsPerPageChange={tablePagination.handleChangeRowsPerPage}
        />
      )}
      <ConfirmDeleteModal
        open={isModalOpen}
        setOpen={setIsModalOpen}
        onConfirm={handleConfirmDelete}
      />
    </>
  );
};

export default TableWithActions;
