import React, { useEffect, useState, useContext } from 'react';
import { Typography, CircularProgress, Grid, Paper, List, ListItem, ListItemIcon, ListItemText, Box, Container } from '@mui/material';
import { Context } from '@/contexts/ContextProvider';
import PartyPopper from '@/components/Animations/Partypopper';
import { create_subscription, get_subscription, get_subscription_plans, cancel_subscription } from '@/services/Blar/Subscription';
import { Plan, Subscription } from './types';
import { AxiosError } from 'axios';
import { useLocation } from 'react-router-dom';
import CheckIcon from '@mui/icons-material/Check';
import { LoadingButton } from '@mui/lab';

interface PricingCardProps {
  id: string;
  title: string;
  price: string | number;
  features: string[];
  isSubscribed: boolean;
  onSubscribe: (id: string) => Promise<void>;
  onUnsubscribe?: (id: string) => Promise<void>;
  isCustom?: boolean;
}

const PricingCard: React.FC<PricingCardProps> = ({ 
  id,
  title, 
  price, 
  features, 
  isSubscribed, 
  onSubscribe,
  onUnsubscribe,
  isCustom = false
}) => {
  const [loading, setLoading] = useState<boolean>(false);

  const handleSubscribe = async () => {
    setLoading(true);
    if (!isSubscribed) {
      await onSubscribe(id);
    } else {
      if (onUnsubscribe) {
        await onUnsubscribe(id);
      }
    }
    setLoading(false);
  }

  return (
  <Paper elevation={2} sx={{
    p: 2,
    height: '100%',
    maxHeight: '400px',
    display: 'flex',
    flexDirection: 'column',
    border: isSubscribed ? '2px solid #1976d2' : 'none',
    position: 'relative',
    borderRadius: '20px',
  }}>
    <Box sx={{ maxHeight: '90px' }}>
      <Typography variant='h5' component='h2' gutterBottom noWrap>
        {title}
      </Typography>
      {isCustom ? 
        <Typography variant='h4' component='div' gutterBottom noWrap>
          Contact Us
        </Typography> :
        <Typography variant='h4' component='div' gutterBottom noWrap>
          ${price}<Typography variant='subtitle1' component='span' sx={{ fontSize: 'clamp(0.75rem, 0.5vw, 1.5rem)' }}>/user/month</Typography>
        </Typography>
      }
    </Box>
    
    <LoadingButton 
      variant='contained'
      color={isSubscribed ? 'secondary' : 'primary'}
      fullWidth
      onClick={handleSubscribe}
      sx={{m: 0}}
      loading={loading}
    >
      {isSubscribed ? 'Unsubscribe' : 'Subscribe'}
    </LoadingButton>
      <List sx={{ flexGrow: 1, overflowY: 'auto' }}>
        {features.map((feature, index) => (
          <ListItem key={index} dense>
            <ListItemIcon>
              <CheckIcon color='primary' />
            </ListItemIcon>
            <ListItemText primary={feature}  />
          </ListItem>
        ))}
      </List>
  </Paper>
)};

const Subscriptions: React.FC = () => {
  const { showMessage, companyTier, setRefreshSession } = useContext(Context);
  const [subscription, setSubscription] = useState<null | Subscription>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [plans, setPlans] = useState<Plan[]>([]);
  const [fetchError, setFetchError] = useState<boolean>(false);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const paymentSuccess = queryParams.get('payment');

  useEffect(() => {
    // Retries to avoid race condition with stripe after checkout
    async function fetchSubscriptionData(retries: number = 0) {
      setRefreshSession(Date.now())
      try {
        const plansResponse = await get_subscription_plans();
        if (plansResponse.status === 200) {
          setPlans(plansResponse.data.plans);
        }
      } catch (error) {
        showMessage('error', 'Failed to fetch subscription price');
        setFetchError(true);
      }

      try {
        const subscriptionResponse = await get_subscription();
        if (subscriptionResponse.status === 200) {
          setSubscription({
            status: subscriptionResponse.data.subscription.status,
            currentPeriodStart: subscriptionResponse.data.subscription.current_period_start,
            currentPeriodEnd: subscriptionResponse.data.subscription.current_period_end,
            quantity: subscriptionResponse.data.subscription.quantity,
          });
        }
      } catch (error: any) {
        if (error instanceof AxiosError) {
          const response = error.response;
          if (response?.status === 404) {
            if (paymentSuccess !== null && retries < 10) {
              setTimeout(() => fetchSubscriptionData(retries + 1), 1000);
            }
            setSubscription(null);
            return;
          }
          setFetchError(true);
        }
        showMessage('error', 'Failed to fetch subscription');
      }
    }
    setLoading(true);
    fetchSubscriptionData().finally(() => setLoading(false));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubscribe = async (planId: string) => {
    const retrieve_session_url = await create_subscription(planId);
    if (retrieve_session_url.status === 201) {
      window.location.href = retrieve_session_url.data.session_url;
    } else {
      showMessage('error', 'Failed to create subscription');
    }
  }

  const handleUnsubscribe = async (planId: string) => {
    try {
      await cancel_subscription();
      setSubscription(null);
    } catch (error) {
      showMessage('error', 'Failed to cancel subscription');
    }
  }

  const scheduleMetting = async () => {
    window.location.href = 'https://calendly.com/jose-wsa/30min';
  }

  const renderSubscriptionPlans = () => {
    if (loading) {
      return <CircularProgress/>;
    }

    if (fetchError || companyTier === null || (companyTier > 1 && subscription === null)) {
      return <Typography variant='h6' color='error'>Failed to fetch subscription data</Typography>;
    }

    if (paymentSuccess !== null && subscription === null ) {
      return (
        <Box>
          <Typography variant='h6' color='error'>
          We are processing your payment. Please wait a few seconds.
          </Typography>
          <br/>
          <CircularProgress/>
        </Box>
      )
    }

    const isSubscribedToPlan = (planIndex: number) => {
      return ((companyTier === planIndex + 1) && (subscription && subscription.status !== 'canceled')) || false
    }

    return (
      <>
        {subscription && paymentSuccess && <PartyPopper />}
        {subscription && subscription.status === 'canceled' && 
        <>
          <Typography variant='h6'>
            Your subscription has been canceled.
          </Typography>
          <Typography variant='h6'>
            Thank you for being with us. If you wish to return, we will be here.
          </Typography>
          <br/>
        </>
        }
        <Grid container spacing={3} width={'100%'} justifyContent='center'>
          {plans.map((plan, index) => (
            <Grid item xs={12} sm={4} key={index}>
              <PricingCard
                id={plan.id}
                title={plan.name}
                price={plan.price}
                features={plan.description.split(',')}
                isSubscribed={isSubscribedToPlan(index)}
                onSubscribe={handleSubscribe}
                onUnsubscribe={handleUnsubscribe}
              />
            </Grid>
          ))}
          <Grid item xs={12} sm={4}>
            <PricingCard
              id='enterprise'
              title='Enterprise'
              price='Custom'
              features={[
                'Everything on Teams plan',
                'On-premise solution',
                'Custom code languages support',
                'Premium support'
              ]}
              isSubscribed={companyTier === plans.length + 1}
              onSubscribe={scheduleMetting}
              isCustom={true}
            />
          </Grid>
        </Grid>
      </>
    );
  };

  return (
    <Container maxWidth="lg">
      {renderSubscriptionPlans()}
    </Container>
  );
};

export default Subscriptions;