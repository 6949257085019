import { IntegrationCardProps } from "../../pages/Settings/components/Integrations/types";

export const integrations: IntegrationCardProps[] = [
  {
    logo: "/integrations/github.svg",
    name: "GitHub",
    dbName: "github",
    description: "Connect your codebase, and we'll review your pull requests, comment on any issues, and help keep your code in top shape.",
    category: "code_versioning",
  },
  {
    logo: "/integrations/bitbucket-icon.svg",
    name: "Bitbucket",
    dbName: "bitbucket",
    description: "Connect your codebase, and we'll review your pull requests, point out issues, and streamline your workflow.",
    category: "code_versioning",
  },
  {
    logo: "/integrations/gitlab.svg",
    name: "GitLab",
    dbName: "gitlab",
    description: "Connect your codebase, and we'll review your merge requests, identify issues, and comment directly to help you resolve them.",
    category: "code_versioning",
  },
  {
    logo: "/integrations/SLA-Logo-Mark-COLOR-RGB.png",
    name: "Slack",
    dbName: "slack",
    description: "A messaging app for seamless team collaboration. We can notify you our code errors reports.",
  },
  {
    logo: "/integrations/sentry-glyph-dark-400x352.svg",
    name: "Sentry",
    dbName: "sentry",
    description: "We catch your production errors, analyze them, and provide both a detailed report and a proposed solution.",
  }
];
