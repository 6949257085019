import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import { get_company_minimum_severity_level, patch_company_minimum_severity_level } from '@/services/Blar/Company';
import { useEffect, useState } from 'react';


const marks = [
    {
        value: 0,
        label: 'Disabled'
    },
    {
        value: 33,
        label: 'Only comment about crucial errors',
    },
    {
        value: 66,
        label: 'Warn me about potential issues too',
    },
    {
        value: 100,
        label: 'Comment on everything'
    }
];

const number_to_severity: { [key: number]: string } = {
    0: 'disabled',
    33: 'error',
    66: 'warning',
    100: 'info'
}

const severity_to_number: { [key: string]: number } = {
    'disabled': 0,
    'error': 33,
    'warning': 66,
    'info': 100
}

function valuetext(value: number) {
    return `${value}`;
}

export default function SeveritySlider() {
    const [severityLevel, setSeverityLevel] = useState<string>('info');


    useEffect(() => {
        get_company_minimum_severity_level().then((severityLevel) => {
            setSeverityLevel(severityLevel.data.minimum_issue_severity);
        });
    }, []);


    return (
        <Box sx={{ width: .8, margin: 'auto' }}>
            <Slider
                aria-label="Restricted values"
                value={severity_to_number[severityLevel]}
                getAriaValueText={valuetext}
                onChange={(e, value) => {
                    patch_company_minimum_severity_level(number_to_severity[value as number]).then((response) => {
                        setSeverityLevel(response.data.minimum_issue_severity);
                    });

                }}
                step={null}
                valueLabelDisplay="off"
                marks={marks}
            />
        </Box>
    );
}