import React, { useState, useEffect, useRef } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Box } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { tomorrowNightBright as style } from "react-syntax-highlighter/dist/esm/styles/hljs";

interface TraceFrame {
  file: string;
  module: string;
  line_number: number;
  pre_context: string[] | null;
  code_context: string | null;
  post_context: string[] | null;
  date?: string;
  author?: string;
  commit_message?: string;
}

interface TracebackViewerProps {
  frames: TraceFrame[];
}

const TracebackViewer: React.FC<TracebackViewerProps> = ({ frames }) => {
  const [expandedPanels, setExpandedPanels] = useState<number[]>([]);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (frames.length > 0) {
      setExpandedPanels([frames.length - 1]);
      
      setTimeout(() => {
        containerRef.current?.scrollIntoView({ 
          behavior: 'smooth', 
          block: 'end'
        });
      }, 300);
    }
  }, [frames]);

  const handleChange = (panel: number) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpandedPanels(prev => {
      if (isExpanded) {
        return [...prev, panel];
      } else {
        return prev.filter(p => p !== panel);
      }
    });
  };

  const getLanguageFromFile = (file: string | undefined): string => {
    if (!file) return 'text';
    const extension = file.split('.').pop()?.toLowerCase();
    switch (extension) {
      case 'py':
        return 'python';
      case 'js':
        return 'javascript';
      case 'ts':
      case 'tsx':
        return 'typescript';
      default:
        return 'text';
    }
  };

  return (
    <Box ref={containerRef}>
      {frames.map((frame, index) => (
        <Accordion
          key={index}
          onChange={handleChange(index)}
          expanded={expandedPanels.includes(index)}
          sx={{
            backgroundColor: 'transparent',
            '&:before': {
              display: 'none',
            },
            border: '1px solid #30363D',
            m: 0, 
            p: 0,
            '&.Mui-expanded': {
              margin: 0,
              padding: 0
            },
            '& .MuiCollapse-root': {
              marginTop: 0,
              marginBottom: 0
            }
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}
            sx={{ 
              p: 0, 
              m: 0,
              '& .MuiAccordionSummary-expandIconWrapper': {
                marginRight: '8px',
                transform: 'none',
                '&.Mui-expanded': {
                  transform: 'rotate(180deg)',
                  marginRight: '8px'
                }
              },
              '&.Mui-expanded': {
                minHeight: '48px',
                margin: 0
              },
              '& .MuiAccordionSummary-content': {
                margin: 0,
                '&.Mui-expanded': {
                  margin: 0
                }
              }
            }}
          >
            <Box sx={{ p: 0, m: 0 }}>
              <Typography variant="body1" sx={{ mx: 1 }}>
                {frame.file}:{frame.line_number}
              </Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails sx={{ 
            p: 0, 
            m: 0,
            '&.MuiAccordionDetails-root': {
              padding: 0,
              margin: 0
            }
          }}>
            <Box sx={{ 
              overflow: 'hidden',
              p: 0,
              m: 0 
            }}>
              <SyntaxHighlighter
                showLineNumbers={true}
                startingLineNumber={frame.line_number - (frame.pre_context?.length || 0)}
                wrapLines={true}
                language={getLanguageFromFile(frame.file)}
                style={style}
                lineProps={(lineNumber) => {
                    const style: any = { display: "block", width: "fit-content" };
                    if (lineNumber === frame.line_number ) {
                      style.backgroundColor = "#0000C0DE";
                    }
                    return { style };
                  }}
              >
                {[
                  ...(frame.pre_context || []),
                  frame.code_context ? `${frame.code_context}` : '',
                  ...(frame.post_context || []),
                ].join('\n')}
              </SyntaxHighlighter>
            </Box>
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
};

export default TracebackViewer; 