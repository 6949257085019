import { Box } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import React from "react";
import useStyles from "./styles/ContinueButton";

interface BackButtonProps {
  backOnboarding: () => void;
  disabled?: boolean;
  className?: string;
}

const BackButton: React.FC<BackButtonProps> = ({
  backOnboarding,
  disabled,
  className,
}) => {
  const classes = useStyles();

  return (
    <Box className={classes.root + (className ? ` ${className}` : "")}>
      <LoadingButton
        variant="outlined"
        color="primary"
        style={{ fontSize: "20px", margin: 0 }}
        onClick={backOnboarding}
        disabled={disabled}
      >
        Back
      </LoadingButton>
    </Box>
  );
};

export default BackButton;