import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    padding: 20,
  },
  headerSection: {
    textAlign: 'left',
    width: '100%',
    maxWidth: "700px",
  },
  title: {
    fontWeight: 500, 
    marginBottom: theme.spacing(1),
    width: '100%'
  },
  subtitle: {
    marginBottom: theme.spacing(1),
    width: '100%'
  },
  repoSelect: {
    marginBottom: theme.spacing(2)
  },
  templatesGrid: {
    overflow: 'auto', 
    maxHeight: 'calc(100vh - 400px)',
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
    gap: theme.spacing(2),
  },
  buttonContainer: {
    width: '100%', 
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: theme.spacing(2), 
  },
  actionButton: {
    flex: 1,
    height: '48px',
    borderRadius: '8px',
    margin: 0,
  },
  collapsedQuestion: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '12px',
    backgroundColor: '#161B22',
    borderRadius: '8px',
    cursor: 'pointer',
    border: '1px solid #30363d',
    marginBottom: theme.spacing(2),
    '&:hover': {
      backgroundColor: '#1c2129'
    }
  },
  collapsedText: {
    color: '#8b949e'
  },
  skipButton: {
    marginTop: theme.spacing(3),
    flex: 1,
    textAlign: 'right',
    marginRight: theme.spacing(1),
    '&:hover': {
      backgroundColor: 'transparent'
    }
  }
}));

export default useStyles;