import ContinueButton from '@/components/Buttons/ContinueButton';
import Subscriptions from '@/components/Subscription';
import { Box, Typography } from '@mui/material';
import { Context } from '@/contexts/ContextProvider';
import React, { useContext } from 'react';
import LoadingTypography from './components/LoadingTypography';
import useStyles from "./styles/SelectPlan";

interface SelectPlanProps {
  continueOnboarding: () => void;
}

const SelectPlan: React.FC<SelectPlanProps> = ({
  continueOnboarding,
}) => {
  const { companyTier } = useContext(Context);
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Box>
        <LoadingTypography
          text="Choose your plan"
          loading={false}
          stepCompleted={companyTier !== 0}
        />
        <Typography variant='h4' mb={3} fontSize={20} color='text.secondary'>
          Enjoy a complimentary two-week trial to experience our AI-driven code review solutions.
        </Typography>
      </Box>
      <Subscriptions />
      <Box className={classes.buttonContainer}>
        <ContinueButton
          continueOnboarding={continueOnboarding}
          disabled={companyTier === 0}
        />
      </Box>
    </Box>
  );
};

export default SelectPlan;