import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  form: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "400px",
  },
  actions: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  }, 
  authButton: {
    height: "44px",
    margin: 0,
    marginBottom: "10px",
  }
});

export default useStyles;
