import React, { useContext } from 'react';
import Subscriptions from '@/components/Subscription';
import { Box} from '@mui/material';
import { Context } from '@/contexts/ContextProvider';
import LoadingTypography from '../Auth/Onboarding/components/LoadingTypography';

const Pricing = () => {
  const { companyTier } = useContext(Context);
  return (
    <Box height='100%' display='flex' flexDirection='column' alignItems='center' justifyContent={'center'} ml={10} mr={10}>
      <LoadingTypography
        text="Choose your plan"
        loading={false}
        stepCompleted={companyTier !== 0}
      />
      <Subscriptions />
    </Box>
  );
};

export default Pricing;