import { Box, Checkbox } from "@mui/material";
import { get_agent_flag_status, patch_agent_flag_status } from "@/services/Blar/Repo_graph";
import { useEffect, useState, useContext } from "react";
import { AxiosError } from "axios";
import { Context } from "@/contexts/ContextProvider";

interface ToggleAgentProps {
    agentType: string;
    repoId: string;
}

export default function ToggleAgent({ agentType, repoId }: ToggleAgentProps) {
  const { showMessage } = useContext(Context);
  const [agentEnabled, setAgentEnabled] = useState<boolean>(false);

  useEffect(() => {
      const fetchAgentStatus = async () => {
          const response = await get_agent_flag_status(repoId, agentType);
          setAgentEnabled(response.data.enabled);
      };

      fetchAgentStatus();
  }, [repoId, agentType]);

  const onToggleAgent = async () => {
    try {
      const response = await patch_agent_flag_status(repoId, agentType, !agentEnabled);
      setAgentEnabled(response.data.enabled);
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.status === 400 && error.response) {
          showMessage("error", error.response.data.error);
        } else {
          showMessage("error", "An unexpected error occurred");
        }
      }
    }
  };

  return (
      <Box>
          <Checkbox checked={agentEnabled} onClick={onToggleAgent} />
      </Box>
  );
}