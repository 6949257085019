import React, { useRef } from "react";
import { Box, Typography } from "@mui/material";
import FileStructure from "./components/WikiFileStructure";
import FileEditor from "./components/FileEditor";
import ResizableLayout from "@/components/Layout/ResizebleLayout";
import { WikiProvider, useWiki } from "./context/WikiContext";
import { WikiFileStructureProvider } from "./context/WikiFileStructureContext";
import { WikiFileEditorProvider } from "./context/WikiFileEditorContext";
import CreateFileCard from "./components/CreateFileCard";
const WikiPageContent: React.FC = () => {
  const { selectedNode, inputFileContent } = useWiki();
  const containerRef = useRef<HTMLDivElement>(null);

  const sidebarContent = <FileStructure />;

  const renderMainContent = () => {
    if (selectedNode?.type === 'file' && inputFileContent !== null) {
      return (
        <WikiFileEditorProvider>
          <FileEditor key={selectedNode.id} />
        </WikiFileEditorProvider>
      );
    }
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100%">
        <CreateFileCard />
      </Box>
    );
  };

  const mainContent = renderMainContent();

  return (
    <Box ref={containerRef} width="100%" height="100%">
      <ResizableLayout
        sidebarContent={sidebarContent}
        mainContent={mainContent}
        initialWidth={300}
        minWidth={250}
        maxWidth={400}
        forceWidth={300}
      />
    </Box>
  );
};

const WikiPage: React.FC = () => {
  return (
    <WikiProvider>
      <WikiFileStructureProvider>
        <WikiPageContent />
      </WikiFileStructureProvider>
    </WikiProvider>
  );
};

export default WikiPage;
