import React from "react";
import { Box, Typography, CircularProgress, Button } from "@mui/material";

interface ActionCardProps {
  message: string;
  loading: boolean;
  buttonText: string;
  onButtonClick: () => void;
}

const ActionCard: React.FC<ActionCardProps> = ({
  message,
  loading,
  buttonText,
  onButtonClick
}) => (
  <Box 
    sx={{ 
      display: 'flex', 
      alignItems: 'center', 
      justifyContent: 'space-between',
      padding: 1,
      borderRadius: '8px', 
      border: '1px solid #30363d',
      marginTop: 1
    }}
  >
    <Typography variant="body1" color="text.primary">
      {message}
    </Typography>
    {loading ? (
      <CircularProgress size={20} />
    ) : (
      <Button
        variant="contained"
        onClick={onButtonClick}
        sx={{ minWidth: '120px', margin: 0 }}
      >
        {buttonText}
      </Button>
    )}
  </Box>
);

export default ActionCard;
