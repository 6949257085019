import { Box, Typography, Button, Select, MenuItem, FormControl, InputLabel, IconButton } from "@mui/material"
import { useState } from "react";
import SentryProjectsModal from './SentryProjectsModal';

export interface SelectedProject {
  projectName: string;
  selectedRepo?: string;
}

interface NoSentryProjectsConfigProps {
  onConfigSuccess?: () => void;
}

const NoSentryProjectsConfig: React.FC<NoSentryProjectsConfigProps> = ({ onConfigSuccess }) => {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
    onConfigSuccess?.();
  }

  return (
    <>
      <Box sx={{ 
        width: "100%", 
        mb: 2, 
        border: "1px solid #30363d", 
        borderRadius: "10px", 
        padding: "16px", 
        backgroundColor: '#161B22',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
      }}>
        <Box sx={{ 
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center' 
        }}>
          <Typography variant="h6" sx={{ color: "white", textAlign: "left" }}>
              Currently you don't have Sentry projects configured in Blar
          </Typography>
          <Typography variant="body1" sx={{ color: "white", textAlign: "left" }}>
              We need relate sentry projects with your code to start analyzing your code errors.
          </Typography>
        </Box>
        <Button
          variant="contained"
          color="primary"
          onClick={handleClick}
          sx={{
            whiteSpace: 'nowrap',
            height: 'fit-content'
          }}
        >
          Configure
        </Button>
      </Box>

      <SentryProjectsModal
        open={open}
        onClose={handleClose}
        onUpdateSuccess={onConfigSuccess}
      />
    </>
  )
}

export default NoSentryProjectsConfig;

