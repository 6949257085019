import React from "react";
import { Box, Typography, CircularProgress } from "@mui/material";

const LoadingBox: React.FC<{ message: string }> = ({ message }) => (
  <Box sx={{ 
    display: 'flex', 
    alignItems: 'center', 
    justifyContent: 'space-between', 
    backgroundColor: 'transparent', 
    padding: 2, 
    borderRadius: '8px', 
    border: '1px solid #30363d'
  }}>
    <Typography variant="body1" fontSize="18px" color="text.primary">
      {message}
    </Typography>
    <CircularProgress sx={{ marginLeft: 1 }} size={20} />
  </Box>
);

export default LoadingBox;
