import { createContext, useCallback, useContext, useEffect, useState } from "react";
import { useWiki } from "./WikiContext";
import { Context } from "@/contexts/ContextProvider";
import { updateFile } from "@/services/Blar/Wiki";
import { MDXEditorMethods } from "@mdxeditor/editor";

export const WikiFileEditorContext = createContext<WikiFileEditorContextType | null>(null);

interface WikiFileEditorContextType {
  handleSaveFile: () => Promise<void>;
  setEditorRef: (ref: React.RefObject<MDXEditorMethods>) => void;
}

export const WikiFileEditorProvider = ({ children }: { children: React.ReactNode }) => {
  const [editorRef, setEditorRefState] = useState<React.RefObject<MDXEditorMethods> | null>(null);

  const { selectedNode, outputFileContent, setLoadingCount, setInputFileContent, setOutputFileContent, setTitle } = useWiki();
  const { showMessage } = useContext(Context);

  const setEditorRef = useCallback((ref: React.RefObject<MDXEditorMethods>) => {
    setEditorRefState(ref);
  }, []);

  useEffect(() => {
    if (editorRef?.current && outputFileContent !== undefined) {
      try {
        editorRef.current.setMarkdown(outputFileContent);
      } catch (error) {
        console.error('Failed to sync editor content:', error);
        showMessage('error', 'Failed to sync editor content');
      }
    }
  }, [editorRef, outputFileContent]);

  const handleSaveFile = useCallback(async () => {
    if (!selectedNode || selectedNode.type !== 'file') {
      return;
    }

    setLoadingCount((prev) => prev + 1);
    try {
      // The editor (external library) removes newlines before code fences, so we need to add them back. Weird.
      // This is a hack to fix the issue.
      // If we save the file without the hack, the code fences are all clumped together.
      const currentContentRaw = editorRef?.current?.getMarkdown?.() ?? outputFileContent;
      const currentContent = currentContentRaw.replace(/([^\n])(```)/g, '$1\n$2');

      if (currentContent === undefined) {
        throw new Error('No content available to save');
      }

      const updatePromises = [
        updateFile(selectedNode.id, {
          id: selectedNode.id,
          name: selectedNode.name,
          content: currentContent,
          folder: selectedNode.parent,
        }),
        new Promise<void>((resolve) => {
          setOutputFileContent(currentContent);
          setInputFileContent(currentContent);
          setTitle(selectedNode.name);
          resolve();
        })
      ];

      const [response] = await Promise.all(updatePromises);
      showMessage('success', 'File saved successfully');
    } catch (error) {
      console.error('Failed to save file:', error);
      showMessage('error', 'Failed to save file');
    } finally {
      setLoadingCount((prev) => prev - 1);
    }
  }, [selectedNode, outputFileContent, editorRef]);

  return (
    <WikiFileEditorContext.Provider value={{ handleSaveFile, setEditorRef }}>
      {children}
    </WikiFileEditorContext.Provider>
  );
};

export const useWikiFileEditor = () => {
  const context = useContext(WikiFileEditorContext);
  if (!context) {
    throw new Error('useWikiFileEditor must be used within a WikiFileEditorProvider');
  }
  return context;
};
