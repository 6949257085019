import { useState, useCallback, useMemo, memo, useEffect, useRef } from "react";
import { Box, Typography, Tooltip } from "@mui/material";
import '@mdxeditor/editor/style.css'
import '@/pages/Wiki/styles.css'
import {
  MDXEditor,
  Button,
  headingsPlugin,
  listsPlugin,
  quotePlugin,
  codeBlockPlugin,
  thematicBreakPlugin,
  markdownShortcutPlugin,
  toolbarPlugin,
  UndoRedo,
  BoldItalicUnderlineToggles,
  BlockTypeSelect,
  MDXEditorMethods,
  CodeToggle,
  InsertCodeBlock,
  codeMirrorPlugin,
  ChangeCodeMirrorLanguage,
  ConditionalContents,
  ListsToggle,
  CodeMirrorEditor,
  useCodeBlockEditorContext,
  CodeBlockEditorDescriptor,
  linkPlugin,
  diffSourcePlugin,
  DiffSourceToggleWrapper,
} from '@mdxeditor/editor'
import { githubDark } from "@uiw/codemirror-theme-github";
import { useWiki } from "../context/WikiContext";
import { useNavigate } from "react-router-dom";
import { useWikiFileEditor } from "../context/WikiFileEditorContext";
import { useWikiFileStructure } from "../context/WikiFileStructureContext";
import { makeContentEditable } from "@/utils/dom";

interface EditorToolbarProps {
  title: string;
  onTitleDoubleClick: () => void;
  tags: string[];
}

const EditorToolbar = memo(({ title, onTitleDoubleClick, tags }: EditorToolbarProps) => {
  const isEditingBlocked = tags && tags.length > 0 && tags.includes("user") && tags.includes("info");
  return (
  <Box display="flex" sx={{ width: '100%', justifyContent: 'space-between' }}>
    <Box display="flex" >
      <DiffSourceToggleWrapper options={['rich-text', 'source']}>
        <UndoRedo />
        <ConditionalContents
          options={[
            {
              when: (editor) => editor?.editorType === 'codeblock',
              contents: () => <ChangeCodeMirrorLanguage />
            },
            {
              fallback: () => (
                <>
                  <BoldItalicUnderlineToggles />
                  <BlockTypeSelect />
                  <CodeToggle />
                  <ListsToggle />
                  <InsertCodeBlock />
                </>
              )
            }
          ]}
        />
      </DiffSourceToggleWrapper>
    </Box>
    <Box display="flex" gap={1}>
      <Box display="flex" alignItems="center">
        <Typography
          variant="h6"
          data-title-input
          onDoubleClick={onTitleDoubleClick}
          sx={{
            fontSize: '1.25rem',
            lineHeight: '1.5',
            cursor: 'text',
            padding: '3px 6px',
            borderRadius: 1,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: '300px',
            '&[contenteditable="true"]': {
              background: 'rgba(255, 255, 255, 0.1)',
              outline: 'none',
            },
            '&[contenteditable="true"]:focus': {
              background: 'rgba(255, 255, 255, 0.15)',
            }
          }}
        >
          {title}
        </Typography>
      </Box>
        <Button className="save-button" type="submit" disabled={isEditingBlocked}>
          {isEditingBlocked ? "Blocked" : "Save"}
        </Button>
      </Box>
    </Box>
  );
});

EditorToolbar.displayName = 'EditorToolbar';

const PlainTextCodeEditorDescriptor: CodeBlockEditorDescriptor = {
  match: () => true,
  priority: 0,
  Editor: memo((props) => {
    const cb = useCodeBlockEditorContext()
    return (
      <CodeMirrorEditor {...props} />
    )
  })
};

const FileEditor: React.FC = () => {
  const {
    selectedNode,
    inputFileContent,
    title,
  } = useWiki();
  const navigate = useNavigate();

  const [isEditorReady, setIsEditorReady] = useState<boolean>(false);
  const editorRef = useRef<MDXEditorMethods>(null);
  const { handleSaveFile, setEditorRef } = useWikiFileEditor();
  const { handleRename } = useWikiFileStructure();

  useEffect(() => {
    setEditorRef(editorRef);
    setIsEditorReady(true);
    return () => {
      setIsEditorReady(false);
    };
  }, [setEditorRef]);

  // Add effect to handle focus when content is empty
  useEffect(() => {
    if (isEditorReady && selectedNode?.type === 'file' && !inputFileContent) {
      editorRef.current?.focus();
    }
  }, [isEditorReady, selectedNode, inputFileContent]);

  const handleTitleDoubleClick = useCallback(() => {
    if (!selectedNode) return;

    setTimeout(() => {
      const titleElement = document.querySelector('[data-title-input]') as HTMLElement;
      if (titleElement) {
        makeContentEditable(titleElement, title, async (newTitle) => {
          await handleRename(selectedNode, newTitle);
        });
      }
    }, 0);
  }, [selectedNode, title, handleRename]);

  const plugins = useMemo(() => [
    headingsPlugin(),
    listsPlugin(),
    quotePlugin(),
    diffSourcePlugin({ viewMode: 'rich-text' }),
    thematicBreakPlugin(),
    markdownShortcutPlugin(),
    linkPlugin(),
    codeBlockPlugin({
      defaultCodeBlockLanguage: 'python',
      codeBlockEditorDescriptors: [PlainTextCodeEditorDescriptor]
    }),
    codeMirrorPlugin({
      codeBlockLanguages: {
        python: 'Python',
        javascript: 'JavaScript',
        typescript: 'TypeScript',
        jsx: 'JSX',
        tsx: 'TSX',
        css: 'CSS',
        html: 'HTML',
        json: 'JSON',
        csharp: 'C#',
        ruby: 'Ruby',
        go: 'Go',
        dart: 'Dart',

      },

      codeMirrorExtensions: [githubDark],
      autoLoadLanguageSupport: true,
    }),
    toolbarPlugin({
      toolbarClassName: "",
      toolbarContents: () => (
        <EditorToolbar title={title} onTitleDoubleClick={handleTitleDoubleClick} tags={selectedNode?.tags || []} />
      )
    })
  ], [title, handleTitleDoubleClick]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    await handleSaveFile();
  };

  if (!selectedNode || !isEditorReady) return null;

  return (
    <form onSubmit={handleSubmit}>
      <Box
        textAlign="left"
        width="100%"
        px={2}
        sx={{
          overflow: 'hidden',
          mt: 2,
        }}
      >
        <MDXEditor
          key={selectedNode.id}
          markdown={inputFileContent || ""}
          ref={editorRef}
          plugins={plugins}
          contentEditableClassName="prose-content"
          className="dark-editor"
        />
      </Box>
    </form>
  );
};

export default memo(FileEditor);
