import { Grid, IconButton, Link, Typography } from "@mui/material";
import { delete_repo, list_repos } from "@/services/Blar/Repo_graph";
import { useContext, useEffect, useState } from "react";
import { TaskState } from "@/types/common";

import AddIcon from "@mui/icons-material/Add";
import AddRepoModal from "./components/AddRepoComponent";
import EditRepoModal from "./components/EditRepoComponent";
import { Context } from "@/contexts/ContextProvider";
import { NotificationsContextProvider } from "@/contexts/NotificationContextProvider";
import { Repo, RepoPaginatedResponse } from "./types";
import { TableRowData } from "@/components/Tables/types";
import TableWithActions from "@/components/Tables/TableWithActions";
import { convertReposToRow } from "./helper";

const defaultReposTitles = ["Name", "GitHub Url", "PR Branches", "Last Sync"];

const ReposTable = () => {
  const [reposRow, setReposRow] = useState<TableRowData[]>([]);
  const [openAddRepo, setopenAddRepo] = useState<boolean>(false);
  const [openEditRepo, setopenEditRepo] = useState<boolean>(false);
  const [selectedRepo, setSelectedRepo] = useState<Repo | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [createdObject, setCreatedObject] = useState<number | null>(null);
  const { showMessage, access, refresh, companyTier } = useContext(Context);

  useEffect(() => {
    async function fetchReposData() {
      try {
        setLoading(true);
        const reposResult = (await list_repos()).data as RepoPaginatedResponse;
        const repoRows = convertReposToRow(reposResult);
        setReposRow(repoRows);
      } catch (error) {
        showMessage("error", `Failed to fetch repos`);
      } finally {
        setLoading(false);
      }
    }

    if (createdObject !== null) {
      fetchReposData();
    }
  }, [createdObject]);

  // Initial fetch
  useEffect(() => {
    setCreatedObject(Date.now());
  }, []);

  const handleRepoDelete = async (index: number) => {
    const repoId = reposRow[index]._id.content as string;
    try {
      const response = await delete_repo(repoId);
      setCreatedObject(Date.now());
      showMessage("success", "Repo deleted successfully");
    } catch (error) {
      showMessage("error", "Error deleting Repo");
    }
  };

  const handleAddRepo = () => {
    setopenAddRepo(true);
  };

  const handleEditRepo = (index: number) => {
    const row = reposRow[index];
    const repoData = {
      repo_id: row._id.content as string,
      name: row.Name.content as string,
      url: row["GitHub Url"].content as string,
      last_sync: (row["Last Sync"].content as Date).toISOString(),
      main_branch: (row["Tracked Branches"].content as any).props.mainBranch,
      development_branch: (row["Tracked Branches"].content as any).props.developmentBranch,
      task_state: (row["Sync Status"].content as any).props.initialState === "completed" ? "completed" : "pending" as TaskState,
    };

    setSelectedRepo(repoData);
    setopenEditRepo(true);
  };

  return (
    <>
      <Grid item xs={12}>
        <Grid container alignItems="center" sx={{ marginBottom: "20px" }}>
          <Grid item>
            <Typography variant="h6">Repositories</Typography>
          </Grid>
          <Grid item>
            <IconButton
              sx={{
                border: "1px solid #FFFFFF4D",
                color: "gray",
                marginLeft: "10px",
              }}
              size="small"
              onClick={handleAddRepo}
            >
              <AddIcon />
            </IconButton>
          </Grid>
        </Grid>
        <NotificationsContextProvider access={access} refresh={refresh} >
          <TableWithActions
            loading={loading}
            tableRows={reposRow}
            onDelete={handleRepoDelete}
            onEdit={handleEditRepo}
            defaultTitles={defaultReposTitles}
          />
        </NotificationsContextProvider>
      </Grid >
      <AddRepoModal
        setCreatedObject={setCreatedObject}
        open={openAddRepo}
        setOpen={setopenAddRepo}
      />
      {selectedRepo && (
        <EditRepoModal
          setCreatedObject={setCreatedObject}
          open={openEditRepo}
          setOpen={setopenEditRepo}
          selectedRepo={selectedRepo}
        />
      )}
    </>
  );
};

export default ReposTable;
