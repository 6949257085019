import { Box, Typography, Button, Select, MenuItem, FormControl, InputLabel, IconButton, CircularProgress } from "@mui/material";
import BaseModal from "@/components/Modal/BaseModal";
import DeleteIcon from '@mui/icons-material/Delete';
import { SelectedProject } from "./NoSentryProjectsConfig";
import { list_repos } from '../../../services/Blar/Repo_graph';
import { list_sentry_projects, save_sentry_projects, sentry_projects_repos } from "../../../services/Blar/Integrations";
import { useEffect, useState, useContext } from "react";
import { Repo } from "@/pages/Settings/components/Repos/types";
import { Context } from "@/contexts/ContextProvider";

interface SentryProjectsModalProps {
  open: boolean;
  onClose: () => void;
  onUpdateSuccess?: () => void;
}

interface SentryProject {
  id: string;
  slug: string;
}

const SentryProjectsModal = ({
  open,
  onClose,
  onUpdateSuccess,
}: SentryProjectsModalProps) => {
  const [repos, setRepos] = useState<Repo[]>([]);
  const [sentryProjects, setSentryProjects] = useState<SentryProject[]>([]);
  const { showMessage } = useContext(Context);
  const [isUpdating, setIsUpdating] = useState(false);
  const [selectedProject, setSelectedProject] = useState('');
  const [selectedProjects, setSelectedProjects] = useState<SelectedProject[]>([]);
  const [loadingData, setLoadingData] = useState(true);
  
  const fetchRepos = async () => {
    try {
      const response = await list_repos();
      setRepos(response.data?.results || []);
      return response.data?.results || [];
    } catch (err) {
      showMessage("error", "Failed to fetch repositories");
      console.error(err);
      return [];
    }
  };

  const fetchSentryProjects = async () => {
    try {
      const response = await list_sentry_projects();
      setSentryProjects(response.data || []);
      return response.data || [];
    } catch (err) {
      showMessage("error", "Failed to fetch Sentry projects");
      console.error(err);
      return [];
    }
  };
  
  const fetchSentryProjectsRepos = async (sentryProjectsData: SentryProject[], reposData: Repo[]) => {
    try {
      const response = await sentry_projects_repos();
      const projectsRepos = response.data || {};
      
      if (Object.keys(projectsRepos).length > 0) {
        setInitialSelectedProjects(projectsRepos, sentryProjectsData, reposData);
      }
    } catch (err) {
      showMessage("error", "Failed to fetch project repositories");
      console.error(err);
    }
  };

  useEffect(() => {
    if (open) {
      const fetchData = async () => {
        setLoadingData(true);
        try {
          const [sentryProjectsData, reposData] = await Promise.all([
            fetchSentryProjects(),
            fetchRepos()
          ]);
          await fetchSentryProjectsRepos(sentryProjectsData, reposData);
        } catch (err) {
          showMessage("error", "Failed to fetch data");
          console.error(err);
        } finally {
          setLoadingData(false);
        }
      };
      fetchData();
    }
  }, [open]);

  const handleProjectChange = (event: any) => {
    setSelectedProject(event.target.value);
  };

  const handleAddProject = () => {
    if (selectedProject && !selectedProjects.some(p => p.projectName === selectedProject)) {
      setSelectedProjects([...selectedProjects, { projectName: selectedProject }]);
      setSelectedProject('');
    }
  };

  const handleDeleteProject = (projectName: string) => {
    setSelectedProjects(selectedProjects.filter(p => p.projectName !== projectName));
  };

  const handleRepoChange = (projectName: string, repo: string) => {
    setSelectedProjects(selectedProjects.map(p => 
      p.projectName === projectName ? { ...p, selectedRepo: repo } : p
    ));
  };

  const getAvailableRepos = (currentProject: SelectedProject) => {
    const selectedRepos = selectedProjects
      .filter(p => p.projectName !== currentProject.projectName)
      .map(p => p.selectedRepo)
      .filter((repo): repo is string => repo !== undefined);

    return repos.filter(repo => !selectedRepos.includes(repo.name));
  };


  const setInitialSelectedProjects = (projectsRepos: any, sentryProjects: SentryProject[], repos: Repo[]) => {
    const initialSelected = Object.entries(projectsRepos)
          .filter(([projectSlug]) => 
            sentryProjects.some(p => p.slug === projectSlug)
          )
          .map(([projectSlug, repoName]) => {
            const repo = repos.find(r => r.name === repoName);
            return repo ? { projectName: projectSlug, selectedRepo: repo.name } : null;
          })
          .filter((item): item is SelectedProject & { selectedRepo: string } => item !== null);
        
        setSelectedProjects(initialSelected);
  }

  const handleUpdate = async () => {
    setIsUpdating(true);
    const sentryProjectMap = selectedProjects
      .filter((project): project is SelectedProject & { selectedRepo: string } => project.selectedRepo !== undefined)
      .reduce((acc: Record<string, string>, project) => {
        acc[project.projectName] = project.selectedRepo;
        return acc;
      }, {});

    try {
      await save_sentry_projects({
        sentry_project_map: sentryProjectMap
      });
      showMessage("success", "Sentry projects updated successfully");
      onClose();
      onUpdateSuccess?.();
    } catch (error) {
      console.error('Error saving sentry projects:', error);
      showMessage("error", "Error saving sentry projects");
    } finally {
      setIsUpdating(false);
    }
  };

  return (
    <BaseModal
      open={open}
      onClose={onClose}
      title="Configure Sentry Projects"
      style={{ width: "50%" }}
    >
      <Box sx={{ p: 2 }}>
          <Typography variant="body1" gutterBottom>
          Centralize error tracking by connecting your Sentry project to its corresponding repository. First select a project, then choose its linked code repository to sync error alerts with your codebase.
          </Typography>
          <Box sx={{ 
            display: 'flex', 
            gap: 2, 
            mt: 2,
            alignItems: 'center' 
          }}>
            <FormControl fullWidth>
              <InputLabel id="project-select-label">Select Project</InputLabel>
              <Select
                labelId="project-select-label"
                value={selectedProject}
                label="Select Project"
                onChange={handleProjectChange}
                sx={{ backgroundColor: '#161B22' }}
                disabled={loadingData}
              >
                {sentryProjects
                  .filter(project => !selectedProjects.some(p => p.projectName === project.slug))
                  .map((project) => (
                  <MenuItem key={project.id} value={project.slug}>
                    {project.slug}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Button
              variant="contained"
              onClick={handleAddProject}
              disabled={!selectedProject || loadingData}
              sx={{
                whiteSpace: 'nowrap',
                height: '56px',
                minWidth: 'fit-content'
              }}
            >
              Add Project +
            </Button>
          </Box>

          {/* Selected Projects List */}
          {loadingData ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 3 }}>
              <CircularProgress />
            </Box>
          ) : (
            <Box sx={{ mt: 3 }}>
            {selectedProjects.map((project) => (
              <Box
                key={project.projectName}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2,
                  mt: 2,
                  p: 2,
                  border: '1px solid #30363d',
                  borderRadius: '10px',
                  backgroundColor: '#161B22'
                }}
              >
                <Typography sx={{ flex: 1, color: 'white' }}>
                  {project.projectName}
                </Typography>
                <FormControl sx={{ width: '200px' }}>
                  <InputLabel>Select Repo</InputLabel>
                  <Select
                    value={project.selectedRepo || ''}
                    label="Select Repo"
                    onChange={(e) => handleRepoChange(project.projectName, e.target.value)}
                    sx={{ backgroundColor: '#161B22' }}
                  >
                    {getAvailableRepos(project).map((repo) => (
                      <MenuItem key={repo.repo_id} value={repo.name}>
                        {repo.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <IconButton 
                  onClick={() => handleDeleteProject(project.projectName)}
                  sx={{ color: 'error.main' }}
                >
                  <DeleteIcon />
                </IconButton>
                </Box>
              ))}
            </Box>
          )}

          {/* Update Button */}
          <Box sx={{ 
            mt: 4, 
            pt: 3, 
            borderTop: '1px solid #30363d',
            display: 'flex',
            justifyContent: 'flex-end'
          }}>
            <Button
              variant="contained"
              onClick={handleUpdate}
              disabled={selectedProjects.length === 0 || selectedProjects.some(p => !p.selectedRepo) || isUpdating}
              sx={{
                minWidth: '120px'
              }}
            >
              {isUpdating ? <CircularProgress size={24} color="inherit" /> : 'Update'}
            </Button>
          </Box>
        </Box>
    </BaseModal>
  );
};

export default SentryProjectsModal; 