import { User } from "@/interfaces/ICompany";
import { blarClient } from "./Client";

export const fetchUserInfo = async (): Promise<User> => {
  try {
    const response = await blarClient.request("get", "/users/info");
    return response.data;
  } catch (error) {
    console.error("Error fetching GitHub members:", error);
    throw error;
  }
};

