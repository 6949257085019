import React, { useState, useEffect, useContext } from "react";
import {
  Menu,
  MenuItem,
  Typography,
  TextField,
  Button,
  Box,
  ListItemIcon,
  ListItemText,
  Select,
  CircularProgress,
  Tooltip,
  Chip,
} from "@mui/material";
import BugReportIcon from "@mui/icons-material/BugReport";
import SpeedIcon from "@mui/icons-material/Speed";
import SecurityIcon from "@mui/icons-material/Security";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";

import MenuSearch from "@/components/SearchBar/MenuSearch";
import { Agent, FileNode } from "./types";
import { list_repos, search_node } from "@/services/Blar/Repo_graph";
import { Context } from "@/contexts/ContextProvider";
import { AxiosResponse } from "axios";
import { execute_manual_agent } from "@/services/Blar/Agents";

interface AgentExecutionFormProps {
  anchorEl: HTMLElement | null;
  open: boolean;
  onClose: () => void;
  setRefreshExecutions: (date: number) => void; // New callback prop
}

interface AgentOption {
  value: Agent | string;
  label: string;
  icon: JSX.Element;
  disabled: boolean;
  beta?: boolean;
}

const agentOptions: AgentOption[] = [
  {
    value: "optimizer",
    label: "Optimizer",
    icon: <SpeedIcon fontSize="small" />,
    disabled: false,
    beta: true,
  },
  {
    value: "Debugger",
    label: "Debugger",
    icon: <BugReportIcon fontSize="small" />,
    disabled: true,
  },
  {
    value: "cyber_security",
    label: "Cyber Security",
    icon: <SecurityIcon fontSize="small" />,
    disabled: true,
  },
];

const AgentExecutionForm: React.FC<AgentExecutionFormProps> = ({
  anchorEl,
  open,
  onClose,
  setRefreshExecutions,
}) => {
  const [selectedRepo, setSelectedRepo] = useState<string | "">("");
  const [repositories, setRepositories] = useState<
    { repo_id: string; name: string }[]
  >([]);
  const [loadingRepos, setLoadingRepos] = useState<boolean>(true);

  const [selectedAgent, setSelectedAgent] = useState<Agent | "">("");
  const [selectedNode, setSelectedNode] = useState<FileNode | null>(null);
  const [loadingNodes, setLoadingNodes] = useState<boolean>(false);
  const [nodeOptions, setNodeOptions] = useState<FileNode[]>([]);
  const [extraInfo, setExtraInfo] = useState<string>("");

  const { showMessage } = useContext(Context);

  // Fetch repositories on mount
  useEffect(() => {
    const fetchRepositories = async () => {
      try {
        setLoadingRepos(true);
        const response: AxiosResponse = await list_repos();
        const repoList = response.data.results.map((repo: any) => ({
          repo_id: repo.repo_id,
          name: repo.name,
        }));
        setRepositories(repoList);
      } catch (error) {
        showMessage("error", "Failed to load repositories");
      } finally {
        setLoadingRepos(false);
      }
    };
    fetchRepositories();
  }, []);

  const handleRepoSelect = (event: any) => {
    setSelectedRepo(event.target.value);
  };

  const handleAgentSelect = (event: any) => {
    setSelectedAgent(event.target.value as Agent);
  };

  const handleSearchChange = async (value: string) => {
    if (!value || !selectedRepo) {
      setNodeOptions([]);
      return;
    }

    try {
      setLoadingNodes(true);
      const response: AxiosResponse = await search_node(
        value,
        selectedRepo,
        "main"
      );
      const options = response.data as FileNode[];
      setNodeOptions(options);
    } catch (error) {
      showMessage("error", "Failed to search nodes");
    } finally {
      setLoadingNodes(false);
    }
  };

  const handleExecute = async () => {
    if (selectedAgent && selectedNode && selectedRepo) {
      try {
        await execute_manual_agent(
          selectedAgent,
          selectedNode.node_id,
          selectedNode.path,
          extraInfo,
          selectedRepo
        );
        // Call onExecuted callback so parent can refresh list
        setRefreshExecutions(Date.now());
      } catch (error: any) {
        if (error.response) {
          if (error.response.status === 403) {
            showMessage("error", error.response.data.detail || "Monthly chat limit exceeded for your plan.");
          } else {
            // Handle other HTTP status codes by showing a generic error message or a more detailed response if available.
            showMessage("error", error.response.data.detail || "An unexpected error occurred. Please try again.");
          }
        } else {
          showMessage("error", "Failed to execute agent");
        }
      }
      onClose();
    }
  };


  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      sx={{ mt: 1 }}
      onClose={onClose}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      slotProps={{
        paper: {
          sx: {
            px: 3,
            py: 2,
            border: "1px solid rgba(255, 255, 255, 0.2)",
            borderRadius: "8px",
            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.3)",
          },
        },
      }}
      elevation={3}
    >
      <Typography variant="h6" sx={{ mb: 2 }}>
        Execute Agent
      </Typography>

      {/* Select an Agent */}
      <Typography variant="subtitle2" sx={{ mb: 1 }}>
        Select an Agent
      </Typography>
      <Select
        value={selectedAgent}
        onChange={handleAgentSelect}
        displayEmpty
        fullWidth
        variant="outlined"
        size="small"
        sx={{ mb: 2 }}
        renderValue={(selected) => {
          if (!selected) {
            return "Select an Agent";
          }
          const selectedItem = agentOptions.find(
            (item) => item.value === selected
          );
          return (
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              {selectedItem?.icon}
              {selectedItem?.label}
              {selectedItem?.beta && (
                <Chip label="Beta" size="small" color="warning" />
              )}
            </Box>
          );
        }}
      >
        <MenuItem value="" disabled>
          Select an Agent
        </MenuItem>
        {agentOptions.map((option) => {
          const menuItem = (
            <MenuItem
              key={option.value}
              value={option.value}
              disabled={option.disabled}
            >
              <ListItemIcon sx={{ minWidth: "30px" }}>
                {option.icon}
              </ListItemIcon>
              <ListItemText primary={option.label} />
              {option.beta && (
                <Chip label="Beta" size="small" color="warning" />
              )}
            </MenuItem>
          );

          return option.disabled ? (
            <Tooltip key={option.value} title="Coming Soon">
              <span>{menuItem}</span>
            </Tooltip>
          ) : (
            menuItem
          );
        })}
      </Select>

      {/* Select Repository */}
      <Typography variant="subtitle2" sx={{ mb: 1 }}>
        Select a Repository
      </Typography>
      <Select
        value={selectedRepo}
        onChange={handleRepoSelect}
        displayEmpty
        fullWidth
        variant="outlined"
        size="small"
        sx={{ mb: 2 }}
        disabled={loadingRepos}
      >
        <MenuItem value="" disabled>
          {loadingRepos ? (
            <CircularProgress size={20} />
          ) : (
            "Select a Repository"
          )}
        </MenuItem>
        {repositories.map((repo) => (
          <MenuItem key={repo.repo_id} value={repo.repo_id}>
            {repo.name}
          </MenuItem>
        ))}
      </Select>

      {/* Select a Starting Point */}
      <Typography variant="subtitle2" sx={{ mb: 1 }}>
        Select a Starting Point
      </Typography>
      <MenuSearch
        onChange={handleSearchChange}
        options={selectedNode ? [...nodeOptions, selectedNode] : nodeOptions}
        placeholder="Search path..."
        loading={loadingNodes}
        value={selectedNode}
        setSelectedNode={setSelectedNode}
        selectedNode={selectedNode}
        selectedRepo={selectedRepo}
      />

      {/* Extra Information Field */}
      <Typography variant="subtitle2" sx={{ mb: 1, mt: 2 }}>
        Additional Information
      </Typography>
      <TextField
        multiline
        rows={3}
        fullWidth
        variant="outlined"
        size="small"
        value={extraInfo}
        onChange={(e) => setExtraInfo(e.target.value)}
        sx={{ mb: 2 }}
      />

      {/* Execute Button */}
      <Button
        variant="contained"
        startIcon={<PlayArrowIcon />}
        fullWidth
        onClick={handleExecute}
        color="blarSuccess"
        disabled={!selectedRepo || !selectedAgent || !selectedNode}
      >
        Execute
      </Button>
    </Menu>
  );
};

export default AgentExecutionForm;
