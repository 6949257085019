import React from "react";
import DOMPurify from "dompurify"; // Import DOMPurify
import { Box, Card, CardContent, Typography } from "@mui/material";
import { Execution } from "./types";
import CopyButton from "@/components/Buttons/CopyButton";
import ReactMarkdown from "react-markdown";
import SyntaxHighlighter from "react-syntax-highlighter";
import rehypeRaw from "rehype-raw";
import { tomorrowNightBright as codeStyle } from "react-syntax-highlighter/dist/esm/styles/hljs";
import useStyles from "./styles/ChatBody";

interface Props {
  selectedExecution: Execution | null;
}

const ExecutionReport: React.FC<Props> = ({ selectedExecution }) => {
  const classes = useStyles();

  if (!selectedExecution) {
    return (
      <Card variant="outlined" sx={{ height: "100%" }}>
        <CardContent sx={{ overflowY: "auto" }}>
          <Typography variant="h6">No Execution Selected</Typography>
          <Typography variant="body2" color="text.secondary" align="left">
            Select a past execution from the list to view its report.
          </Typography>
        </CardContent>
      </Card>
    );
  }

  // Sanitize the report before rendering
  const sanitizedReport = DOMPurify.sanitize(selectedExecution.report || "");

  return (
    <Card variant="outlined" sx={{ height: "100%", overflow: "auto" }}>
      <CardContent sx={{ overflowY: "auto", textAlign: "left" }}>
        <Typography variant="h6" gutterBottom align="left">
          Execution Report
        </Typography>
        <ReactMarkdown
          rehypePlugins={[rehypeRaw]}
          children={sanitizedReport} // Use sanitized content
          components={{
            code(props) {
              const { children, className, ...rest } = props;
              const match = /language-(\w+)/.exec(className || "");
              return match ? (
                <div style={{ position: "relative" }}>
                  <SyntaxHighlighter
                    children={String(children).replace(/\n$/, "")}
                    language={match[1]}
                    style={codeStyle}
                    customStyle={{
                      borderRadius: "20px",
                      padding: "20px",
                      fontSize: "14px",
                    }}
                  />
                  <Box className={classes.copyButton}>
                    <CopyButton
                      copyValue={String(children).replace(/\n$/, "")}
                      backgroundColor="black"
                      size="small"
                      sx={{ height: "20px" }}
                    />
                  </Box>
                </div>
              ) : (
                <code
                  className={className}
                  {...rest}
                  style={{
                    textOverflow: "ellipsis",
                    wordBreak: "break-word",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  {children}
                </code>
              );
            },
          }}
        />
      </CardContent>
    </Card>
  );
};

export default ExecutionReport;
