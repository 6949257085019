import { IconButton, Tooltip, useTheme } from "@mui/material";
import { iNotification, useNotifications } from "@/contexts/NotificationContextProvider";
import { useCallback, useContext, useEffect, useState } from "react";
import { Context } from "@/contexts/ContextProvider";
import ErrorIcon from "@mui/icons-material/Error";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import PendingIcon from '@mui/icons-material/Pending';

type TableSyncIconProps = {
  repoId: string;
  initialState: "completed" | "not_completed";
};

const TableSyncIcon = ({ repoId, initialState }: TableSyncIconProps) => {
  const theme = useTheme();
  const { showMessage } = useContext(Context);
  const [isCompleted, setIsCompleted] = useState(initialState === "completed");
  const { subscribeToNotifications, unsubscribeFromNotifications } = useNotifications();

  const handleRepoSyncedNotification = useCallback((notification: iNotification) => {
    if (notification.data.action !== "repo_synced" || notification.data.repo_id !== repoId) return;

    if (notification.data.task_state === "completed") {
      showMessage("success", "Repo synced successfully");
      setIsCompleted(true);
    } else {
      showMessage("error", "Error syncing Repo");
      setIsCompleted(false);
    }
  }, [showMessage, repoId]);

  useEffect(() => {
    subscribeToNotifications(handleRepoSyncedNotification);
    return () => {
      unsubscribeFromNotifications(handleRepoSyncedNotification);
    };
  }, [handleRepoSyncedNotification, subscribeToNotifications, unsubscribeFromNotifications]);

  return (
    <div style={{ display: "flex", justifyContent: "start", alignItems: "start" }}>
      {isCompleted ? (
        <CheckCircleIcon fontSize="small" sx={{ color: "#1a7f64" }} />
      ) : (
        <Tooltip title="Sync is pending">
          <PendingIcon fontSize="small" sx={{ color: theme.palette.divider }} />
        </Tooltip>
      )}
    </div>
  );
};

export default TableSyncIcon;
