import { Box, Typography, Button } from "@mui/material"
import { useContext } from "react"
import { Context } from "@/contexts/ContextProvider"
import { redirectToSentryApp } from "@/integrations/sentry"

const IntegrateSentry: React.FC = () => {
  const { showMessage } = useContext(Context)
  const REDIRECT_TIMEOUT = 2 * 1000

  const handleClick = () => {
    showMessage("info", "Redirecting to Blar docs, check out how to connect Sentry")
    setTimeout(() => redirectToSentryApp(), REDIRECT_TIMEOUT)
  }

  return (
    <Box sx={{ 
      width: "100%", 
      mb: 2, 
      border: "1px solid #30363d", 
      borderRadius: "10px", 
      padding: "16px", 
      backgroundColor: '#161B22',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center'
    }}>
      <Box sx={{ 
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center' 
      }}>
        <Typography variant="h6" sx={{ color: "white", textAlign: "left" }}>
            Currently you don't have Sentry integrated with Blar
        </Typography>
        <Typography variant="body1" sx={{ color: "white", textAlign: "left" }}>
            Integrate Sentry and start analyzing your code errors.
        </Typography>
      </Box>
      <Button 
        variant="contained" 
        color="primary"
        onClick={handleClick}
        sx={{ 
          whiteSpace: 'nowrap',
          height: 'fit-content'
        }}
      >
        Integrate Sentry
      </Button>
    </Box>
  )
}

export default IntegrateSentry;

