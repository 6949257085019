import { Box, TextField, Select, MenuItem, FormControl, InputLabel, InputAdornment } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import { User } from '../../../interfaces/ICompany';

interface ErrorsFiltersProps {
  searchQuery: string;
  selectedState: string;
  selectedAssignee: string;
  startDate: string;
  endDate: string;
  selectedRepo: string;
  users: User[];
  repos: any[];
  onSearchChange: (value: string) => void;
  onStateChange: (value: string) => void;
  onAssigneeChange: (value: string) => void;
  onStartDateChange: (value: string) => void;
  onEndDateChange: (value: string) => void;
  onRepoChange: (value: string) => void;
}

const ErrorsFilters: React.FC<ErrorsFiltersProps> = ({
  searchQuery,
  selectedState,
  selectedAssignee,
  startDate,
  endDate,
  selectedRepo,
  users,
  repos,
  onSearchChange,
  onStateChange,
  onAssigneeChange,
  onStartDateChange,
  onEndDateChange,
  onRepoChange,
}) => {
  return (
    <Box sx={{ display: "flex", gap: 2, mb: 2, flexWrap: "wrap" }}>
      {/* Search Bar */}
      <TextField
        placeholder="Search errors..."
        value={searchQuery}
        onChange={(e) => onSearchChange(e.target.value)}
        sx={{
          flex: 1,
          minWidth: '200px',
          '& .MuiOutlinedInput-root': {
            color: 'white',
            borderRadius: '10px',
            '& fieldset': { borderColor: '#30363d' },
            '&:hover fieldset': { borderColor: '#6e7681' },
          }
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <SearchIcon sx={{ color: 'white' }} />
            </InputAdornment>
          ),
        }}
      />

      {/* Status Filter */}
      <FormControl sx={{ minWidth: 120 }}>
        <InputLabel sx={{ color: 'white' }}>State</InputLabel>
        <Select
          value={selectedState}
          onChange={(e) => onStateChange(e.target.value)}
          sx={{ 
            color: 'white',
            borderRadius: '10px',
            '& .MuiOutlinedInput-notchedOutline': { borderColor: '#30363d' },
            '&:hover .MuiOutlinedInput-notchedOutline': { borderColor: '#6e7681' },
          }}
        >
          <MenuItem value="">All</MenuItem>
          <MenuItem value="open">Open</MenuItem>
          <MenuItem value="acknowledged">Acknowledged</MenuItem>
          <MenuItem value="closed">Closed</MenuItem>
        </Select>
      </FormControl>

      {/* Assignee Filter */}
      {/* <FormControl sx={{ minWidth: 200 }}>
        <InputLabel sx={{ color: 'white' }}>Assignee</InputLabel>
        <Select
          value={selectedAssignee}
          onChange={(e) => onAssigneeChange(e.target.value)}
          sx={{ 
            color: 'white',
            borderRadius: '10px',
            '& .MuiOutlinedInput-notchedOutline': { borderColor: '#30363d' },
            '&:hover .MuiOutlinedInput-notchedOutline': { borderColor: '#6e7681' },
          }}
        >
          <MenuItem value="">All</MenuItem>
          {users.map(user => (
            <MenuItem key={user.id} value={user.id}>{user.email}</MenuItem>
          ))}
        </Select>
      </FormControl> */}

      {/* Repo Filter */}
      <FormControl sx={{ minWidth: 200 }}>
        <InputLabel sx={{ color: 'white' }}>Repository</InputLabel>
        <Select
          value={selectedRepo}
          onChange={(e) => onRepoChange(e.target.value)}
          sx={{ 
            color: 'white',
            borderRadius: '10px',
            '& .MuiOutlinedInput-notchedOutline': { borderColor: '#30363d' },
            '&:hover .MuiOutlinedInput-notchedOutline': { borderColor: '#6e7681' },
          }}
        >
          <MenuItem value="">All</MenuItem>
          {repos.map(repo => (
            <MenuItem key={repo.repo_id} value={repo.repo_id}>{repo.name}</MenuItem>
          ))}
        </Select>
      </FormControl>

      {/* Date Range Filters */}
      <TextField
        type="date"
        label="Start Date"
        value={startDate}
        onChange={(e) => onStartDateChange(e.target.value)}
        InputLabelProps={{ shrink: true }}
        sx={{
          minWidth: '160px',
          '& .MuiOutlinedInput-root': {
            color: 'white',
            borderRadius: '10px',
            '& fieldset': { borderColor: '#30363d' },
            '&:hover fieldset': { borderColor: '#6e7681' },
          },
          '& .MuiInputLabel-root': { color: 'white' },
          '& input::-webkit-calendar-picker-indicator': {
            filter: 'invert(1)'
          }
        }}
      />
      <TextField
        type="date"
        label="End Date"
        value={endDate}
        onChange={(e) => onEndDateChange(e.target.value)}
        InputLabelProps={{ shrink: true }}
        sx={{
          minWidth: '160px',
          '& .MuiOutlinedInput-root': {
            color: 'white',
            borderRadius: '10px',
            '& fieldset': { borderColor: '#30363d' },
            '&:hover fieldset': { borderColor: '#6e7681' },
          },
          '& .MuiInputLabel-root': { color: 'white' },
          '& input::-webkit-calendar-picker-indicator': {
            filter: 'invert(1)'
          }
        }}
      />
    </Box>
  );
};

export default ErrorsFilters; 