import React from 'react';
import { Box } from '@mui/material';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { tomorrowNightBright as codeStyle } from "react-syntax-highlighter/dist/esm/styles/hljs";
import CopyButton from '@/components/Buttons/CopyButton';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  copyButton: {
    position: 'absolute',
    top: '8px',
    right: '8px'
  }
});

interface CodeBlockProps {
  children: string;
  language?: string;
  hideCopyButton?: boolean;
}

const CodeBlock: React.FC<CodeBlockProps> = ({ children, language, hideCopyButton }) => {
  const classes = useStyles();
  
  return (
    <div style={{ position: "relative"}}>
      <SyntaxHighlighter
        language={language || 'text'}
        style={codeStyle}
        customStyle={{
          borderRadius: "10px",
          padding: "16px",
          fontSize: "14px",
          backgroundColor: "transparent",
          border: "1px solid #30363D",
          textAlign: "left",
        }}

      >
        {children}
      </SyntaxHighlighter>
      {!hideCopyButton && (
        <Box className={classes.copyButton}>
          <CopyButton
            copyValue={children.replace(/\n$/, "")}
            backgroundColor="transparent"
            size="small"
            sx={{
              height: "20px",
            }}
          />
        </Box>
      )}
    </div>
  );
};

export default CodeBlock;