import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  Typography,
  List,
  ListItemButton,
  Avatar,
  ListItemAvatar,
  ListItemText,
  IconButton,
  Card,
  Divider,
  CircularProgress,
  useTheme,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import SpeedIcon from "@mui/icons-material/Speed";
import BugReportIcon from "@mui/icons-material/BugReport";
import SecurityIcon from "@mui/icons-material/Security";
import SearchBar from "@/components/SearchBar/SearchBar";
import InfiniteScroll from "react-infinite-scroll-component";
import { AxiosResponse } from "axios";
import { list_manual_agent } from "@/services/Blar/Agents";
import { Context } from "@/contexts/ContextProvider";
import { Execution } from "./types";

interface Props {
  selectedId: string | null;
  onSelect: (execution: Execution) => void;
  refreshExecutions: number; // New prop
}

const getAgentIcon = (agent: string) => {
  switch (agent) {
    case "optimizer":
      return <SpeedIcon />;
    case "debugger":
      return <BugReportIcon />;
    case "cyber_security":
      return <SecurityIcon />;
    default:
      return <BugReportIcon />;
  }
};

const PastExecutionsList: React.FC<Props> = ({
  selectedId,
  onSelect,
  refreshExecutions,
}) => {
  const [executions, setExecutions] = useState<Execution[]>([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [hasMore, setHasMore] = useState(true);
  const [offset, setOffset] = useState(0);
  const limit = 10;
  const theme = useTheme();

  const { showMessage } = useContext(Context);

  const fetchNextData = async () => {
    try {
      const response: AxiosResponse = await list_manual_agent(
        { limit, offset },
        { search: searchQuery }
      );
      const newData = response.data.results as Execution[];
      setExecutions((prev) => [...prev, ...newData]);
      setHasMore(response.data.next !== null);
      setOffset((prevOffset) => prevOffset + limit);
    } catch (error) {
      showMessage("error", "Failed to load executions");
    }
  };

  // Fetch initial data or reset when search query or refreshExecutions changes.
  useEffect(() => {

    const fetchData = async () => {
      try {
        const response: AxiosResponse = await list_manual_agent(
          { limit, offset: 0 },
          { search: searchQuery }
        );
        const newData = response.data.results as Execution[];
        setExecutions(newData);
        setHasMore(response.data.next !== null);
        setOffset(limit);
      } catch (error) {
        showMessage("error", "Failed to fetch executions");
      }
    };

    // Reset list when searchQuery or refreshExecutions changes
    fetchData();
  }, [searchQuery, refreshExecutions]);

  return (
    <Box
      sx={{ height: "100%", overflowY: "auto", p: 2, pr: 0 }}
      id="scrollableDiv"
    >
      <SearchBar
        onChange={setSearchQuery}
        placeholder="Search..."
        sx={{ marginBottom: 2 }}
      />

      <InfiniteScroll
        dataLength={executions.length}
        next={fetchNextData}
        hasMore={hasMore}
        loader={
          <Box sx={{ display: "flex", justifyContent: "center", p: 2 }}>
            <CircularProgress />
          </Box>
        }
        endMessage={
          <Typography variant="body2" align="center">
            No more data
          </Typography>
        }
        scrollableTarget="scrollableDiv"
      >
        <List sx={{ p: 0 }}>
          {executions.map((exec) => {
            const createdAt = new Date(exec.created_at);
            const date = createdAt.toLocaleDateString();
            const time = createdAt.toLocaleTimeString();
            const name = exec.name.split("/").slice(3).join("/");

            const isComplete = exec.task_state === "complete";
            const isPending =
              exec.task_state === "pending" ||
              exec.task_state === "in_progress";
            const isFailed = exec.task_state === "failed";

            return (
              <Card
                key={exec.id}
                elevation={2}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  textAlign: "left",
                  gap: 2,
                  mb: 2,
                  borderRadius: "12px",
                  border: "1px solid",
                  borderColor: theme.palette.divider,
                  backgroundColor: selectedId === exec.id ? "#30363D" : "#161B22",
                  transition: "0.3s",
                  p: 3,
                  opacity: isComplete ? 1 : 0.7,
                  width: "100%", // Ensure card spans the full container width
                }}
                onClick={() => onSelect(exec)}
              >
                {/* Left side: Agent Icon + Text */}
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 2,
                    minWidth: 0, // Prevent text overflow from pushing icons away
                  }}
                >
                  <ListItemAvatar>
                    <Avatar sx={{ bgcolor: "transparent", color: "#FFF" }}>
                      {getAgentIcon(exec.agent)}
                    </Avatar>
                  </ListItemAvatar>
                  <Box
                    sx={{
                      minWidth: 0, // Allow text truncation if needed
                      flexShrink: 1,
                      overflow: "hidden", // optional: to prevent overflow
                    }}
                  >
                    <Typography
                      variant="subtitle1"
                      sx={{ fontWeight: "bold", color: "#FFF", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}
                    >
                      {exec.agent}
                    </Typography>
                    <Typography variant="body2" color="gray" sx={{ overflow: "hidden", overflowWrap: "anywhere" }}>
                      {name}
                    </Typography>
                    <Typography variant="caption" color="gray">
                      {date}, {time}
                    </Typography>
                  </Box>
                </Box>

                {/* Right side: Status + Menu Icon */}
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  {isPending && (
                    <CircularProgress size={20} sx={{ color: "#FFF" }} />
                  )}
                  {isFailed && (
                    <Typography variant="caption" sx={{ color: "error.main" }}>
                      Failed
                    </Typography>
                  )}
                  <IconButton sx={{ color: "#FFF" }}>
                    <MoreVertIcon />
                  </IconButton>
                </Box>
              </Card>
            );
          })}
        </List>
      </InfiniteScroll>

      <Divider sx={{ my: 2, borderColor: "gray" }} />
    </Box>
  );
};

export default PastExecutionsList;
