const summaryDataMap = (data: any) => {
  const pullRequestData = data.map((item: any) => {
    return {
      repo_name: item.repo_name,
      pull_request_feature_flag: item.pull_request_feature_flag
    }
  })
  const guidelinesData = data.map((item: any) => {
    return {
      repo_name: item.repo_name,
      wiki_step_status: item.wiki_step_status
    }
  })

  return {
    pullRequestData,
    guidelinesData
  }
}

export { summaryDataMap }