import React, { useState, useEffect, useContext, useCallback } from "react";
import { Box, FormControl, Typography, TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { Repository } from "@/interfaces/IBackendOutputs";
import { AxiosError, AxiosResponse } from "axios";
import { git_repository_info, search_git_repos } from "@/services/Blar/Integrations";
import { Context } from "@/contexts/ContextProvider";
import { LoadingButton } from "@mui/lab";
import GitHubIcon from "@mui/icons-material/GitHub";
import { CreateRepoParams } from "@/pages/Settings/components/Repos/types";
import { create_repo } from "@/services/Blar/Repo_graph";
import BranchSelector from "./BranchSelector";
import CustomPaper from "./CustomPaper";
interface RepositoryBranchSelectorProps {
  setHasRepo: React.Dispatch<React.SetStateAction<boolean>>;
  defaultBranches?: string[];
  onAddRepository: (repoName: string) => void;
}

const RepositoryBranchSelector: React.FC<RepositoryBranchSelectorProps> = ({ setHasRepo, defaultBranches, onAddRepository }) => {
  const { showMessage } = useContext(Context);
  const [repositories, setRepositories] = useState<Repository[]>([]);
  const [selectedRepository, setSelectedRepository] = useState<Repository | null>(null);
  const [selectedBranches, setSelectedBranches] = useState<string[]>([]);
  const [loadingRepos, setLoadingRepos] = useState(false);
  const [isRepositoryTooBig, setIsRepositoryTooBig] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');

  const escapeSearchQuery = (query: string): string => {
    return query.replace(/[.*+?^${}()|[\]\\]/g, '\\$&').trim();
  };

  const fetchRepositories = useCallback(async (query: string) => {
    try {
      if (query === '') {
        setRepositories([]);
        return;
      }
      setLoadingRepos(true);
      const escapedQuery = escapeSearchQuery(query);
      const repositoriesResponse: AxiosResponse = await search_git_repos(escapedQuery);
      if (repositoriesResponse.status === 200) {
        const results = repositoriesResponse.data || [];
        setRepositories(results);
      }
    } catch (error) {
      showMessage("error", "Failed to get repositories");
    } finally {
      setLoadingRepos(false);
    }
  }, []);

  useEffect(() => {
    if (searchQuery) {
      fetchRepositories(searchQuery);
    }
  }, [searchQuery]);

  const handleRepositorySelection = async (
    event: any,
    newValue: Repository | null
  ) => {
    setSelectedRepository(newValue);
    setSelectedBranches([]);
    setIsRepositoryTooBig(false);
    if (newValue) {
      const repositoryName = newValue.fullName;
      const [org, repo] = repositoryName.split("/");
      try {
        const repositoryInfoRequest = await git_repository_info(org, repo);
        const repositoryInfo = repositoryInfoRequest.data;
        const size = repositoryInfo.size;
        // Check if the repository is too big (2GB limit)
        if (size > 2000000) {
          console.warn("⚠️ Repository too big:", size);
          setIsRepositoryTooBig(true);
        }
      } catch (error) {
        // Optionally handle errors here
      }
    }
  };

  const handleAddRepository = async () => {
    if (selectedRepository && selectedBranches.length > 0 && selectedBranches.length < 3) {
      try {
        setLoadingRepos(true);
        const addRepoParams: CreateRepoParams = {
          repo_name: selectedRepository.fullName,
          git_url: selectedRepository.url,
          main_branch: selectedBranches[0],
          development_branch: selectedBranches.length === 1 ? selectedBranches[0] : selectedBranches[1],
        };
        const createRepoResponse = await create_repo(addRepoParams);
        if (createRepoResponse.status === 201) {
          showMessage("success", "Repository added successfully");
          setHasRepo(true);
          onAddRepository(selectedRepository.fullName);
        }
        setSelectedRepository(null);
      } catch (error) {
        if (error instanceof AxiosError) {
          if (error.response?.status === 400 && error.response) {
            showMessage("error", error.response.data.error);
            return;
          }
          showMessage("error", "An unexpected error occurred");
          return;
        }
        showMessage("error", "Failed to add repository");
      } finally {
        setLoadingRepos(false);
      }
    }
  };

  return (
    <Box width={"100%"}>
      <FormControl fullWidth>
        <Autocomplete
          id="repository-autocomplete"
          options={repositories || []}
          value={selectedRepository}
          loading={loadingRepos}
          loadingText="Searching repositories..."
          noOptionsText={
            searchQuery === ''
              ? "Type to search a repository"
              : repositories.length === 0 && !loadingRepos
                ? "No repositories found for the query"
                : "No options"
          }
          getOptionLabel={(option) => option.fullName}
          onChange={handleRepositorySelection}
          renderInput={(params) => (
            <TextField {...params} label="Search a repository" variant="outlined" />
          )}
          onInputChange={(event, newValue) => {
            setSearchQuery(newValue);
          }}
          PaperComponent={CustomPaper}
          ListboxProps={{
            style: { maxHeight: 300, overflowY: "auto" },
          }}
        />
      </FormControl>
      
      {isRepositoryTooBig && (
        <Typography variant="body1" color="error">
          Currently, we don't support repositories bigger than 2GB. Please select another one.
        </Typography>
      )}
      
      {selectedRepository && !isRepositoryTooBig && (
        <BranchSelector
          repositoryFullName={selectedRepository.fullName}
          selectedBranches={selectedBranches}
          onBranchesChange={setSelectedBranches}
          disabled={isRepositoryTooBig}
          defaultBranches={defaultBranches}
        />
      )}

      <LoadingButton
        variant="contained"
        color="secondary"
        startIcon={<GitHubIcon />}
        style={{ fontSize: "20px", margin: "16px 0" }}
        onClick={handleAddRepository}
        loading={loadingRepos}
        disabled={
          !selectedRepository ||
          selectedBranches.length === 0 ||
          isRepositoryTooBig
        }
      >
        Add Repository
      </LoadingButton>
    </Box>
  );
};

export default RepositoryBranchSelector;