import { Box, Typography, Button, useTheme, CircularProgress } from "@mui/material";
import { manually_get_user_comments } from "@/services/Blar/Integrations";
import { useContext } from "react";
import { Context } from "@/contexts/ContextProvider";
import { useState } from "react";
import { GitUserComment } from "./GithubImportContent";

interface GetCommentCardProps {
  repo_name: string;
  setComments: (comments: GitUserComment[]) => void;
}

export const GetCommentCard = ({ repo_name, setComments }: GetCommentCardProps) => {
  const theme = useTheme();
  const { showMessage } = useContext(Context);
  const [loading, setLoading] = useState(false);
  const handleGetComments = async () => {
    try {
      setLoading(true);
      const comments = await manually_get_user_comments(repo_name);
      setComments(comments.data);
      showMessage("success", "Comments fetched successfully");
    } catch (error) {
      showMessage("error", "Failed to fetch comments");
    } finally {
      setLoading(false);
    }
  }
  return (
    <Box width="50%" mx="auto" border={`1px solid ${theme.palette.divider}`} borderRadius={"10px"} textAlign="center" flexDirection="row" p={2}>
      <Typography variant="body1">No comments found for this repository in our database. We can get some for you.</Typography>
      <Button sx={{ mt: 2 }} variant="outlined" size="medium" onClick={handleGetComments} disabled={loading}>{loading ? <CircularProgress size={20} color="inherit" /> : "Get comments"}</Button>
    </Box>
  );
};

