import React from "react";
import { useState, useContext, useEffect } from "react";
import {
  Box,
  Typography,
  CircularProgress,
} from "@mui/material";

import BaseModal from "@/components/Modal/BaseModal";
import { Context } from "@/contexts/ContextProvider";
import {
  check_code_git_integration_exists,
} from "@/services/Blar/Integrations";
import RepositoryBranchSelector from "@/components/Selectors/RepositoryBranchSelector";

type AddIntegrationModalProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  setCreatedObject: (createdObject: number) => void;
};

const AddRepoModal = ({
  open,
  setOpen,
  setCreatedObject,
}: AddIntegrationModalProps) => {
  const [isGitConnected, setIsGitConnected] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const { showMessage } = useContext(Context);

  const handleClose = () => {
    setOpen(false);
  };

  const handleRepoAdded = () => {
    // Close modal first to prevent unnecessary renders
    handleClose();
    // Then update table data
    setCreatedObject(Date.now());
  };

  useEffect(() => {
    const checkGitIntegrationExists = async (): Promise<boolean> => {
      try {
        setLoading(true);
        const res = await check_code_git_integration_exists();
        setIsGitConnected(res.data.is_integrated);
        return res.data.is_integrated;
      } catch (error) {
        showMessage("error", "Failed to get Github integration");
      } finally {
        setLoading(false);
      }
      return false;
    };

    if (open && !isGitConnected) {
      checkGitIntegrationExists();
    }
  }, [open, isGitConnected]);

  return (
    <BaseModal
      open={open}
      onClose={handleClose}
      title="Integrate New Repo"
      style={{ width: "50%" }}
    >
     {loading ? <CircularProgress/> : <Box sx={{ display: "flex", flexDirection: "column" }}>
        {isGitConnected ? (
          <>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                marginBottom: "20px",
                width: "100%",
              }}
            >
              <RepositoryBranchSelector
                setHasRepo={handleRepoAdded}
                onAddRepository={handleRepoAdded}
              />
            </Box>
          </>
        ) : (
          <Box
            sx={{ display: "flex", alignItems: "center", marginTop: "20px" }}
          >
            <Typography variant="body1">
              Please connect your Github account to add a repository
            </Typography>
          </Box>
        )}
      </Box>}
    </BaseModal>
  );
};

export default AddRepoModal;
