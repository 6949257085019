import React, { useEffect, useState } from "react";
import {
  Box,
  Collapse,
  IconButton,
  LinearProgress,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  TextField,
  Tooltip,
  PaletteColor,
  Typography,
} from "@mui/material";
import {
  CreateNewFolder,
  Folder,
  FolderOpen,
  InsertDriveFile,
  NoteAdd,
  ArrowBack,
} from "@mui/icons-material";
import { FileNode } from "../types";
import { getFolderContents } from "@/services/Blar/Wiki";
import ContextMenu from "./ContextMenu";
import ConfirmDeleteModal from "@/components/Tables/ConfirmDeleteModal";
import { useWiki } from "../context/WikiContext";
import { useWikiFileStructure } from "../context/WikiFileStructureContext";
import { makeContentEditable } from "@/utils/dom";

const FileStructure: React.FC = () => {
  const {
    selectedNode,
    setSelectedNode,
    loadingCount,
    setLoadingCount,
    handleFileClick,
    inputFileContent,
  } = useWiki();
  
  const { 
    handleDelete, 
    parseResponse, 
    structure,
    setStructure,
    handleCreateFile,
    handleCreateFolder,
    handleRename,
    repoFolder,
    setRepoFolder,
    openFolders,
    setOpenFolders,
    isRepoFolder,
    updateNodeInStructure,
    handleToggle
  } = useWikiFileStructure();

  const [newNodeName, setNewNodeName] = useState<string>("");
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [creatingNode, setCreatingNode] = useState<{
    type: "file" | "folder";
    parentId: number | null;
  } | null>(null);

  const [contextMenu, setContextMenu] = useState<null | HTMLElement>(null);
  const [isCreating, setIsCreating] = useState(false);

  const handleClose = () => {
    setContextMenu(null);
  };

  const handleRightClick = (event: React.MouseEvent, node: FileNode) => {
    event.preventDefault();
    setSelectedNode(node);
    setContextMenu(event.currentTarget as HTMLElement);
  };

  const handleCreateSubmit = async () => {
    if (creatingNode && newNodeName.trim() && !isCreating) {
      setIsCreating(true);
      try {
        if (creatingNode.type === "folder") {
          await handleCreateFolder(newNodeName.trim(), creatingNode.parentId);
        } else {
          await handleCreateFile(newNodeName.trim(), creatingNode.parentId, "");
        }
      } finally {
        setCreatingNode(null);
        setNewNodeName("");
        setIsCreating(false);
      }
    }
  };

  const handleRepoFolderClose = (e: React.MouseEvent, nodeId: number) => {
    e.stopPropagation();
    setSelectedNode(null);
    setRepoFolder(null);
    setOpenFolders((prev: { [key: string]: boolean }) => ({
      ...prev,
      [nodeId]: false
    }));
  };

  const handleToggleOnCreate = async (node: FileNode) => {
    if (node.type !== "folder") {
      return;
    }
    
    const isOpen = openFolders[node.id];
    if (!isOpen) {
      setOpenFolders((prev: { [key: string]: boolean }) => ({
        ...prev,
        [node.id]: true
      }));

      if (!node.fetched) {
        try {
          setLoadingCount(prev => prev + 1);
          const response = await getFolderContents(node.id);
          const parsedChildren = parseResponse(response);
          const updatedNode = {
            ...node,
            children: parsedChildren,
            fetched: true
          };
          setStructure(prev => updateNodeInStructure(prev, updatedNode));
        } finally {
          setLoadingCount(prev => prev - 1);
        }
      }
    }
  };

  const getRootId = (node: FileNode): number => {
    if (!node.parent) {
      return node.id;
    }
    const findNodeById = (nodes: FileNode[], id: number): FileNode | null => {
      for (const n of nodes) {
        if (n.id === id) return n;
        if (n.children) {
          const found = findNodeById(n.children, id);
          if (found) return found;
        }
      }
      return null;
    };
    const parentId = Number(node.parent);
    const parentNode = findNodeById(structure, parentId);
    if (!parentNode) {
      return parentId;
    }
    return getRootId(parentNode);
  };

  const getParentId = () => {
    if (!selectedNode) return null;
    if (selectedNode.type === "file") {
      const parentId = Number(selectedNode.parent);
      const rootId = getRootId(selectedNode);
      if (parentId === rootId) {
        return null;
      }
      return parentId;
    }
    return Number(selectedNode.id);
  };

  const isInRootFolder = (node: FileNode | null): boolean => {
    if (!node) return false;
    return node.type === 'folder' && !node.parent;
  };

  const isInsideRootFolder = (node: FileNode | null): boolean => {
    if (!node) return false;
    if (node.type === 'file') {
      return !!node.parent && !structure.find(n => n.id === node.parent)?.parent;
    }
    return !!node.parent && !structure.find(n => n.id === node.parent)?.parent;
  };

  const getFolderIcon = (node: FileNode): React.ReactNode => {
    if (isRepoFolder(node)) {
      if (openFolders[node.id]) {
        return <ArrowBack onClick={(e) => handleRepoFolderClose(e, node.id)} />;
      }
      return null;
    }
    
    return openFolders[node.id] ? <FolderOpen /> : <Folder />;
  };

  const getNodeIcon = (node: FileNode): React.ReactNode => {
    if (node.type === "folder") {
      return getFolderIcon(node);
    }
    return <InsertDriveFile />;
  };

  const shouldRenderNode = (node: FileNode): boolean => {
    if (isRepoFolder(node)) {
      return repoFolder === null || repoFolder.id === node.id;
    }
    return true;
  };

  const formatRepoName = (name: string): string => {
    return name;
  };

  const renderFileTree = (
    nodes: FileNode[],
    level: number = 0
  ): React.ReactNode => {
    return nodes.map((node) => (
      <React.Fragment key={node.id}>
        {shouldRenderNode(node) && (
          <>
            {isRepoFolder(node) ? (
              <Tooltip 
                title="Right click for more options"
                placement="right"
                enterDelay={1000}
              >
                <Box 
                  sx={{ 
                    border: (theme) => `1px solid ${theme.palette.divider}`, 
                    width: '100%', 
                    backgroundColor: (theme) => selectedNode?.id === node.id ? 
                      theme.palette.divider : theme.palette.background.paper, 
                    borderRadius: '10px', 
                    padding: '15px',
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                    mb: 1,
                    height: '54px',
                  }} 
                  onClick={() => handleToggle(node)}
                  onContextMenu={(event) => handleRightClick(event, node)}
                  data-node-id={node.id}
                >
                  <Box sx={{ mr: 2, width: '24px', display: 'flex', justifyContent: 'center' }}>
                    {getFolderIcon(node)}
                  </Box>
                  <Typography
                    sx={{
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      maxWidth: 'calc(100% - 40px)'
                    }}
                  >
                    {formatRepoName(node.name)}
                  </Typography>
                </Box>
              </Tooltip>
            ) : (
              <Tooltip 
                title="Right click for more options"
                placement="right"
                enterDelay={1000}
              >
                <ListItemButton
                  data-node-id={node.id}
                  draggable
                  onContextMenu={(event) => handleRightClick(event, node)}
                  onClick={node.type === "file" ? () => handleFileClick(node) : () => handleToggle(node)}
                  sx={{ pl: level * 2 }}
                >
                  <ListItemIcon>
                    {getNodeIcon(node)}
                  </ListItemIcon>
                  <ListItemText 
                    primary={node.name} 
                    primaryTypographyProps={{
                      sx: {
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'
                      }
                    }}
                  />
                </ListItemButton>
              </Tooltip>
            )}
          </>
        )}
        {node.type === "folder" && (
          <Collapse in={openFolders[node.id]} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {renderFileTree(node.children || [], level + 1)}
              {creatingNode && creatingNode.parentId === node.id && (
                <ListItemButton sx={{ pl: (level + 1) * 2 }}>
                  <TextField
                    value={newNodeName}
                    onChange={(e) => setNewNodeName(e.target.value)}
                    onBlur={handleCreateSubmit}
                    onKeyDown={(e) => {
                      if (e.key === "Enter" && !isCreating) {
                        handleCreateSubmit();
                      } else if (e.key === "Escape") {
                        setCreatingNode(null);
                        setNewNodeName("");
                      }
                    }}
                    disabled={isCreating}
                    autoFocus
                    placeholder={`New ${creatingNode.type}`}
                    size="small"
                  />
                </ListItemButton>
              )}
            </List>
          </Collapse>
        )}
      </React.Fragment>
    ));
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (creatingNode) {
        const target = event.target as HTMLElement;
        if (!target.closest('input')) {
          setCreatingNode(null);
          setNewNodeName('');
        }
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [creatingNode]);

  const handleContextMenuRename = () => {
    if (selectedNode) {
      handleClose();
      setCreatingNode(null);
      setNewNodeName(selectedNode.name);
      
      setTimeout(() => {
        const parentElement = document.querySelector(`[data-node-id="${selectedNode.id}"]`);
        if (parentElement) {
          const textElement = parentElement.querySelector('.MuiListItemText-primary') as HTMLElement;
          if (textElement) {
            makeContentEditable(textElement, selectedNode.name, async (newName) => {
              await handleRename(selectedNode, newName);
            });
          }
        }
      }, 100);
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      height="100%"
      padding={"20px"}
      sx={{
        background: (theme) =>
          (theme.palette.secondaryBackground as PaletteColor).main,
      }}
      style={{ cursor: "context-menu" }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 2,
          height: 40,
          minHeight: 40,
        }}
      >
        <Typography variant="h5" sx={{ fontWeight: "bold", marginBottom: 2 }}>Wiki</Typography>
        {inputFileContent !== null && (
          <Box sx={{ 
            display: 'flex',
            alignItems: 'center',
            minWidth: 80,
            justifyContent: 'flex-end'
          }}>
            {(isInRootFolder(selectedNode) || isInsideRootFolder(selectedNode)) && (
              <>
                <Tooltip title="Create Folder">
                  <IconButton
                    onClick={() =>
                      setCreatingNode({
                        type: "folder",
                        parentId: getParentId(),
                      })
                    }
                  >
                    <CreateNewFolder />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Create File">
                  <IconButton
                    onClick={() =>
                      setCreatingNode({
                        type: "file",
                        parentId: getParentId(),
                      })
                    }
                  >
                    <NoteAdd />
                  </IconButton>
                </Tooltip>
              </>
            )}
          </Box>
        )}
      </Box>
      {loadingCount > 0 && <LinearProgress />}
        <Box
          id="scrollableDiv"
          sx={{ flexGrow: 1, overflow: "auto", height: "100%" }}
        >
          <List>
            {renderFileTree(structure)}
          </List>
        </Box>
        <ContextMenu
            anchorEl={contextMenu}
            handleClose={handleClose}
            handleCreateFile={() => {
              handleClose();
              handleToggleOnCreate(selectedNode as FileNode);
              setCreatingNode({
                type: "file",
                parentId: getParentId(),
              });
            }}
            handleCreateFolder={() => {
              handleClose();
              handleToggleOnCreate(selectedNode as FileNode);
              setCreatingNode({
                type: "folder",
                parentId: getParentId(),
              });
            }}
            handleRename={handleContextMenuRename}        
            handleDelete={() => setDeleteModalOpen(true)}
            isRepoFolder={isRepoFolder(selectedNode)}
          />
          <ConfirmDeleteModal
            open={deleteModalOpen}
            setOpen={setDeleteModalOpen}
            onConfirm={() => {
              if (selectedNode) {
                handleDelete(selectedNode);
                setDeleteModalOpen(false);
                handleClose();
              }
            }}
        />
    </Box>
  );
};

export default FileStructure;
