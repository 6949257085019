import React, { useState } from "react";
import { Panel } from "reactflow";
import { Box, Typography, IconButton } from "@mui/material";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import SyntaxHighlighter from "react-syntax-highlighter";
import { tomorrowNightBright as codeStyle } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { MyNode } from "./GraphView";

interface NodeInfoPanelProps {
    node: MyNode;
}

const NodeInfoPanel: React.FC<NodeInfoPanelProps> = ({ node }) => {
    const [expanded, setExpanded] = useState(false);

    const toggleExpanded = () => setExpanded((prev) => !prev);

    return (
        <Panel position="bottom-right">
            <Box
                sx={{
                    textAlign: "left",
                    border: "1px solid #ccc",
                    borderRadius: 2,
                    backgroundColor: "background.paper",
                    p: 2,
                    overflowY: "auto",
                    overflowX: "auto",
                    maxHeight: expanded ? "60vh" : "30vh",
                    maxWidth: expanded ? "40vw" : "500px",
                    boxShadow: 3,
                    zIndex: 1000,
                    position: "relative",
                }}
            >
                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <Typography variant="h6" sx={{ textAlign: "center" }} gutterBottom>
                        {node.label}
                    </Typography>
                    <IconButton size="small" onClick={toggleExpanded}>
                        {expanded ? <FullscreenExitIcon /> : <FullscreenIcon />}
                    </IconButton>
                </Box>
                {node.node_path && (
                    <Typography variant="body2">
                        <strong>Path:</strong> {node.node_path.split("/").slice(4).join("/")}
                    </Typography>
                )}
                {(node.start_line || node.end_line) && (
                    <Typography variant="body2">
                        <strong>Lines:</strong> {node.start_line} - {node.end_line}
                    </Typography>
                )}
                {node.labels && (
                    <Typography variant="body2">
                        <strong>Labels:</strong> {node.labels.join(", ")}
                    </Typography>
                )}
                <Typography variant="body2">
                    <strong>Relevant:</strong> {node.relevant ? "Yes" : "No"}
                </Typography>
                <Box sx={{ mt: 2 }}>
                    <SyntaxHighlighter
                        language="python"
                        style={codeStyle}
                        customStyle={{
                            borderRadius: "20px",
                            padding: "20px",
                            fontSize: "14px",
                        }}
                    >
                        {String(node.text).replace(/\n$/, "")}
                    </SyntaxHighlighter>
                </Box>
            </Box>
        </Panel>
    );
};

export default NodeInfoPanel;
