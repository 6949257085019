import React, { ReactNode, createContext, useContext, useEffect, useRef, useState } from "react";

import { connectWebSocket } from "../services/Websocket/connect";
import { w3cwebsocket } from "websocket";
import { Context } from "./ContextProvider";

export interface NotificationData {
    task_state: string;
    action: string;
    repo_id: string;
    pr_number: string;
}

export interface iNotification {
    title: string;
    body: string;
    data: NotificationData;
    silent: boolean;
    template: string;
}

interface NotificationsContextProps {
    subscribeToNotifications: (subscriber: Function) => void;
    unsubscribeFromNotifications: (subscriber: Function) => void;
}

const NotificationsContext = createContext<NotificationsContextProps | undefined>(undefined);

export const NotificationsContextProvider = ({ access, refresh, children }: { access: string, refresh: string, children: ReactNode }) => {
    const subscribersRef = useRef<Function[]>([]);
    const closeNotificationsRef = useRef(false);
    const wsNotificationsRef = useRef<w3cwebsocket | null>(null);
    const { showMessage } = useContext(Context);
    const [wsNotificationsUrl, setWsNotificationsUrl] = useState<string>(
        `${process.env.REACT_APP_WS_URL}/notifications/?token=${access}&refresh_token=${refresh}`
    );

    const onMessage = (event: any) => {
        try {
            const message = JSON.parse(event.data as string);
            subscribersRef.current.forEach((subscriber) => subscriber(message.notification));
        } catch (error) {
            console.error(error);
        }
    };

    const onOpen = (socket: w3cwebsocket | null): void => {
        wsNotificationsRef.current = socket;
    }

    const onClose = () => {
        wsNotificationsRef.current = null;
    }


    useEffect(() => {

        closeNotificationsRef.current = false;
        if (wsNotificationsRef.current === null) {
            try {
                connectWebSocket(
                    wsNotificationsUrl,
                    onMessage,
                    onOpen,
                    undefined,
                    onClose,
                    closeNotificationsRef
                );
            } catch (error) {
                console.error(error);
                showMessage("error", "Error connecting to notifications");
            }
        }

        return () => {
            if (wsNotificationsRef.current) {
                closeNotificationsRef.current = true;
                wsNotificationsRef.current.close();

            }
        };
    }, [wsNotificationsUrl]);

    useEffect(() => {
        setWsNotificationsUrl(`${process.env.REACT_APP_WS_URL}/notifications/?token=${access}&refresh_token=${refresh}`);
    }, [access, refresh]);

    const subscribeToNotifications = (subscriber: Function) => {
        subscribersRef.current.push(subscriber);
    };

    const unsubscribeFromNotifications = (subscriber: Function) => {
        subscribersRef.current = subscribersRef.current.filter((sub) => sub !== subscriber);
    };

    return (
        <NotificationsContext.Provider value={{ subscribeToNotifications, unsubscribeFromNotifications }}>
            {children}
        </NotificationsContext.Provider>
    );

};

export const useNotifications = (): NotificationsContextProps => {
    const context = useContext(NotificationsContext);
    if (!context) {
        throw new Error("useNotifications must be used within a NotificationsProvider");
    }
    return context;
};
