import ContinueButton from '@/components/Buttons/ContinueButton';
import { Box, Typography, Chip, Button, Dialog, DialogContent, DialogTitle } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react';
import LoadingTypography from './components/LoadingTypography';
import useStyles from "./styles/SelectSeats";
import BackButton from "@/components/Buttons/BackButton";
import SelectUsersSeats from '@/components/Select Users Seats/SelectUsersSeats';
import { count_selected_seats } from '@/services/Blar/Git';
import { Context } from '@/contexts/ContextProvider';

interface SelectSeatsProps {
  continueOnboarding: () => void;
  backOnboarding: () => void;
}

const SelectSeats: React.FC<SelectSeatsProps> = ({
  continueOnboarding,
  backOnboarding
}) => {
  const classes = useStyles();
  const { showMessage } = useContext(Context);
  const [open, setOpen] = useState(false);
  const [seatsCount, setSeatsCount] = useState<number>(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const countSelectedSeats = async () => {
      setLoading(true);
      try {
        const seletedSeatCountRequest = await count_selected_seats();
        setSeatsCount(seletedSeatCountRequest.data.result);
      } catch (error) {
        showMessage("error", "Failed to get selected seats");
      }
      
      setLoading(false);
    };
    countSelectedSeats();
  }, [open])

  return (
    <Box className={classes.root}>
      <LoadingTypography
        text="Select your seats"
        loading={loading}
        stepCompleted={false}
      />
      <Typography variant='h4' mb={1} fontSize={20} color='text.secondary'>
        Blar subscription is based on the number of seats you need. We will only review the PR made by the users you select. 
        You can always add more seats later.
      </Typography>

      <Button variant="contained" onClick={() => setOpen(true)}>
        Select the users that are going to have access to Blar
      </Button>

      <Dialog open={open} onClose={() => setOpen(false)} maxWidth="md" fullWidth>
        <DialogTitle>Select your seats</DialogTitle>
          <SelectUsersSeats />
        <DialogContent>
        </DialogContent>
      </Dialog>


      <Chip
        variant="outlined"
        label={`${seatsCount} seats selected`}
        color={"primary"}
      />
      
      <Box className={classes.buttonContainer}>
        <BackButton backOnboarding={backOnboarding} />
        <ContinueButton
          continueOnboarding={continueOnboarding}
          disabled={seatsCount === 0}
        />
      </Box>
    </Box>
  );
};

export default SelectSeats;
