import { blarClient } from "./Client";


export const get_subscription = async () => {
  return blarClient.request("get", `/subscriptions/`);
};

export const create_subscription = async (planId: string) => {
  return blarClient.request("post", `/subscriptions/`, { plan_id: planId });
};

export const get_subscription_plans = async () => {
  return blarClient.request("get", `/subscriptions/plans/`);
}

export const cancel_subscription = async () => {
  return blarClient.request("delete", `/subscriptions/`);
}