import React from "react";
import BaseModal from "./BaseModal";
import { TextField, Typography, Button } from "@mui/material";
import { Repo } from "@/pages/Settings/components/Repos/types";

interface ImportModalProps {
  open: boolean;
  onClose: () => void;
  guidelinesText: string;
  setGuidelinesText: (text: string) => void;
  loadingImport: boolean;
  selectedRepo: Repo | null;
  onSubmit: () => void;
}

const ImportModal: React.FC<ImportModalProps> = ({
  open,
  onClose,
  guidelinesText,
  setGuidelinesText,
  loadingImport,
  onSubmit
}) => (
  <BaseModal
    open={open}
    onClose={onClose}
    title="Add guidelines from documentation"
    actions={
      <>
        <Button 
          onClick={onClose}
          variant="outlined"
          sx={{ minWidth: '120px' }}
        >
          Cancel
        </Button>
        <Button 
          variant="contained"
          sx={{ minWidth: '120px' }}
          onClick={onSubmit}
          disabled={loadingImport}
        >
          {loadingImport ? 'Adding...' : 'Add this docs to Wiki'}
        </Button>
      </>
    }
  >
    <Typography variant="body1" color="text.secondary" sx={{ mb: 2 }}>
      Paste your guideline docs from Notion, Confluence or where ever you have them.
    </Typography>
    <TextField
      multiline
      rows={10}
      fullWidth
      value={guidelinesText}
      onChange={(e) => setGuidelinesText(e.target.value)}
      placeholder="Paste your guidelines here..."
      variant="outlined"
      sx={{ mb: 3 }}
    />
  </BaseModal>
);

export default ImportModal;
