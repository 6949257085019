import React, { useState, useEffect, useContext } from "react";
import {
  FormControl,
  TextField,
  Typography,
  Chip,
  Autocomplete,
  Tooltip,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { Context } from "@/contexts/ContextProvider";
import { search_git_repo_branches } from "@/services/Blar/Integrations";
import CustomPaper from "./CustomPaper";
interface BranchSelectorProps {
  repositoryFullName: string;
  selectedBranches: string[];
  onBranchesChange: (branches: string[]) => void;
  disabled?: boolean;
  defaultBranches?: string[];
}

const BranchSelector: React.FC<BranchSelectorProps> = ({
  repositoryFullName,
  selectedBranches,
  onBranchesChange,
  disabled = false,
  defaultBranches,
}) => {
  const { showMessage } = useContext(Context);
  const [branches, setBranches] = useState<string[]>([]);
  const [loadingBranches, setLoadingBranches] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');

  const escapeSearchQuery = (query: string): string => {
    return query.replace(/[.*+?^${}()|[\]\\]/g, '\\$&').trim();
  };

  const fetchBranches = async (repo: string, query: string) => {
    try {
      if (query === '') {
        setBranches([]);
        return;
      }

      setLoadingBranches(true);
      const escapedQuery = escapeSearchQuery(query);
      const branchesResponse = await search_git_repo_branches(repo, escapedQuery);

      if (branchesResponse.status === 200) {
        const results = branchesResponse.data || [];
        setBranches(results);
        if (selectedBranches.length === 0 && defaultBranches) {
          for (const branch of defaultBranches) {
            if (results.includes(branch)) {
              onBranchesChange([branch]);
              break;
            }
          }
        }
      }
    } catch (error) {
      showMessage("error", 'Failed to get branches');
      console.error(error);
    } finally {
      setLoadingBranches(false);
    }
  };

  useEffect(() => {
    setBranches([]);
    if (repositoryFullName) {
      fetchBranches(repositoryFullName, searchQuery);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery]);

  return (
    <>
      <Typography variant="body2" color="textSecondary" style={{ marginTop: 8 }}>
        Select one or two branches that we will be watching and reviewing the code on the PRs made to them.
      </Typography>
      
      {selectedBranches.length > 0 && (
        <Typography variant="body2" style={{ margin: "8px 0", display: "flex", alignItems: "center" }}>
          Production branch: 
          <Chip
            label={selectedBranches[0]}
            color="primary"
            size="small"
            style={{ marginLeft: 8, marginRight: 4 }}
          />
          <Tooltip title="The production branch is used for features like code error reports.">
            <InfoIcon fontSize="small" color="action" />
          </Tooltip>
        </Typography>
      )}

      <br />
      <FormControl fullWidth>
        <Autocomplete
          multiple
          id="branch-autocomplete"
          options={branches || []}
          value={selectedBranches}
          loading={loadingBranches}
          loadingText="Searching branches..."
          noOptionsText={
            selectedBranches.length === 2
              ? ""
              : searchQuery === ''
                ? "Type to search branches"
                : branches.length === 0 && !loadingBranches
                  ? "No branches found for the query"
                  : "No options"
          }
          onChange={(event, newValue) => {
            if (newValue.length <= 2) {
              onBranchesChange(newValue);
            }
          }}
          getOptionLabel={(option) => option}
          renderTags={(value: string[], getTagProps) =>
            value.map((option, index) => {
              const { key, ...chipProps } = getTagProps({ index });
              return (
                <Chip
                  key={key}
                  {...chipProps}
                  label={option}
                  color="primary"
                  size="small"
                />
              );
            })
          }
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label="Search branches"
              placeholder={
                selectedBranches.length === 0
                  ? "Search and select branches"
                  : selectedBranches.length === 1
                    ? "Search and select another branch"
                    : ""
              }
              disabled={disabled}
            />
          )}
          ListboxProps={{
            style: { maxHeight: 300, overflow: "auto" },
          }}
          PaperComponent={CustomPaper}
          disabled={disabled}
          onInputChange={(event, newValue) => {
            setSearchQuery(newValue);
          }}
          filterOptions={(options) => options}
        />
      </FormControl>
    </>
  );
};

export default BranchSelector; 