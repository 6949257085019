import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Typography,
  Button,
} from "@mui/material";

interface SecretModalProps {
  open: boolean;
  onClose: () => void;
  onComplete: (confirm: boolean) => void;
}

const SecretModal: React.FC<SecretModalProps> = ({
  open,
  onClose,
  onComplete,
}) => {
  // secretStep:
  // 1-3: correct password attempts,
  // 4: final confirmation,
  // 5: easter egg unlock,
  // 6: insistent mode unlock.
  const [secretStep, setSecretStep] = useState<number>(1);
  const [passwordInput, setPasswordInput] = useState<string>("");
  const [errorMsg, setErrorMsg] = useState<string>("");
  const [totalAttempts, setTotalAttempts] = useState<number>(0);
  const [correctCount, setCorrectCount] = useState<number>(0);

  // Expected password and easter egg phrase.
  const expectedPassword = "letmein";
  const easterEgg = "frogs eat 3301 cicadas";

  const resetState = () => {
    setSecretStep(1);
    setPasswordInput("");
    setErrorMsg("");
    setTotalAttempts(0);
    setCorrectCount(0);
  };

  const handlePasswordSubmit = () => {
    const trimmedInput = passwordInput.trim();
    const lowerInput = trimmedInput.toLowerCase();

    // Increase the total attempts count on every submit.
    const newTotal = totalAttempts + 1;
    setTotalAttempts(newTotal);

    // Check for the easter egg first.
    if (lowerInput === easterEgg) {
      setErrorMsg("");
      setSecretStep(5);
      return;
    }

    // Check for insistent mode: if they've tried 30 times, let them in.
    if (newTotal >= 30) {
      setErrorMsg("");
      setSecretStep(6);
      return;
    }

    // Check the expected password.
    if (trimmedInput === expectedPassword) {
      setErrorMsg("");
      const newCorrectCount = correctCount + 1;
      setCorrectCount(newCorrectCount);
      if (newCorrectCount >= 3) {
        // After 3 correct entries, move to final confirmation.
        setSecretStep(4);
      } else {
        // Advance the attempt step.
        setSecretStep(newCorrectCount + 1);
      }
      setPasswordInput("");
    } else {
      setErrorMsg("Incorrect password. Please try again.");
    }
  };

  const handleFinalConfirmation = (confirm: boolean) => {
    onComplete(confirm);
    resetState();
  };

  const handleAlternativeUnlock = () => {
    // Used for both the easter egg and insistent modes.
    onComplete(true);
    resetState();
  };

  const handleCancel = () => {
    onClose();
    resetState();
  };

  return (
    <Dialog open={open} onClose={handleCancel}>
      {secretStep < 4 && secretStep !== 5 && secretStep !== 6 && (
        <>
          <DialogTitle>
            Password Required (Attempt {secretStep} of 3)
          </DialogTitle>
          <DialogContent>
            <Typography variant="body2" sx={{ mb: 2 }}>
              Please enter the secret password to continue.
            </Typography>
            <TextField
              label="Password"
              type="password"
              fullWidth
              value={passwordInput}
              onChange={(e) => setPasswordInput(e.target.value)}
              error={!!errorMsg}
              helperText={errorMsg}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCancel} color="secondary">
              Cancel
            </Button>
            <Button onClick={handlePasswordSubmit} variant="contained">
              Submit
            </Button>
          </DialogActions>
        </>
      )}

      {secretStep === 4 && (
        <>
          <DialogTitle>Final Confirmation</DialogTitle>
          <DialogContent>
            <Typography variant="body2">
              You've earned it! Are you really sure you want to know the secret?
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => handleFinalConfirmation(false)}
              color="secondary"
            >
              No
            </Button>
            <Button
              onClick={() => handleFinalConfirmation(true)}
              variant="contained"
            >
              Yes
            </Button>
          </DialogActions>
        </>
      )}

      {secretStep === 5 && (
        <>
          <DialogTitle>Easter Egg Unlocked!</DialogTitle>
          <DialogContent>
            <Typography variant="body2">
              Wow, you typed the magic phrase <br />
              (I guess you're groovy enough to know the secret!) Enjoy!
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleAlternativeUnlock} variant="contained">
              Awesome!
            </Button>
          </DialogActions>
        </>
      )}

      {secretStep === 6 && (
        <>
          <DialogTitle>STOP CLICKING</DialogTitle>
          <DialogContent>
            <Typography variant="body2">
              Insistent, Aren't You? Ok, I'll let you in.
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleAlternativeUnlock} variant="contained">
              Thank you, mysterious overlord!
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

export default SecretModal;
