import * as Yup from "yup";

import {
  Alert,
  Box,
  CardContent,
  Grid,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext } from "react";
import { serverSignIn, serverSignUp } from "../../services/Blar/Auth";
import { useLocation, useNavigate } from "react-router-dom";

import { AxiosError } from "axios";
import { Context } from "@/contexts/ContextProvider";
import { LoadingButton } from "@mui/lab";
import { useFormik } from "formik";
import useStyles from "./styles/AuthForm";

interface AuthFormValues {
  firstName: string;
  lastName: string;
  companyName: string;
  email: string;
  password: string;
  authError?: string;
}

interface AuthFormProps {
  continueOnboarding?: () => void;
}

const AuthForm: React.FC<AuthFormProps> = ({
  continueOnboarding,
}: AuthFormProps) => {
  const classes = useStyles();
  const { signIn } = useContext(Context);
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;

  const formik = useFormik<AuthFormValues>({
    validateOnChange: true,
    validateOnBlur: false,
    initialValues: {
      firstName: "",
      lastName: "",
      companyName: "",
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      firstName: Yup.string().when("email", {
        is: () => currentPath.includes("/onboarding"),
        then: (schema) => schema.required("Name is required"),
        otherwise: (schema) => schema,
      }),
      lastName: Yup.string().when("email", {
        is: () => currentPath.includes("/onboarding"),
        then: (schema) => schema.required("Last name is required"),
        otherwise: (schema) => schema,
      }),
      companyName: Yup.string().when("email", {
        is: () => currentPath.includes("/onboarding"),
        then: (schema) => schema.required("Company name is required"),
        otherwise: (schema) => schema,
      }),
      email: Yup.string().email("Invalid email address").required("Required"),
      password: Yup.string()
        .min(6, "Password must be at least 6 characters")
        .matches(/[a-z]/, "Password must contain at least one lowercase letter")
        .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
        .matches(/\d/, "Password must contain at least one number")
        .required("Required"),
    }),
    onSubmit: async (values, { setSubmitting, setErrors }) => {
      setSubmitting(true);
      try {
        let authResponse;
        if (currentPath.includes("/onboarding")) {
          await serverSignUp({
            first_name: values.firstName,
            last_name: values.lastName,
            email: values.email,
            password: values.password,
            company_name: values.companyName,
          });
          authResponse = await serverSignIn(values.email, values.password);
          await signIn(authResponse.data.access, authResponse.data.refresh);
          if (continueOnboarding) continueOnboarding();
          return;
        }
        authResponse = await serverSignIn(values.email, values.password);
        await signIn(authResponse.data.access, authResponse.data.refresh);
        const redirectPath = new URLSearchParams(location.search).get(
          "redirect"
        );
        navigate(redirectPath || "/starter");
      } catch (error) {
        const axiosError = error as AxiosError;
        if (axiosError.response?.status === 400) {
          if (axiosError.response.data) {
            const errorData = axiosError.response.data as {
              [key: string]: string[];
            };
            const errors: string[] = [];
            for (const key of Object.keys(errorData)) {
              if (errorData.hasOwnProperty(key)) {
                errors.push(`${key}: ${errorData[key]}`);
              }
            }
            const errorString: string = errors.reduce(
              (acc, curr) => acc + curr + "\n",
              ""
            );
            setErrors({ authError: errorString });
          }
        } else {
          setErrors({
            authError:
              "The username or password you entered is incorrect. Please try again.",
          });
        }
      }
      setSubmitting(false);
    },
  });

  const is_onboarding = currentPath.includes("/onboarding");

  return (
    <Box
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
      height={"100%"}
      width={"100%"}
      data-testid="auth-form-container"
    >
      <Box display={"block"} mt={1} data-testid="auth-form-content">
        {!is_onboarding ? (
          <>
            <Box 
              component={"img"} 
              src="/logos/BlueLogo.svg" 
              alt="Blar" 
              data-testid="auth-form-logo"
            />
            <Typography
              variant="h4"
              align="center"
              fontSize={40}
              sx={{
                lineHeight: "40px",
                marginBottom: 2,
              }}
              data-testid="auth-form-welcome-text"
              >
              Welcome!
            </Typography>
            <Box
                sx={{ width: 300 }}
                display={"flex"}
                flexDirection={"column"}
                justifyContent={"center"}
                alignItems={"center"}
                component="form"
                onSubmit={formik.handleSubmit}
                noValidate
                data-testid="auth-login-form"
              >
                <Grid
                  container
                  rowSpacing={2}
                  direction="column"
                  className={classes.form}
                >
                  <Grid item sx={{ width: "80%" }}>
                    <TextField
                      fullWidth
                      InputProps={{
                        sx: {
                          borderRadius: "10px",
                        },
                      }}
                      placeholder="Email"
                      type="text"
                      id="email"
                      data-testid="auth-login-email"
                      {...formik.getFieldProps("email")}
                      error={formik.touched.email && Boolean(formik.errors.email)}
                      helperText={formik.errors.email}
                    />
                  </Grid>
                  <Grid item sx={{ width: "80%" }}>
                    <TextField
                      fullWidth
                      InputProps={{
                        sx: {
                          borderRadius: "10px",
                        },
                      }}
                      placeholder="Password"
                      type="password"
                      id="password"
                      data-testid="auth-login-password"
                      {...formik.getFieldProps("password")}
                      error={
                        formik.touched.password && Boolean(formik.errors.password)
                      }
                      helperText={
                        formik.touched.password && formik.errors.password
                      }
                    />
                  </Grid>
                  <Grid item>
                    {formik.errors.authError && (
                      <Alert severity="error">{formik.errors.authError}</Alert>
                    )}
                  </Grid>
                  <Grid
                    item
                    container
                    direction="column"
                    className={classes.actions}
                  >
                    <LoadingButton
                      loading={formik.isSubmitting}
                      type="submit"
                      variant="contained"
                      color="secondary"
                      size="large"
                      style={{ fontSize: "20px" }}
                      className={classes.authButton}
                      data-testid="auth-login-button"
                    >
                      Login
                    </LoadingButton>
                    <Link
                      href="/onboarding"
                      underline="hover"
                      color="secondary"
                      data-testid="auth-login-signup-link"
                    >
                      Don't have an account? Sign Up
                    </Link>
                    <br />
                    {/* <Link href="/login/playground" underline="hover" color="secondary" data-testid="auth-login-playground-link">
                      Want to try a playground? Click here
                    </Link> */}
                  </Grid>
                </Grid>
              </Box>
          </>
        ) : (
        <Box mt={1}>
          <CardContent data-testid="auth-form-card-content">
              <Box 
                display="flex" 
                flexDirection="row" 
                alignItems="center"
                justifyContent="space-between" 
                sx={{ mb: 3, width: "100%" }}
                data-testid="auth-signup-form"
              >
                <Box 
                  sx={{ 
                    display: "flex", 
                    flexDirection: "column", 
                    alignItems: "center",
                    mr: 8,
                    minWidth: "140px",
                    mb: 10
                  }}
                >
                  <Box 
                    component={"img"} 
                    src="/logos/BlueLogo.svg" 
                    alt="Blar"
                    sx={{ maxWidth: "120px", mb: 2 }}
                    data-testid="auth-signup-logo"
                  />
                  <Typography
                    variant="h4"
                    align="center"
                    fontSize={32}
                    sx={{
                      lineHeight: "32px",
                    }}
                    data-testid="auth-signup-welcome-text"
                  >
                    Welcome!
                  </Typography>
                </Box>
                <Box
                  sx={{ width: 300 }}
                  display={"flex"}
                  flexDirection={"column"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  component="form"
                  onSubmit={formik.handleSubmit}
                  noValidate
                >
                  <Grid
                    container
                    rowSpacing={2}
                    direction="column"
                    className={classes.form}
                  >
                    <>
                      <Grid item sx={{ width: "80%" }}>
                        <TextField
                          fullWidth
                          InputProps={{
                            sx: {
                              borderRadius: "10px",
                            },
                          }}
                          placeholder="Name"
                          type="text"
                          id="firstName"
                          data-testid="auth-signup-firstname"
                          {...formik.getFieldProps("firstName")}
                          error={
                            formik.touched.firstName &&
                            Boolean(formik.errors.firstName)
                          }
                          helperText={
                            formik.touched.firstName && formik.errors.firstName
                          }
                        />
                      </Grid>
                      <Grid item sx={{ width: "80%" }}>
                        <TextField
                          fullWidth
                          InputProps={{
                            sx: {
                              borderRadius: "10px",
                            },
                          }}
                          placeholder="Last Name"
                          type="text"
                          id="lastName"
                          data-testid="auth-signup-lastname"
                          {...formik.getFieldProps("lastName")}
                          error={
                            formik.touched.lastName &&
                            Boolean(formik.errors.lastName)
                          }
                          helperText={
                            formik.touched.lastName && formik.errors.lastName
                          }
                        />
                      </Grid>
                      <Grid item sx={{ width: "80%" }}>
                        <TextField
                          fullWidth
                          InputProps={{
                            sx: {
                              borderRadius: "10px",
                            },
                          }}
                          placeholder="Company Name"
                          type="text"
                          id="companyName"
                          data-testid="auth-signup-company"
                          {...formik.getFieldProps("companyName")}
                          error={
                            formik.touched.companyName &&
                            Boolean(formik.errors.companyName)
                          }
                          helperText={
                            formik.touched.companyName &&
                            formik.errors.companyName
                          }
                        />
                      </Grid>
                    </>
                    <Grid item sx={{ width: "80%" }}>
                      <TextField
                        fullWidth
                        InputProps={{
                          sx: {
                            borderRadius: "10px",
                          },
                        }}
                        placeholder="Email"
                        type="text"
                        id="email"
                        data-testid="auth-signup-email"
                        {...formik.getFieldProps("email")}
                        error={formik.touched.email && Boolean(formik.errors.email)}
                        helperText={formik.errors.email}
                      />
                    </Grid>
                    <Grid item sx={{ width: "80%" }}>
                      <TextField
                        fullWidth
                        InputProps={{
                          sx: {
                            borderRadius: "10px",
                          },
                        }}
                        placeholder="Password"
                        type="password"
                        id="password"
                        data-testid="auth-signup-password"
                        {...formik.getFieldProps("password")}
                        error={
                          formik.touched.password && Boolean(formik.errors.password)
                        }
                        helperText={
                          formik.touched.password && formik.errors.password
                        }
                      />
                    </Grid>
                    <Grid item>
                      {formik.errors.authError && (
                        <Alert severity="error">{formik.errors.authError}</Alert>
                      )}
                    </Grid>
                    <Grid
                      item
                      container
                      direction="column"
                      className={classes.actions}
                    >
                      <LoadingButton
                        loading={formik.isSubmitting}
                        type="submit"
                        variant="contained"
                        color="secondary"
                        size="large"
                        style={{ fontSize: "20px" }}
                        className={classes.authButton}
                        data-testid="auth-signup-button"
                      >
                        Sign Up
                      </LoadingButton>
                      <Link href="/login" underline="hover" color="secondary" data-testid="auth-signup-login-link">
                        Already have an account? Login
                      </Link>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
          </CardContent>
        </Box>
        )}
      </Box>
    </Box>
  );
};

export default AuthForm;
