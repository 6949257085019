import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    padding: 20,
  },
  loggerToolBox: {
    display: "flex",
    maxWidth: "550px",
    textAlign: "start",
  },
  repositorySelectContainer: {
    display: "flex",
    flexDirection: "column",
    textAlign: "left",
    alignItems: "start",
    width: "100%",
    maxWidth: "450px",
    marginTop: theme.spacing(4),
  },
  buttonContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    bottom: 10,
    marginTop: theme.spacing(4),
    '& > :first-child': {
      marginLeft: 0
    },
    '& > :last-child': {
      marginRight: 0
    }
  }
}));

export default useStyles;
