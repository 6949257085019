import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  root: {
    height: "100vh",
    width: "100vw",
    display: "flex",
  },
  leftSection: {
    color: "white",
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
    alignItems: "center",
    padding: "1rem",
    paddingRight: "3rem",
  },
  logo: {
    height: "4%",
    width: "100%",
    display: "inline-flex",
    alignSelf: "flex-start",
    gap: "1rem",
  },
  slogan: {
    height: "35%",
    width: "100%",
    paddingLeft: "10%",
  },
  stepsBox: {
    height: "61%",
    width: "100%",
    display: "flex",
    justifyContent: "end",
    paddingTop: "60px",
  },
  rightSection: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    width: "100%",
  },
  formField: {
    marginBottom: "1rem",
  },
});

export default useStyles;
