import { PaginationParams } from "@/interfaces/IApi";
import { ListAgentFilters } from "@/interfaces/IGraphVideo";
import { blarClient } from "./Client";
import { ListChatRoomFilters } from "@/interfaces/IChatRoom";
import { Agent } from "@/pages/Chat/components/types";
import { ListManualAgentFilters } from "@/pages/ManualAgents/types";

export const list_agent_solutions = async (
  paginationParams?: PaginationParams,
  filters?: ListAgentFilters
) => {
  const params = { ...paginationParams, ...filters };
  return blarClient.request("get", "/agents/agent-solution-series/list", null, {
    ...params,
  });
};

export const get_video_demo = async (agent_solution_series_id: number) => {
  const url = `/agents/agent-solution-series/video-chain?agent_solution_series_id=${agent_solution_series_id}`;

  return blarClient.request("get", url);
};

export const list_manual_agent_solutions = async (
  paginationParams?: PaginationParams
) => {
  const params = { ...paginationParams };
  return blarClient.request(
    "get",
    "/agents/agent-solution-series/list-manual",
    null,
    {
      ...params,
    }
  );
};

export const list_chat_rooms = async (
  paginationParams?: PaginationParams,
  filters?: ListChatRoomFilters
) => {
  const params = { ...paginationParams, ...filters };
  return blarClient.request("get", "/agents/chats", null, {
    ...params,
  });
};

export const execute_manual_agent = async (
  agentType: Agent,
  nodeId: string,
  path: string,
  comments: string,
  repoId: string
) => {
  return blarClient.request("post", "/agents/manual-agent/", {
    agent_type: agentType,
    node_id: nodeId,
    path,
    comments,
    repo_id: repoId,
  });
};

export const list_manual_agent = async (
  paginationParams?: PaginationParams,
  filters?: ListManualAgentFilters
) => {
  const params = { ...paginationParams, ...filters };
  return blarClient.request("get", "/agents/list-manual-agent/", null, params);
};

export const getGraphData = async (reportId: string): Promise<any> => {
  const url = `agents/manual-reports/${reportId}/graph/`;
  return blarClient.request("get", url);
};
