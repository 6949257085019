import React, { useState } from 'react';
import { Drawer, Box, Typography, IconButton, useTheme, Tabs, Tab } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ReactMarkdown from 'react-markdown';
import TracebackViewer from '@/components/TracebackViewer/TracebackViewer';
import CodeBlock from '@/components/CodeBlock/CodeBlock';

interface AiReportDrawerProps {
  aiResponse: { 
    report: string; 
    solution: string; 
    test: string | null;
    trace: { 
      file: string;
      module: string;
      line_number: number;
      pre_context: string[] | null;
      code_context: string | null;
      post_context: string[] | null;
      date?: string;
      author?: string;
      commit_message?: string;
    }[] | null | [];
  } | null;
  onClose: () => void;
}

interface CustomTabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: CustomTabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`ai-report-tabpanel-${index}`}
      aria-labelledby={`ai-report-tab-${index}`}
      {...other}
      sx={{
        height: 'calc(100% - 48px)',
        overflow: 'auto',
        p: 2
      }}
    >
      {value === index && children}
    </Box>
  );
}

interface MarkdownTabPanelProps {
  value: number;
  index: number;
  text: string;
  borderColor: string;
  backgroundColor: string;
}

const MarkdownTabPanel = ({ value, index, text, borderColor, backgroundColor }: MarkdownTabPanelProps) => (
  <TabPanel value={value} index={index}>
    <Box
      sx={{
        p: 2,
        border: `1px solid ${borderColor}`,
        borderRadius: 1,
        backgroundColor: backgroundColor,
        height: '100%',
        overflow: 'auto'
      }}
    >
      <ReactMarkdown components={{
        p: ({ children }) => <Typography variant="body1">{children}</Typography>,
        code(props) {
          const { children, className, ...rest } = props;
          const match = /language-(\w+)/.exec(className || "");
          return match ? (
            <CodeBlock language={match[1]}>
              {String(children)}
            </CodeBlock>
          ) : (
            <code
              {...rest}
              className={className}
              style={{
                textOverflow: "ellipsis",
                wordBreak: "break-word",
                whiteSpace: "pre-wrap",
              }}
            >
              {children}
            </code>
          );
        },
      }}>
        {text}
      </ReactMarkdown>
    </Box>
  </TabPanel>
);

const AiReportDrawer: React.FC<AiReportDrawerProps> = ({ aiResponse, onClose }) => {
  const theme = useTheme();
  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  return (
    <Drawer
      anchor="right"
      open={Boolean(aiResponse)}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: {
            xs: '100%',
            sm: '60%'
          },
          minWidth: 300,
          borderLeft: '1px solid #FFFFFF4D',
          '& .MuiBackdrop-root': {
            backgroundColor: 'transparent',
          },
          backgroundColor: theme.palette.background.default
        }
      }}
    >
      {aiResponse && (
        <Box display="flex" flexDirection="column" height="100%" overflow="hidden">
          <Box p={2} flexShrink={0}>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
              <Typography variant="h5" fontWeight={500} fontFamily="Ubuntu Sans Mono">
                Root Cause Analysis
              </Typography>
              <IconButton onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>

          <Box sx={{ borderBottom: 1, borderColor: 'divider', flexShrink: 0 }}>
            <Tabs value={tabValue} onChange={handleTabChange} aria-label="AI report tabs">
              <Tab label="Report" />
              <Tab label="Solution" />
              {aiResponse.test && <Tab label="Test" />}
              {aiResponse.trace && aiResponse.trace.length > 0 && <Tab label="Traceback" />}
            </Tabs>
          </Box>

          <MarkdownTabPanel 
            value={tabValue} 
            index={0}
            text={aiResponse.report}
            borderColor="#ff5252"
            backgroundColor="rgba(255, 82, 82, 0.1)"
          />

          <MarkdownTabPanel 
            value={tabValue} 
            index={1}
            text={aiResponse.solution}
            borderColor="#4caf50"
            backgroundColor="rgba(76, 175, 80, 0.1)"
          />

          {aiResponse.test && (
            <MarkdownTabPanel 
              value={tabValue} 
              index={2}
              text={aiResponse.test}
              borderColor="#0055ED"
              backgroundColor="rgba(0, 85, 237, 0.1)"
            />
          )}

          {aiResponse.trace && aiResponse.trace.length > 0 && (
            <TabPanel value={tabValue} index={aiResponse.test ? 3 : 2}>
              <TracebackViewer frames={aiResponse.trace as any} />
            </TabPanel>
          )}
        </Box>
      )}
    </Drawer>
  );
};

export default AiReportDrawer; 