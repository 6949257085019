import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    padding: 20,
  },
  connectButton: {
    marginBottom: "10%",
    marginLeft: "30px",
  },
  loggerToolBox: {
    marginLeft: "30px",
    marginBottom: "20px",
    display: "flex",
    flexDirection: "column",
    width: "450px",
    textAlign: "start",
  },
  repositorySelectContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    width: "100%",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "237px",
  },
  integrationSection: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    marginTop: "24px",
    marginBottom: "24px",
    gap: 24,
  },
  integrationCard: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "10rem",
    height: "10rem",
    "&:hover": {
      transform: "scale(1.05)",
    }
  },
  successBorder: {
    border: "2px solid #4caf50",
    borderRadius: "10px",
  },
  buttonContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: theme.spacing(4),
    '& > :first-child': {
      marginLeft: 0
    },
    '& > :last-child': {
      marginRight: 0
    }
  }
}));

export default useStyles;
