import React, { useState } from "react";
import { Box, Typography, Paper, useTheme, IconButton } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useWiki } from "../context/WikiContext";
import { createWikiFromCursorRules } from "@/services/Blar/Wiki";
import { FileNode } from "@/pages/Wiki/types";
import { useWikiFileStructure } from "../context/WikiFileStructureContext";

interface CursorRulesImportContentProps {
  onBack: () => void;
  repoFolderName?: string;
}

const CursorRulesImportContent: React.FC<CursorRulesImportContentProps> = ({ 
  onBack,
  repoFolderName
}) => {
  const theme = useTheme();
  const { selectedNode, setSelectedNode, setInputFileContent, setOutputFileContent } = useWiki();
  const { setStructure, addNodeToStructure } = useWikiFileStructure();
  const [selectedMdcFile, setSelectedMdcFile] = useState<File | null>(null);
  const [uploadingFile, setUploadingFile] = useState(false);
  const [uploadingDirectory, setUploadingDirectory] = useState(false);

  const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setSelectedMdcFile(file);
    }
  };

  const handleDirectorySelect = async () => {
    try {
      setUploadingDirectory(true);
      // @ts-ignore - TypeScript doesn't have types for experimental API
      const directoryHandle = await window.showDirectoryPicker();
      const cursorDir = await directoryHandle.getDirectoryHandle('.cursor', { create: false });
      const rulesDir = await cursorDir.getDirectoryHandle('rules', { create: false });
      
      for await (const entry of rulesDir.values()) {
        if (entry.kind === 'file' && entry.name.endsWith('.mdc')) {
          const file = await entry.getFile();
          setSelectedMdcFile(file);
          break;
        }
      }
    } catch (error) {
      console.error('Error accessing directory:', error);
      alert('Could not find .cursor/rules directory with MDC files');
    } finally {
      setUploadingDirectory(false);
    }
  };

  const handleCreateFromUpload = async () => {
    if (!selectedMdcFile || !selectedNode) return;
    
    try {
      setUploadingFile(true);
      const createdFile = await createWikiFromCursorRules(
        selectedMdcFile,
        "",  // repo_id parameter (not needed in this context)
        selectedNode.id.toString() // folder parameter
      );
      
      const newFileNode: FileNode = {
        id: createdFile.id,
        name: createdFile.name,
        type: 'file' as const,
        parent: createdFile.folder,
        path: createdFile.path,
        content: createdFile.content
      };
      
      setStructure(prev => addNodeToStructure(prev, selectedNode.id, newFileNode));
      setSelectedNode(newFileNode);
      setInputFileContent(createdFile.content);
      setOutputFileContent(createdFile.content);
      setSelectedMdcFile(null);
      
      // Go back to the main view after successful creation
      onBack();
    } catch (error) {
      console.error('Error creating from MDC file:', error);
    } finally {
      setUploadingFile(false);
    }
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="center" height="100%" p={4}>
      <Paper
        sx={{
          borderColor: theme.palette.divider,
          width: '100%',
          maxWidth: '800px',
          borderRadius: '10px',
          padding: 3,
          boxShadow: theme.shadows[3]
        }}
      >
        <Box sx={{ mb: 4, textAlign: 'left' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
            <IconButton 
              onClick={onBack} 
              sx={{ mr: 1, p: 0.5 }}
              aria-label="Go back"
            >
              <ArrowBackIcon />
            </IconButton>
            <Typography variant="h5">
              Import from Cursor rules {repoFolderName ? `for ${repoFolderName}` : ''}
            </Typography>
          </Box>
          
          <Typography variant="body1" color="text.secondary" sx={{ mb: 3 }}>
            We can create a wiki from your Cursor rules. Select your repository directory in the file explorer to automatically find .mdc files in .cursor/rules
          </Typography>
        </Box>

        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mb: 4 }}>
          <Box sx={{ display: 'flex', justifyContent: 'center', mb: 3 }}>
            <LoadingButton
              variant="outlined"
              color="secondary"
              onClick={handleDirectorySelect}
              loading={uploadingDirectory}
              sx={{ mr: 2 }}
            >
              Select a directory
            </LoadingButton>

            <input
              type="file"
              accept=".mdc"
              onChange={handleFileSelect}
              style={{ display: 'none' }}
              id="mdc-file-input"
            />
            <label htmlFor="mdc-file-input">
              <LoadingButton
                component="span"
                variant="outlined"
                color="secondary"
                loading={uploadingFile}
              >
                {selectedMdcFile ? selectedMdcFile.name : 'Select .mdc file manually'}
              </LoadingButton>
            </label>
          </Box>

          {selectedMdcFile && (
            <Box sx={{ 
              textAlign: 'center', 
              p: 3, 
              bgcolor: theme.palette.background.paper, 
              borderRadius: '8px', 
              width: '100%',
              border: `1px solid ${theme.palette.secondary.main}`
            }}>
              <Typography variant="body2" sx={{ mb: 2 }}>
                Selected file: <strong>{selectedMdcFile.name}</strong>
              </Typography>
              <LoadingButton
                variant="contained"
                color="secondary"
                onClick={handleCreateFromUpload}
                loading={uploadingFile}
                size="large"
              >
                Create wiki from {selectedMdcFile.name}
              </LoadingButton>
            </Box>
          )}
        </Box>
      </Paper>
    </Box>
  );
};

export default CursorRulesImportContent; 