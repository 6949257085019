import { Tooltip, Typography, useTheme, Box, Stack, Paper } from "@mui/material";
import { iNotification, useNotifications } from "@/contexts/NotificationContextProvider";
import { useCallback, useContext, useEffect, useState } from "react";
import { Context } from "@/contexts/ContextProvider";
import ErrorIcon from "@mui/icons-material/Error";
import DescriptionIcon from "@mui/icons-material/Description";
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WarningIcon from '@mui/icons-material/Warning';

type GetPRReviewNotificationProps = {
  repoId: string;
  inProgressReviewPRNumbers: number[];
};

interface NotificationData {
  body: string;
  pr_number: string;
  repo_id: string;
  status: "Not started" | "reviewing" | "summary" | "issue" | "finished";
  timestamp: Date;
}

const GetPRReviewNotification = ({ repoId, inProgressReviewPRNumbers }: GetPRReviewNotificationProps) => {
  const theme = useTheme();
  const { showMessage } = useContext(Context);
  const [statusHistory, setStatusHistory] = useState<NotificationData[]>([]);
  const [currentPRNumber, setCurrentPRNumber] = useState<string>("");
  const { subscribeToNotifications, unsubscribeFromNotifications } = useNotifications();

  useEffect(() => {
    if (inProgressReviewPRNumbers.length === 0 && statusHistory.length === 0) {
      setStatusHistory([{
        body: "Select a PR if you want to review it",
        pr_number: "",
        repo_id: repoId,
        status: "Not started",
        timestamp: new Date()
      }]);
    } else if (inProgressReviewPRNumbers.length > 0 && 
              (statusHistory.length === 0 || 
               statusHistory[statusHistory.length - 1].status === "Not started")) {
      setCurrentPRNumber(inProgressReviewPRNumbers[0].toString());
      setStatusHistory(prev => [...prev, {
        body: `Starting review of PR `,
        pr_number: inProgressReviewPRNumbers[0].toString(),
        repo_id: repoId,
        status: "reviewing",
        timestamp: new Date()
      }]);
    }
  }, [inProgressReviewPRNumbers, repoId, statusHistory]);

  const handleNotification = useCallback((notification: iNotification) => {
    if (!notification.data || notification.data.repo_id !== repoId) {
      return;
    }

    const prNumber = notification.data.pr_number || "";
    setCurrentPRNumber(prNumber);

    if (notification.data.action === "pr_summary_created") {
      console.log("notification", notification);
      showMessage("info", notification.body || "Summary updated");
      
      setStatusHistory(prev => [...prev, {
        body: notification.body || "Summary available",
        pr_number: prNumber,
        repo_id: notification.data.repo_id || "",
        status: "summary",
        timestamp: new Date()
      }]);
    } if (notification.data.action === "pr_review_issue_found") {
      showMessage("warning", notification.body || "New issue reported");
      
      setStatusHistory(prev => [...prev, {
        body: notification.body || "New issue reported",
        pr_number: prNumber,
        repo_id: notification.data.repo_id || "",
        status: "issue",
        timestamp: new Date()
      }]);
    } else if (notification.data.action === "pr_review_finished") {
      showMessage("success", notification.body || "PR review finished");
      
    setStatusHistory(prev => [...prev, {
      body: notification.body || "PR review finished",
      pr_number: prNumber,
      repo_id: notification.data.repo_id || "",
      status: "finished",
      timestamp: new Date()
    }]);
  }
}, [showMessage, repoId]);

  useEffect(() => {
    subscribeToNotifications(handleNotification);
    return () => {
      unsubscribeFromNotifications(handleNotification);
    };
  }, [handleNotification, subscribeToNotifications, unsubscribeFromNotifications]);

  const getStatusIcon = (status: string) => {
    switch (status) {
      case "Not started":
        return <HourglassEmptyIcon color="disabled" />;
      case "reviewing":
        return <HourglassEmptyIcon color="primary" />;
      case "summary":
        return <DescriptionIcon color="info" />;
      case "issue":
        return <WarningIcon color="warning" />;
      case "finished":
        return <CheckCircleIcon color="success" />;
      default:
        return null;
    }
  };

  return (
    <Box sx={{ 
      width: "100%",
      maxHeight: "100px",
      overflow: "auto",
      padding: 2,
      borderRadius: "6px",
      backgroundColor: "transparent",
      border: `1px solid ${theme.palette.divider}`,
    }}>
      <Typography variant="h6" gutterBottom>
        PR Review Status
      </Typography>
      <Stack spacing={1}>
        {[...statusHistory].reverse().map((status, index) => (
          <Paper 
            key={index}
            elevation={0}
            sx={{ 
              p: 1, 
              display: "flex", 
              alignItems: "center", 
              gap: 1,
              backgroundColor: "transparent",
              maxWidth: "100%",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap"
            }}
          >
            {getStatusIcon(status.status)}
            <Box sx={{ overflow: "hidden", flexGrow: 1 }}>
              <Typography variant="body1" noWrap>
                {status.body}
                {status.pr_number && ` (#${status.pr_number})`}
              </Typography>
            </Box>
            <Typography variant="caption" color="text.secondary" sx={{ minWidth: 'fit-content' }}>
              {status.timestamp.toLocaleTimeString()}
            </Typography>
          </Paper>
        ))}
      </Stack>
    </Box>
  );
};

export default GetPRReviewNotification;
