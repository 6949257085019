import { blarClient } from "./Client";
import { AxiosResponse } from "axios";
import { ExternalProfile } from "@/interfaces/ICompany";

interface UpdateExternalsProfilesOutput {
  results: ExternalProfile[];
}

export const update_externals_profiles = async (): Promise<AxiosResponse<UpdateExternalsProfilesOutput>> => {
  const response = await blarClient.request(
    "post",
    "/integrations/git/members/update"
  );
  return response;
}
interface selectSeatParams {
  select_all?: boolean;
  unselect_all?: boolean;
  seat?: string;
}

export const select_seat = async (params: selectSeatParams) => {
  const response = await blarClient.request(
    "post",
    "/integrations/git/members/select_seat",
    params
  );
  return response
}

export const count_selected_seats = async () => {
  try {
    const response = await blarClient.request(
      "get",
      "/integrations/git/members/select_seat/count"
    );
    return response;
  } catch (error) {
    console.error("Error counting selected seats:", error);
    throw error;
  }
}

interface GetSelectedSeatsOutput {
  results: ExternalProfile[];
}

export const get_selected_seats = async (): Promise<AxiosResponse<GetSelectedSeatsOutput>> => {
  try {
    const response = await blarClient.request(
      "get",
      "/integrations/git/members/select_seat"
    );
    return response;
  } catch (error) {
    console.error("Error fetching selected seats:", error);
    throw error;
  }
}

