import React, { useState, useEffect, useContext } from "react";
import { Box, Typography, CircularProgress, Button, Chip, AlertColor, Modal } from "@mui/material";
import { Context } from "@/contexts/ContextProvider";
import { NotificationsContextProvider } from "@/contexts/NotificationContextProvider";
import ImportCursorRules from "@/pages/Auth/Onboarding/components/guidelines/ImportCursorRules";
import LoadingTypography from "./components/LoadingTypography";
import { list_repos } from "@/services/Blar/Repo_graph";
import { createWikiFromDocs, getWikiRules, createWikiFromCursorRules } from "@/services/Blar/Wiki";
import ContinueButton from "@/components/Buttons/ContinueButton";
import BackButton from "@/components/Buttons/BackButton";
import PastCommentsAnalysis from "./components/guidelines/PastCommentsAnalysis";
import YesNoQuestion from "@/pages/Auth/Onboarding/components/guidelines/YesNoQuestion";
import ImportModal from "@/pages/Auth/Onboarding/components/guidelines/ImportModal";
import { Repo } from "@/pages/Settings/components/Repos/types";
import ActionCard from "@/pages/Auth/Onboarding/components/guidelines/ActionCard";
import ReactMarkdown from 'react-markdown';
import CodeBlock from '@/components/CodeBlock/CodeBlock';
import { WikiOnboardingSource } from "./contexts/OnboardingContext";
import { useOnboarding } from "./contexts/OnboardingContext";
import useStyles from "./styles/Guidelines";
interface RepoPaginatedResponse {
  results: Repo[];
  count: number;
}



interface WriteGuidelinesProps {
  continueOnboarding: () => void;
  backOnboarding: () => void;
}

const CREATED_GUIDELINES = {
  [WikiOnboardingSource.PR_ANALYSIS]: "We reviewed your pull requests last comments and created the following coding guidelines.",
  [WikiOnboardingSource.CURSOR_RULES]: "We imported your Cursor rules.",
  [WikiOnboardingSource.MANUAL_GUIDELINES]: "You added manually your coding guidelines.",
}

const WriteGuidelines: React.FC<WriteGuidelinesProps> = ({ continueOnboarding, backOnboarding }): JSX.Element => {
  const [selectedRepo, setSelectedRepo] = useState<Repo | null>(null);
  const [guidelineCreated, setGuidelineCreated] = useState(false);
  const [guidelineCreatedContent, setGuidelineCreatedContent] = useState<string | null>(null);
  const [hasGuidelines, setHasGuidelines] = useState<boolean | null>(null);
  const [usesCursor, setUsesCursor] = useState<boolean | null>(null);
  const [guidelinesText, setGuidelinesText] = useState("");
  const [showCursorQuestion, setShowCursorQuestion] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingImport, setLoadingImport] = useState(false);
  const [openCursorModal, setOpenCursorModal] = useState(false);
  const [loadingCursor, setLoadingCursor] = useState(false);
  const [loadingPRAnalysis, setLoadingPRAnalysis] = useState(true);
  const [successPRAnalysis, setSuccessPRAnalysis] = useState<boolean | null>(null);
  const [selectedCursorFile, setSelectedCursorFile] = useState<File | null>(null);
  const { showMessage, access, refresh } = useContext(Context);
  const classes = useStyles();
  const [openMarkdownModal, setOpenMarkdownModal] = useState(false);
  const { wikiOnboardingSource, setWikiOnboardingSource } = useOnboarding();
  
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);
  const handleOpenCursorModal = () => setOpenCursorModal(true);
  const handleCloseCursorModal = () => setOpenCursorModal(false);
  const handleOpenMarkdownModal = () => setOpenMarkdownModal(true);
  const handleCloseMarkdownModal = () => setOpenMarkdownModal(false);

  const handleSubmitCursorRules = async () => {
    if (!selectedCursorFile) {
      showMessage("error", "Please select a .mdc file first");
      return;
    }
    
    if (!selectedRepo) {
      showMessage("error", "Please select a repository first");
      return;
    }
    
    setLoadingCursor(true);
    handleCloseCursorModal();
    
    const wiki = await createWikiFromCursorRules(
      selectedCursorFile, 
      selectedRepo.repo_id,
      ""
    )

    if (wiki) {
      setGuidelineCreated(true);
      setGuidelineCreatedContent(wiki.content);
      setWikiOnboardingSource(WikiOnboardingSource.CURSOR_RULES);
      setHasGuidelines(true);
      setUsesCursor(true);
    }

    setLoadingCursor(false);
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const reposResponse = await list_repos();
      const repoData = reposResponse.data as RepoPaginatedResponse;
      
      // Set default repository, in onboarding we only have one repo
      const selected = repoData.results[0];
      setSelectedRepo(selected);

      // Check for existing wiki rules
      try {
        const wikiRules = await getWikiRules(selected.repo_id);
        if (wikiRules && typeof wikiRules === 'object' && wikiRules.data !== "") {
          setGuidelineCreated(true);
          const ruleContent = wikiRules.data;
          setGuidelineCreatedContent(ruleContent);
          if (wikiOnboardingSource === null) {
            setWikiOnboardingSource(WikiOnboardingSource.PR_ANALYSIS);
          }
          return;
        }
      } catch (error) {
        console.error("Error fetching wiki rules:", error);
      }

      // PR analysis fallback if no rules found, in this way we render a component who listen for notifications.
      setTimeout(() => {
        if (successPRAnalysis === null) {
          setLoadingPRAnalysis(false);
          setSuccessPRAnalysis(false);
        }
      }, 15000);

    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (successPRAnalysis === true) {
      fetchData();
    }
  }, [successPRAnalysis]);

  const handleImportWiki = async () => {
    setLoadingImport(true);
    handleCloseModal();
    const wiki = await createWikiFromDocs(guidelinesText, selectedRepo!.repo_id);
    if (wiki) {
      setGuidelineCreated(true);
      setGuidelineCreatedContent(wiki.content);
      setWikiOnboardingSource(WikiOnboardingSource.MANUAL_GUIDELINES);
    }
    setLoadingImport(false);
  }

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100%">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <NotificationsContextProvider access={access} refresh={refresh}>
      <Box className={classes.root}>
        <Box className={classes.headerSection}>
          <LoadingTypography
            text="Define your coding standards"
            loading={loading}
            stepCompleted={guidelineCreated}
          />
          <Typography variant="body1" color="text.secondary" className={classes.subtitle}>
            Let's set the foundation for high-quality code! We'll begin reviewing pull requests in {' '}
            {selectedRepo && (
              <Chip 
              label={selectedRepo.name.split('/')[1]}
              size="small"
              color="primary"
              />
            )} ensuring they adhere to your coding standards with automated feedback. Let's add some rules!
          </Typography>
        </Box>
            <Box className={classes.headerSection}>

              {!guidelineCreated ? (
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                <PastCommentsAnalysis 
                  loadingPRAnalysis={loadingPRAnalysis}
                  successPRAnalysis={successPRAnalysis}
                  setSuccessPRAnalysis={setSuccessPRAnalysis}
                  setLoadingPRAnalysis={setLoadingPRAnalysis}
                  repoId={selectedRepo!.repo_id}
                />

                {!loadingPRAnalysis && successPRAnalysis === false && hasGuidelines === null && (
                  <YesNoQuestion
                    question="You can also add manually your coding standards. Do you have them documented?"
                    onYes={() => {
                      setHasGuidelines(true);
                      handleOpenModal();
                    }}
                    onNo={() => {
                      setHasGuidelines(false);
                      setShowCursorQuestion(true);
                    }}
                  />
                )}

                {hasGuidelines === true && (
                  <ActionCard
                  message="Add your guidelines from your current documentation"
                  loading={loadingImport}
                  buttonText="Add Rules"
                  onButtonClick={handleOpenModal}
                  />
                )}

                {hasGuidelines === false && showCursorQuestion && (
                  <YesNoQuestion
                    question="Did you set any Cursor rules?"
                    onYes={() => {
                      setUsesCursor(true);
                      setShowCursorQuestion(false);
                      handleOpenCursorModal();
                    }}
                    onNo={() => {
                      setUsesCursor(false);
                      setShowCursorQuestion(false);
                    }}
                  />
                )}

                {usesCursor === true && (
                  <ActionCard
                    message="Add your Cursor rules to convert them into guidelines"
                    loading={loadingCursor}
                    buttonText="Add Rules"
                    onButtonClick={handleOpenCursorModal}
                  />
                )}

                {usesCursor === false && (
                  <Box 
                  sx={{ 
                    display: 'flex', 
                    alignItems: 'center', 
                    backgroundColor: '#161B22',
                    padding: 2,
                    borderRadius: '8px', 
                    border: '1px solid #30363d',
                    marginTop: 1
                  }}
                  >
                    <Typography variant="body1" color="text.primary">
                      You can continue we will follow analyzing your PR comments to get some guidelines. Also you can add your guidelines later in the Wiki section.
                    </Typography>
                  </Box>
                )}
              </Box>
              ) : (
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, border: '1px solid #30363d', padding: 2, borderRadius: '8px' }}>
                  <Typography variant="body1" color="text.primary">
                    {CREATED_GUIDELINES[wikiOnboardingSource!]}
                  </Typography>
                  <Box sx={{ 
                    background: 'transparent', 
                    borderRadius: '6px', 
                    border: '1px solid #21262D', 
                    padding: 2,
                    height: 'auto',
                    maxHeight: '200px',
                    width: '100%',
                    overflow: 'hidden',
                    position: 'relative'
                  }}>
                    {guidelineCreatedContent && (
                      <ReactMarkdown
                        components={{
                          code(props) {
                            const { children, className, ...rest } = props;
                            const match = /language-(\w+)/.exec(className || "");
                            return match ? (
                              <CodeBlock language={match[1]}>
                                {String(children)}
                              </CodeBlock>
                            ) : (
                              <code
                                {...rest}
                                className={className}
                                style={{
                                  textOverflow: "ellipsis",
                                  wordBreak: "break-word",
                                  whiteSpace: "pre-wrap",
                                  background: 'rgba(0, 0, 0, 0.2)',
                                  padding: '2px 4px',
                                  borderRadius: '3px'
                                }}
                              >
                                {children}
                              </code>
                            );
                          },
                        }}
                      >
                        {extractFirstRule(guidelineCreatedContent)}
                      </ReactMarkdown>
                    )}
                    <Box sx={{
                      position: 'absolute',
                      bottom: 0,
                      left: 0,
                      right: 0,
                      height: '50px',
                      background: 'linear-gradient(to bottom, transparent, #161B22)',
                      pointerEvents: 'none'
                    }} />
                  </Box>
                  <Box sx={{ display: 'flex', justifyContent: 'center', mt: 1 }}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleOpenMarkdownModal}
                    >
                      See more
                    </Button>
                  </Box>
                </Box>
              )}

              <Modal
                open={openMarkdownModal}
                onClose={handleCloseMarkdownModal}
                aria-labelledby="markdown-modal-title"
              >
                <Box sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  width: '80%',
                  maxWidth: '800px',
                  bgcolor: '#161B22',
                  border: '1px solid #30363d',
                  borderRadius: '8px',
                  boxShadow: 24,
                  p: 4,
                  maxHeight: '80vh',
                  overflow: 'auto'
                }}>
                  <Typography id="markdown-modal-title" variant="h6" component="h2" sx={{ mb: 2 }}>
                    Coding Guidelines
                  </Typography>
                  {guidelineCreatedContent && (
                    <ReactMarkdown
                      components={{
                        code(props) {
                          const { children, className, ...rest } = props;
                          const match = /language-(\w+)/.exec(className || "");
                          return match ? (
                            <CodeBlock language={match[1]}>
                              {String(children)}
                            </CodeBlock>
                          ) : (
                            <code
                              {...rest}
                              className={className}
                              style={{
                                textOverflow: "ellipsis",
                                wordBreak: "break-word",
                                whiteSpace: "pre-wrap",
                                background: 'rgba(0, 0, 0, 0.2)',
                                padding: '2px 4px',
                                borderRadius: '3px'
                              }}
                            >
                              {children}
                            </code>
                          );
                        },
                      }}
                    >
                      {guidelineCreatedContent}
                    </ReactMarkdown>
                  )}
                  <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                    <Button onClick={handleCloseMarkdownModal} variant="contained">
                      Close
                    </Button>
                  </Box>
                </Box>
              </Modal>

              <ImportModal
                open={openModal}
                onClose={handleCloseModal}
                guidelinesText={guidelinesText}
                setGuidelinesText={setGuidelinesText}
                loadingImport={loadingImport}
                selectedRepo={selectedRepo}
                onSubmit={() => {
                  handleImportWiki();
                }}
              />

              <ImportCursorRules
                open={openCursorModal}
                onClose={handleCloseCursorModal}
                loadingCursor={loadingCursor}
                onSubmit={handleSubmitCursorRules}
                onFileSelect={setSelectedCursorFile}
              />
            </Box>
        <Box className={classes.buttonContainer}>
          <BackButton backOnboarding={backOnboarding} />
          <Box className={classes.skipButton}>
            <Button 
              sx={{ fontWeight: 'light', fontSize: '20px', margin: 0, "&:hover": { backgroundColor: 'transparent', textDecoration: 'underline' } }}
              onClick={continueOnboarding}
            >
              Skip
            </Button>
          </Box>
          <ContinueButton
            continueOnboarding={continueOnboarding}
            disabled={false}
          />
        </Box>
      </Box>
    </NotificationsContextProvider>
  );
};

const extractFirstRule = (content: string | null): string => {
  if (!content) return '';
  
  const firstMarkerIndex = content.indexOf('###');
  if (firstMarkerIndex === -1) return content;
  
  const secondMarkerIndex = content.indexOf('###', firstMarkerIndex + 3);
  
  if (secondMarkerIndex === -1) return content.substring(firstMarkerIndex);
  
  return content.substring(firstMarkerIndex, secondMarkerIndex).trim();
};

export default WriteGuidelines;
