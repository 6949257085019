import { useCallback, useContext, useEffect, useState } from "react";
import { Typography, Box } from "@mui/material";
import { iNotification, useNotifications } from "@/contexts/NotificationContextProvider";
import LoadingBox from "./LoadingBox";
import { Context } from "@/contexts/ContextProvider";

interface PastCommentsAnalysisProps {
  loadingPRAnalysis: boolean;
  successPRAnalysis: boolean | null;
  setSuccessPRAnalysis: (success: boolean) => void;
  setLoadingPRAnalysis: (loading: boolean) => void;
  repoId: string;
}

const PastCommentsAnalysis: React.FC<PastCommentsAnalysisProps> = ({
  loadingPRAnalysis,
  successPRAnalysis,
  setSuccessPRAnalysis,
  setLoadingPRAnalysis,
  repoId
}) => {
  const { showMessage } = useContext(Context);
  const { subscribeToNotifications, unsubscribeFromNotifications } = useNotifications();

  const handlePRAnalysisNotification = useCallback((notification: iNotification) => {
    if (notification.data.action !== "new_rule_created" || notification.data.repo_id !== repoId) return;
    showMessage("success", "New rule created successfully");
    setSuccessPRAnalysis(true);
    setLoadingPRAnalysis(false);
  }, [repoId, showMessage, setSuccessPRAnalysis, setLoadingPRAnalysis]);

  useEffect(() => {
    subscribeToNotifications(handlePRAnalysisNotification);
    return () => {
      unsubscribeFromNotifications(handlePRAnalysisNotification);
    };
  }, [handlePRAnalysisNotification, subscribeToNotifications, unsubscribeFromNotifications]);

  return (
    <>
      {loadingPRAnalysis && successPRAnalysis === null && (
        <LoadingBox message="We are looking back to your PR comments to get some rules" />
      )}
      {successPRAnalysis === true && (
        <Box sx={{ 
          display: 'flex', 
          alignItems: 'center', 
          justifyContent: 'space-between', 
          backgroundColor: 'transparent', 
          padding: 2, 
          borderRadius: '8px', 
          border: '1px solid #30363d'
        }}>
        <Typography variant="body1" color="text.primary">
          We found a relevant comment in your PR and made a guideline from it.
        </Typography>
        </Box>
      )}
      {successPRAnalysis === false && (
        <Box sx={{ 
          display: 'flex', 
          alignItems: 'center', 
          justifyContent: 'space-between', 
          backgroundColor: 'transparent', 
          padding: 2, 
          borderRadius: '8px', 
          border: '1px solid #30363d'
        }}>
          <Typography variant="body1" color="text.primary">
            By the moment we didn't find a relevant comment to make a guideline, but we will keep looking for them deeper.
          </Typography>
        </Box>
      )}
    </>
  );
};

export default PastCommentsAnalysis;
