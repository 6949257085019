import { createContext, useContext, useEffect, useState } from "react";

export const OnboardingContext = createContext<OnboardingContextType>({} as OnboardingContextType);

export enum WikiOnboardingSource {
  PR_ANALYSIS = "pr_comments_analysis",
  CURSOR_RULES = "cursor_rules",
  MANUAL_GUIDELINES = "manual_guidelines"
}

interface OnboardingContextType {
  wikiOnboardingSource: WikiOnboardingSource | null;
  setWikiOnboardingSource: (source: WikiOnboardingSource | null) => void;
}

export const OnboardingProvider = ({ children }: { children: React.ReactNode }) => {
  const [wikiOnboardingSource, setWikiOnboardingSource] = useState<WikiOnboardingSource | null>(null);

  return <OnboardingContext.Provider value={{ wikiOnboardingSource, setWikiOnboardingSource }}>{children}</OnboardingContext.Provider>;
};

export const useOnboarding = () => {
  const context = useContext(OnboardingContext);
  if (!context) {
    throw new Error('useOnboarding must be used within a OnboardingProvider');
  }
  return context;
};
