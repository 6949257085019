export const getRelativeTimeString = (dateString: string): string => {
  const date = new Date(dateString);

  if (isNaN(date.getTime())) {
    return 'Invalid date';
  }

  const now = new Date();
  const diffInSeconds = Math.floor((now.getTime() - date.getTime()) / 1000);
  
  const intervals = {
    year: 31536000,
    month: 2592000,
    week: 604800,
    day: 86400,
    hour: 3600,
    minute: 60
  };
  
  if (diffInSeconds < 0) {
    return "in the future";
  }
  
  if (diffInSeconds < 60) {
    return "just now";
  }
  
  let count: number;
  let intervalName: string;
  
  if (diffInSeconds >= intervals.year) {
    count = Math.floor(diffInSeconds / intervals.year);
    intervalName = count === 1 ? "year" : "years";
  } else if (diffInSeconds >= intervals.month) {
    count = Math.floor(diffInSeconds / intervals.month);
    intervalName = count === 1 ? "month" : "months";
  } else if (diffInSeconds >= intervals.week) {
    count = Math.floor(diffInSeconds / intervals.week);
    intervalName = count === 1 ? "week" : "weeks";
  } else if (diffInSeconds >= intervals.day) {
    count = Math.floor(diffInSeconds / intervals.day);
    intervalName = count === 1 ? "day" : "days";
  } else if (diffInSeconds >= intervals.hour) {
    count = Math.floor(diffInSeconds / intervals.hour);
    intervalName = count === 1 ? "hour" : "hours";
  } else {
    count = Math.floor(diffInSeconds / intervals.minute);
    intervalName = count === 1 ? "minute" : "minutes";
  }
  
  return `${count} ${intervalName} ago`;
};
