import React, { useState, useEffect, useContext } from "react";
import { 
  Button, Typography, Box, TableCell, TableRow, 
  TableBody, Link, TableHead, Table 
} from "@mui/material";
import { Repo } from "@/pages/Settings/components/Repos/types";
import { list_repos } from "@/services/Blar/Repo_graph";
import { getOpenPullRequests, postRunPRReview } from "@/services/Blar/Starter";
import ContinueButton from "@/components/Buttons/ContinueButton";
import BackButton from "@/components/Buttons/BackButton";
import useStyles from "./styles/Guidelines";
import { NotificationsContextProvider } from "@/contexts/NotificationContextProvider";
import GetPRReviewNotification from "./components/GetPRReviewNotification";
import { Context } from "@/contexts/ContextProvider";
import LoadingTypography from "./components/LoadingTypography";

interface RepoPaginatedResponse {
  results: Repo[];
}

interface ReviewFirstPRProps {
    continueOnboarding: () => void;
    backOnboarding: () => void;
  }

interface PullRequest {
  branch: string;
  date: string;
  pr_number: number;
  title: string;
  link: string;
  status?: string;
}

const ReviewFirstPRComponent: React.FC<ReviewFirstPRProps> = ({
    continueOnboarding,
    backOnboarding,
  }) => {
    
  const [isLoading, setIsLoading] = useState(true);
  const [selectedRepo, setSelectedRepo] = useState<Repo | null>(null);
  const [pullRequests, setPullRequests] = useState<PullRequest[]>([]);
  const [inProgressReviewPRNumber, setInProgressReviewPRNumber] = useState<number | null>(null);
  const { showMessage, access, refresh } = useContext(Context);
  const classes = useStyles();

  useEffect(() => {
    fetchData();
  }, []); 

  const handleReviewPR = async (prNumber: number) => {
    try {
      if (!selectedRepo) return;
      setInProgressReviewPRNumber(prNumber);
      
      // Update the status of the PR in the UI
      setPullRequests(prevPRs => 
        prevPRs.map(pr => 
          pr.pr_number === prNumber 
            ? { ...pr, status: 'reviewing' } 
            : pr
        )
      );
      
      await postRunPRReview({
        repo_name: selectedRepo.name,
        pr_number: prNumber
      });
    } catch (error) {
      console.error('Error triggering PR review:', error);
    }
  };
  
  const fetchData = async () => {
    try {
      setIsLoading(true);
      const reposResponse = await list_repos();
      const repoData = reposResponse.data as RepoPaginatedResponse;
      
      const selected = repoData.results[0];
      setSelectedRepo(selected);

      const openPullRequests = await getOpenPullRequests(selected.repo_id);
      
      setPullRequests(openPullRequests);
      console.log("openPullRequests", openPullRequests);
      
      // Find any PR that is not in 'not reviewed' status
      const inProgressPR = openPullRequests.find((pr: PullRequest) => pr.status && pr.status !== 'not reviewed');
      console.log("inProgressPR", inProgressPR);
      if (inProgressPR) {
        setInProgressReviewPRNumber(inProgressPR.pr_number);
      } else {
        // If all PRs are 'not reviewed', set to null to enable all buttons
        setInProgressReviewPRNumber(null);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false);
    }
  };



  return (
    <Box sx={{ 
      height: "100%",
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "space-between",
      padding: 4


    }}>
      <Box>
        <LoadingTypography loading={isLoading} stepCompleted={false} text="Your first Pull Request review" />
        <Typography 
          variant="subtitle1" 
          sx={{ 
            color: 'white', 
            textAlign: 'center', 
            mb: 2,
            maxWidth: '700px',
            lineHeight: 1.5,
            opacity: 0.9,
            fontSize: '0.95rem'
          }}
        >
          You're all set! Blar will review your tracked PRs, offering smart insights and suggestions. <br />
          But why not give Blar a try?  Select a PR below or start exploring! 
        </Typography>
      </Box>
      <Table sx={{ 
        '& .MuiTableCell-root': { 
          borderColor: '#30363d',
          color: 'white',
          tableLayout: 'fixed',
          width: '100%',
          padding: '8px',
        }
      }}>
        <TableHead>
          <TableRow sx={{
            display: 'table',
            width: '100%',
            tableLayout: 'fixed'
          }}>
            <TableCell>Title</TableCell>
            <TableCell>Branch</TableCell>
            <TableCell >Created</TableCell>
            <TableCell >Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody sx={{
          display: 'block',
          maxHeight: '200px',
          overflow: 'auto',
          '& tr': {
            display: 'table',
            width: '100%',
            tableLayout: 'fixed'
          }
        }}>
          {pullRequests.length > 0 ? (
            pullRequests.map((pr) => (
              <TableRow key={pr.pr_number}>
                <TableCell>
                  <Link
                    href={pr.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{
                      color: '#58a6ff',
                      textDecoration: 'none',
                      '&:hover': {
                        textDecoration: 'underline'
                      }
                    }}
                  >
                    {pr.title} #{pr.pr_number}
                  </Link>
                </TableCell>
                <TableCell>{pr.branch}</TableCell>
                <TableCell>
                  {new Date(pr.date).toLocaleDateString('en-GB', {
                    month: 'short',
                    day: 'numeric',
                    year: 'numeric'
                  })}
                </TableCell>
                <TableCell>
                  <Button
                    variant="outlined"
                    onClick={() => handleReviewPR(pr.pr_number)}
                    color="secondary"
                    disabled={inProgressReviewPRNumber !== null}
                    sx={{
                      backgroundColor: pr.status === 'reviewing' ? 'green' : 'secondary',
                      '&:hover': {
                        backgroundColor: pr.status === 'reviewing' ? 'green' : 'secondary'
                      },
                      textTransform: 'none',
                      fontSize: '0.875rem',
                      color: pr.status === 'reviewing' ? 'white' : undefined
                    }}
                  >
                    {pr.status === 'reviewing' 
                      ? "Reviewing PR..." 
                      : inProgressReviewPRNumber !== null && pr.pr_number !== inProgressReviewPRNumber
                        ? "Not reviewed" 
                        : "Review this PR"}
                  </Button>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={5} align="center" sx={{ py: 3 }}>
                No open pull requests
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <Box sx={{ width: '100%', mt: 2 }}>
        <NotificationsContextProvider access={access} refresh={refresh}>
          <GetPRReviewNotification 
            repoId={selectedRepo?.repo_id || ""} 
            inProgressReviewPRNumbers={inProgressReviewPRNumber ? [inProgressReviewPRNumber] : []}
          />
        </NotificationsContextProvider>
      </Box>

      <Box className={classes.buttonContainer}>
            <BackButton backOnboarding={backOnboarding} />
            <Button
            variant="contained"
            color="secondary"
          onClick={continueOnboarding}
          sx={{
            fontSize: '20px',
            margin: 0
          }}
        >
          Explore Blar's platform!
        </Button>
      </Box>
    </Box>
  );
};

export default ReviewFirstPRComponent;