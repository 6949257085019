import { Box, Container, Typography, Button, CircularProgress } from "@mui/material";
import { useState, useEffect, useContext } from 'react';
import { get_code_errors } from '../../services/Blar/CodeErrors';
import { list_users } from '../../services/Blar/Company';
import { User } from '../../interfaces/ICompany';
import { list_repos } from '../../services/Blar/Repo_graph';
import AiReportDrawer from './components/AiReportDrawer';
import ErrorsFilters from './components/ErrorsFilters';
import ErrorsTable from './components/ErrorsTable';
import { check_integration_exists, check_code_error_config_exists } from "@/services/Blar/Integrations";
import { IntegrationType } from "@/interfaces/IIntegration";
import IntegrateSentry from "./components/NoSentryIntegration";
import NoSentryProjectsConfig from "./components/NoSentryProjectsConfig";
import SentryProjectsModal from "./components/SentryProjectsModal";
import { Context } from "@/contexts/ContextProvider";
export interface CodeError {
  title: string;
  message: string;
  state: 'open' | 'acknowledged' | 'closed';
  count: number;
  first_seen: string;
  assigned_to: string;
  repo: string;
  ai_report: string | null;
  ai_solution: string | null;
  ai_identified_author: string | null;
  ai_test: string | null;
  trace: any;
}

interface AiResponse {
  report: string;
  solution: string;
  test: string | null;
  trace: any | null | [];
}

const CodeErrors: React.FC = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedState, setSelectedState] = useState('');
  const [selectedAssignee, setSelectedAssignee] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [selectedRepo, setSelectedRepo] = useState('');
  const [errors, setErrors] = useState<CodeError[]>([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [users, setUsers] = useState<User[]>([]);
  const [repos, setRepos] = useState<any[]>([]);
  const [aiResponse, setAiResponse] = useState<AiResponse | null>(null);
  const [existIntegration, setExistIntegration] = useState<boolean | undefined>();
  const [existCodeErrorConfig, setExistCodeErrorConfig] = useState<boolean | undefined>();
  const [isSentryConfigOpen, setIsSentryConfigOpen] = useState(false);
  const [loadingRequirements, setLoadingRequirements] = useState(true);
  const { showMessage } = useContext(Context);
  
  const fetchRepos = async () => {
    try {
      const response = await list_repos();
      setRepos(response.data?.results || []);
    } catch (err) {
      showMessage('error', 'Failed to fetch repositories');
      console.error(err);
    }
  };
  
  const fetchUsers = async () => {
    try {
      const response = await list_users({ limit: 100, offset: 0 }, {});
      setUsers(response.data.results);
    } catch (err) {
      showMessage('error', 'Failed to fetch users');
      console.error(err);
    }
  };
  
  useEffect(() => {
    fetchUsers();
    fetchRepos();
  }, []);
  
  const fetchExistIntegration = async () => {
    try {
      const response = await check_integration_exists(IntegrationType.SENTRY);
      setExistIntegration(response.data);
    } catch (err) {
      showMessage('error', 'Failed to check Sentry integration');
      console.error(err);
    }
  };
  
  const fetchExistCodeErrorConfig = async () => {
    try {
      const response = await check_code_error_config_exists();
      setExistCodeErrorConfig(response.data.is_configured);
    } catch (err) {
      showMessage('error', 'Failed to check code error configuration');
      console.error(err);
    }
  };
  
  useEffect(() => {
    const fetchRequirements = async () => {
      setLoadingRequirements(true);
      try {
        await fetchExistIntegration();
        await fetchExistCodeErrorConfig();
      } catch (err) {
        console.error(err);
      } finally {
        setLoadingRequirements(false);
      }
    };

    fetchRequirements();
  }, []);
  
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  
  const handleRowClick = (error: CodeError) => {
    if (error.ai_report && error.ai_solution) {
      const trace = error.trace
      
      setAiResponse({
        report: error.ai_report,
        solution: error.ai_solution,
        test: error.ai_test,
        trace: trace
      });
    } else {
      setAiResponse(null);
      showMessage('error', 'No AI report or solution available for this error, Blar is working on it!');
    }
  };
  
  useEffect(() => {
    const fetchErrors = async () => {
      try {
        setLoading(true);
        setPage(0);
        const data = await get_code_errors({
          search: searchQuery,
          state: selectedState,
          assigned_to: selectedAssignee,
          start_date: startDate,
          end_date: endDate,
          repo: selectedRepo,
        });
        setErrors(data);
      } catch (err) {
        showMessage('error', 'Failed to fetch code errors');
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchErrors();
  }, [searchQuery, selectedState, selectedAssignee, startDate, endDate, selectedRepo]);

  const handleConfigSuccess = async () => {
    // Refresh config check and errors list
    const configResponse = await check_code_error_config_exists();
    setExistCodeErrorConfig(configResponse.data.is_configured);
    
    // Re-fetch errors
    try {
      setLoading(true);
      const data = await get_code_errors({
        search: searchQuery,
        state: selectedState,
        assigned_to: selectedAssignee,
        start_date: startDate,
        end_date: endDate,
        repo: selectedRepo,
      });
      setErrors(data);
    } catch (err) {
      showMessage('error', 'Failed to fetch code errors');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="lg" sx={{ py: 4, px: 4 }}>
      <Box sx={{ width: "100%", mb: 2 }}>
        <Box sx={{ mb: 4 }}>
          <Typography variant="h4" fontWeight={"medium"} sx={{ color: "white", textAlign: "left" }}>
            Code Errors Insights
          </Typography>
          <Typography variant="body1" sx={{ color: "white", textAlign: "left" }}>
            Review the errors analyzed by Blar to uncover the root causes and recommend fixes.
          </Typography>
        </Box>
        {(loadingRequirements || existIntegration === undefined) && loadingRequirements ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '200px' }}>
            <CircularProgress />
          </Box>
        ) : !existIntegration ? (
          <IntegrateSentry />
        ) : !existCodeErrorConfig ? (
          <NoSentryProjectsConfig onConfigSuccess={handleConfigSuccess} />
        ) : (
          <>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
              <Button 
                variant="contained" 
                onClick={() => setIsSentryConfigOpen(true)}
                sx={{ mb: 3, mr: 0 }}
              >
                Configure Sentry Projects
              </Button>
            </Box>
            <ErrorsFilters
              searchQuery={searchQuery}
              selectedState={selectedState}
              selectedAssignee={selectedAssignee}
              startDate={startDate}
              endDate={endDate}
              selectedRepo={selectedRepo}
              users={users}
              repos={repos}
              onSearchChange={setSearchQuery}
              onStateChange={setSelectedState}
              onAssigneeChange={setSelectedAssignee}
              onStartDateChange={setStartDate}
              onEndDateChange={setEndDate}
              onRepoChange={setSelectedRepo}
            />
            <ErrorsTable
              errors={errors}
              loading={loading}
              users={users}
              repos={repos}
              page={page}
              rowsPerPage={rowsPerPage}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              onRowClick={handleRowClick}
            />
          </>
        )}
      </Box>
      <AiReportDrawer
        aiResponse={aiResponse}
        onClose={() => setAiResponse(null)}
      />
      <SentryProjectsModal
        open={isSentryConfigOpen}
        onClose={() => setIsSentryConfigOpen(false)}
        onUpdateSuccess={handleConfigSuccess}
      />
    </Container>
  );
};

export default CodeErrors;
