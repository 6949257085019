import React, { useState } from "react";
import BaseModal from "./BaseModal";
import { Button, Typography, Box } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

interface ImportCursorRulesProps {
  open: boolean;
  onClose: () => void;
  loadingCursor: boolean;
  onSubmit: () => void;
  onFileSelect?: (file: File | null) => void;
}

const ImportCursorRules: React.FC<ImportCursorRulesProps> = ({
  open,
  onClose,
  loadingCursor,
  onSubmit,
  onFileSelect
}) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setSelectedFile(file);
      if (onFileSelect) {
        onFileSelect(file);
      }
    }
  };

  const handleDirectorySelect = async () => {
    try {
      // @ts-ignore - TypeScript doesn't have types for experimental API
      const directoryHandle = await window.showDirectoryPicker();
      const cursorDir = await directoryHandle.getDirectoryHandle('.cursor', { create: false });
      const rulesDir = await cursorDir.getDirectoryHandle('rules', { create: false });
      
      for await (const entry of rulesDir.values()) {
        if (entry.kind === 'file' && entry.name.endsWith('.mdc')) {
          const file = await entry.getFile();
          setSelectedFile(file);
          if (onFileSelect) {
            onFileSelect(file);
          }
          break;
        }
      }
    } catch (error) {
      console.error('Error accessing directory:', error);
      alert('Could not find .cursor/rules directory with MDC files');
    }
  };

  return (
    <BaseModal
      open={open}
      onClose={onClose}
      title="Add Rules from Cursor"
      actions={
        <>
          <Button 
            onClick={onClose}
            variant="outlined"
            sx={{ minWidth: '120px' }}
          >
            Cancel
          </Button>
          <Button 
            variant="contained"
            sx={{ minWidth: '120px' }}
            onClick={onSubmit}
            disabled={loadingCursor}
          >
            {loadingCursor ? 'Importing...' : 'Import Rules'}
          </Button>
        </>
      }
    >
      <Typography variant="body1" color="text.secondary" sx={{ mb: 3 }}>
        We can import your Cursor rules to automatically generate coding guidelines for your team.
        Select the repository directory or upload an .mdc file directly.
      </Typography>
      
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
        <Box sx={{ width: '100%' }}>
          <Button
            variant="outlined"
            onClick={handleDirectorySelect}
            startIcon={<CloudUploadIcon />}
            sx={{ width: '100%', py: 1.5 }}
            fullWidth
          >
            Select Repository Directory
          </Button>
        </Box>
        
        <Typography variant="body2" color="text.secondary">OR</Typography>
        
        <Box sx={{ width: '100%' }}>
          <input
            accept=".mdc"
            style={{ display: 'none' }}
            id="cursor-file-upload"
            type="file"
            onChange={handleFileChange}
          />
          <label htmlFor="cursor-file-upload" style={{ width: '100%', display: 'block' }}>
            <Button
              variant="outlined"
              component="span"
              startIcon={<CloudUploadIcon />}
              sx={{ width: '100%', py: 1.5 }}
              fullWidth
            >
              Select .mdc File
            </Button>
          </label>
        </Box>
        
        {selectedFile && (
          <Typography variant="body2" color="primary" sx={{ mt: 1 }}>
            Selected: {selectedFile.name}
          </Typography>
        )}
      </Box>
    </BaseModal>
  );
};

export default ImportCursorRules;
