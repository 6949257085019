import React, { useState, useEffect, useMemo } from "react";
import ReactFlow, {
    Edge,
    ReactFlowProvider,
    Controls,
    Node as RFNode,

} from "reactflow";
import "reactflow/dist/style.css";
import dagre from "dagre";
import { CircularProgress, Box, Typography } from "@mui/material";
import { getGraphData } from "@/services/Blar/Agents";
import CustomNode from "./CustomNode";
import NodeInfoPanel from "./NodeInfoPanel";

// Extend the ReactFlow Node type to include additional properties.
export interface MyNode extends RFNode {
    label: string;
    node_path?: string;
    file_path?: string | null;
    start_line?: number;
    end_line?: number;
    text?: string;
    diff_text?: string;
    file_node_id?: string | null;
    labels?: string[];
    relevant?: boolean;
    // Make position required:
    position: { x: number; y: number };
}


interface GraphViewProps {
    reportId: string;
}

const nodeWidth = 172;
const nodeHeight = 36;

export const getLayoutedNodes = (
    nodes: MyNode[],
    edges: Edge[],
    direction: "TB" | "LR" = "TB"
): MyNode[] => {
    const dagreGraph = new dagre.graphlib.Graph();
    dagreGraph.setDefaultEdgeLabel(() => ({}));
    dagreGraph.setGraph({ rankdir: direction });

    nodes.forEach((node) => {
        dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });
    });

    edges.forEach((edge) => {
        dagreGraph.setEdge(edge.source, edge.target);
    });

    dagre.layout(dagreGraph);

    return nodes.map((node) => {
        const nodeWithPosition = dagreGraph.node(node.id);
        return {
            ...node,
            type: "custom",
            position: {
                x: nodeWithPosition.x - nodeWidth / 2,
                y: nodeWithPosition.y - nodeHeight / 2,
            },
            data: { ...(node.data || {}), label: node.label || "No Name", relevant: node.relevant || false },
        };
    });
};

const GraphView: React.FC<GraphViewProps> = ({ reportId }) => {
    const [graphData, setGraphData] = useState<{ nodes: MyNode[]; edges: Edge[] } | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [selectedNode, setSelectedNode] = useState<MyNode | null>(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const response = await getGraphData(reportId);
                // Assuming response.data contains { nodes, edges }
                setGraphData(response.data);
            } catch (err) {
                console.error(err);
                setError("Failed to load graph data.");
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [reportId]);

    // Ensure each node has a default position.
    const nodesWithDefault = useMemo(() => {
        if (!graphData) return [];
        return graphData.nodes.map((node) => {
            if (!node.position || typeof node.position.x !== "number" || typeof node.position.y !== "number") {
                return { ...node, position: { x: 0, y: 0 } };
            }
            return node;
        });
    }, [graphData]);

    // Add the label to the node's data so that it is displayed.
    const nodesWithLabel = useMemo(() => {
        return nodesWithDefault.map((node) => ({
            ...node,
            data: { ...(node.data || {}), label: node.label || "No Name" },
        }));
    }, [nodesWithDefault]);

    // Compute layout using dagre.
    const layoutedNodes = useMemo(() => {
        if (!graphData) return [];
        return getLayoutedNodes(nodesWithLabel, graphData.edges, "TB");
    }, [nodesWithLabel, graphData]);

    const handlePaneClick = () => {
        setSelectedNode(null);
    };

    if (loading) {
        return (
            <Box sx={{ height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <CircularProgress />
            </Box>
        );
    }

    if (error) {
        return (
            <Box sx={{ height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Typography color="error">{error}</Typography>
            </Box>
        );
    }

    if (!graphData) return null;

    return (
        <ReactFlowProvider>
            <ReactFlow
                nodeTypes={{ custom: CustomNode }}
                nodes={layoutedNodes}
                edges={graphData.edges}
                fitView
                nodesDraggable
                onNodeClick={(_, node) => setSelectedNode(node as MyNode)}
                onPaneClick={handlePaneClick}
            >
                {selectedNode && <NodeInfoPanel node={selectedNode} />}
                <Controls />
            </ReactFlow>
        </ReactFlowProvider>
    );
};

export default GraphView;
