import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination, Typography, Skeleton } from "@mui/material";
import { styled } from "@mui/material/styles";
import { User } from '../../../interfaces/ICompany';
import StateChip from './StateChip';
import { CodeError } from '../CodeErrors';
// Styled Components
const StyledTableHeadCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: '#161B22',
  color: '#fff',
  fontWeight: 600,
  borderBottom: '1px solid #30363d',
  whiteSpace: 'nowrap'
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  color: '#fff',
  borderBottom: '1px solid #30363d',
  padding: '16px',
  '& .truncate-text': {
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  backgroundColor: '#161B22',
  '&:hover': {
    backgroundColor: 'transparent',
    cursor: 'pointer',
  },
}));

interface ErrorsTableProps {
  errors: CodeError[];
  loading: boolean;
  users: User[];
  repos: any[];
  page: number;
  rowsPerPage: number;
  onPageChange: (event: unknown, newPage: number) => void;
  onRowsPerPageChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onRowClick: (error: CodeError) => void;
}

const ErrorsTable: React.FC<ErrorsTableProps> = ({
  errors,
  loading,
  users,
  repos,
  page,
  rowsPerPage,
  onPageChange,
  onRowsPerPageChange,
  onRowClick,
}) => {
  const skeletonCount = 5;

  return (
    <>
      <TableContainer 
        component={Paper} 
        sx={{ 
          backgroundColor: 'transparent',
          borderRadius: '10px',
          overflowY: 'auto',
          border: '1px solid #30363d',
          padding: 0
        }}
      >
        <Table stickyHeader sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              {/* <StyledTableHeadCell>Title</StyledTableHeadCell> */}
              <StyledTableHeadCell>Message</StyledTableHeadCell>
              <StyledTableHeadCell>Status</StyledTableHeadCell>
              <StyledTableHeadCell>Recurrence</StyledTableHeadCell>
              <StyledTableHeadCell>First Seen</StyledTableHeadCell>
              <StyledTableHeadCell>Repo</StyledTableHeadCell>
              <StyledTableHeadCell>Suggested Author</StyledTableHeadCell>
              {/* <StyledTableHeadCell>Assignee</StyledTableHeadCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              Array(skeletonCount).fill(0).map((_, index) => (
                <StyledTableRow key={`skeleton-${index}`}>
                  {Array(7).fill(0).map((_, colIndex) => (
                    <StyledTableCell key={colIndex}>
                      <Skeleton 
                        variant="rectangular" 
                        width="100%" 
                        height={20} 
                        sx={{ 
                          backgroundColor: '#1c2128',
                          borderRadius: '4px'
                        }} 
                      />
                    </StyledTableCell>
                  ))}
                </StyledTableRow>
              ))
            ) : errors.length === 0 ? (
              <StyledTableRow>
                <StyledTableCell colSpan={7} sx={{ textAlign: 'center', py: 4 }}>
                  <Typography variant="body1" sx={{ color: '#6e7681' }}>
                    No code errors found matching your criteria
                  </Typography>
                </StyledTableCell>
              </StyledTableRow>
            ) : (
              errors
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((error, index) => (
                  <StyledTableRow 
                    key={index} 
                    hover
                    onClick={() => onRowClick(error)}
                    sx={{
                      '&:hover': {
                        cursor: 'pointer',
                      }
                    }}
                  >
                    {/* <StyledTableCell>
                      <div className="truncate-text">{error.title}</div>
                    </StyledTableCell> */}
                    <StyledTableCell>
                      <div className="truncate-text">{error.message}</div>
                    </StyledTableCell>
                    <StyledTableCell>
                      <StateChip state={error.state} />
                    </StyledTableCell>
                    <StyledTableCell>{error.count}</StyledTableCell>
                    <StyledTableCell>
                      {new Date(error.first_seen).toLocaleDateString()}
                    </StyledTableCell>
                    <StyledTableCell>
                      {repos.find(r => r.repo_id === error.repo)?.name || 'Unknown'}
                    </StyledTableCell>
                    <StyledTableCell>
                      {error.ai_identified_author || '-'}
                    </StyledTableCell>
                    {/* <StyledTableCell>
                      {users.find(u => Number(u.id) === Number(error.assigned_to))?.email || 'Unassigned'}
                    </StyledTableCell> */}
                  </StyledTableRow>
                ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {/* {!loading && errors.length > 0 && (
        <TablePagination
          component="div"
          count={errors.length}
          page={page}
          onPageChange={onPageChange}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={onRowsPerPageChange}
          rowsPerPageOptions={[10, 20, 30]}
          sx={{
            mt: 1,
            color: 'white',
            '.MuiTablePagination-select': {
              color: 'white'
            },
            '.MuiTablePagination-selectIcon': {
              color: 'white'
            },
            '.MuiTablePagination-displayedRows': {
              color: 'white'
            },
            '.MuiTablePagination-actions': {
              color: 'white'
            }
          }}
        />
      )} */}
    </>
  );
};

export default ErrorsTable; 