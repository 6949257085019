import React, { useState, useEffect } from "react";
import { Box, Typography, Paper, useTheme, FormControl, InputLabel, Select, MenuItem, SelectChangeEvent, IconButton, Button } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useWikiFileStructure } from "../context/WikiFileStructureContext";
import { useWiki } from "../context/WikiContext";
import { getFolderContents } from "@/services/Blar/Wiki";
import CursorRulesImportContent from "./CursorRulesImportContent";
import GithubImportContent from "./GithubImportContent";

// Define view types for navigation
type ViewType = 'main' | 'github-import' | 'cursor-import';

const CreateFileCard: React.FC = () => {
  const [creatingFile, setCreatingFile] = useState<string | null>(null);
  const [currentView, setCurrentView] = useState<ViewType>('main');
  const theme = useTheme();
  const { 
    repoFolders, 
    repoFolder, 
    setRepoFolder, 
    setOpenFolders, 
    parseResponse,
    setStructure,
    updateNodeInStructure,
    handleCreateFile
  } = useWikiFileStructure();
  const { selectedNode, setSelectedNode, setLoadingCount } = useWiki();

  const handleRepoChange = async (event: SelectChangeEvent) => {
    const selectedRepoId = Number(event.target.value);
    const selectedRepo = repoFolders.find(repo => repo.id === selectedRepoId) || null;
    
    if (selectedRepo) {
      try {
        // Set loading state
        setLoadingCount((prev) => prev + 1);
        
        // Update UI state
        setSelectedNode(selectedRepo);
        setRepoFolder(selectedRepo);
        
        // Mark folder as open
        setOpenFolders((prev) => ({
          ...prev,
          [selectedRepo.id]: true
        }));
        
        // Directly fetch folder contents
        const response = await getFolderContents(selectedRepo.id);
        const parsedChildren = parseResponse(response);
        
        // Update the structure with the fetched contents
        const updatedNode = {
          ...selectedRepo,
          children: parsedChildren,
          fetched: true
        };
        
        setStructure(prev => updateNodeInStructure(prev, updatedNode));
      } finally {
        // Clear loading state
        setLoadingCount((prev) => prev - 1);
      }
    }
  };

  // Handle the case where the user navigates to the page without selecting a repository
  useEffect(() => {
    if (!repoFolder) {
      setCurrentView('main');
    }
  }, [repoFolder]);

  const handleBackClick = () => {
    if (repoFolder) {
      // Close the folder in the UI
      setOpenFolders((prev) => ({
        ...prev,
        [repoFolder.id]: false
      }));
    }
    
    // Reset the state
    setRepoFolder(null);
    setSelectedNode(null);
  };

  // Navigation handlers
  const handleImportFromGithub = () => {
    setCurrentView('github-import');
  };

  const handleImportFromCursor = () => {
    setCurrentView('cursor-import');
  };

  const handleBackToMain = () => {
    setCurrentView('main');
  };

  const handleStartFromScratch = async () => {
    if (creatingFile) return;
    
    try {
      setCreatingFile('scratch');
      await handleCreateFile("Style Guide", selectedNode!.id, "");
    } catch (error) { 
      console.error('Error creating empty file:', error);
    } finally {
      setCreatingFile(null);
    }
  };

  // Calculate the select value
  const selectValue = repoFolder ? String(repoFolder.id) : "";

  // Render the appropriate view based on currentView state
  const renderContent = () => {
    switch (currentView) {
      case 'github-import':
        return (
          <GithubImportContent 
            onBack={handleBackToMain} 
            repoFolderName={repoFolder?.name}
          />
        );
      case 'cursor-import':
        return (
          <CursorRulesImportContent 
            onBack={handleBackToMain} 
            repoFolderName={repoFolder?.name}
          />
        );
      case 'main':
      default:
        return (
          <Paper
            sx={{
              borderColor: theme.palette.divider,
              width: '100%',
              maxWidth: '900px',
              borderRadius: '10px',
              padding: 4,
            }}
          >
            <Box sx={{ mb: 4, textAlign: 'left' }}>
              {repoFolder ? (
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                  <IconButton 
                    onClick={handleBackClick} 
                    sx={{ mr: 1, p: 0.5 }}
                    aria-label="Go back to repository selection"
                  >
                    <ArrowBackIcon />
                  </IconButton>
                  <Typography variant="h5">
                    Let's get started for {repoFolder.name}
                  </Typography>
                </Box>
              ) : (
                <>
                  <Typography variant="h5" sx={{ mb: 1 }}>
                    Let's get started, select a repository
                  </Typography>
                  <FormControl fullWidth sx={{ mb: 2 }}>
                    <InputLabel id="repo-select-label">Select Repository</InputLabel>
                    <Select
                      labelId="repo-select-label"
                      id="repo-select"
                      value={selectValue}
                      label="Select Repository"
                      onChange={handleRepoChange}
                    >
                      {repoFolders.map((repo) => (
                        <MenuItem key={repo.id} value={repo.id}>
                          {repo.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </>
              )}
              <Typography variant="body1" color="text.secondary" sx={{ mb: 3 }}>
                Adding files to the repository wiki helps facilitate code review in each pull request. We use your established rules, guidelines, and best practices to review and enforce quality standards in your codebase.
              </Typography>
            </Box>

            <Box sx={{ opacity: repoFolder ? 1 : 0.6, pointerEvents: repoFolder ? 'auto' : 'none' }}>
              {/* First Box: Import from GitHub comments */}
              <Box 
                sx={{ 
                  mb: 2, 
                  p: 4, 
                  border: `1px solid ${theme.palette.divider}`,
                  borderRadius: '8px',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Box sx={{ textAlign: 'left', flex: 1, pr: 3 }}>
                  <Typography variant="h6" sx={{ mb: 1, fontWeight: 'medium' }}>
                    Import from past PR comments
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Review your past pull request comments and generate wiki guidelines based on your team's feedback patterns.
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Button 
                    variant="contained" 
                    color="secondary"
                    onClick={handleImportFromGithub}
                    disabled={!repoFolder}
                    size="large"
                  >
                    Let's generate rules
                  </Button>
                </Box>
              </Box>

              {/* Second Box: Import from Cursor rules */}
              <Box 
                sx={{ 
                  mb: 2,
                  p: 4, 
                  border: `1px solid ${theme.palette.divider}`,
                  borderRadius: '8px',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Box sx={{ textAlign: 'left', flex: 1, pr: 3 }}>
                  <Typography variant="h6" sx={{ mb: 1, fontWeight: 'medium' }}>
                    Import from your Cursor rules
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    We know their popularity, so we can create a wiki from your Cursor rules. Select your repository directory to automatically find .mdc files.
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Button 
                    variant="contained" 
                    color="secondary"
                    onClick={handleImportFromCursor}
                    disabled={!repoFolder}
                    size="large"
                  >
                    Import from Cursor
                  </Button>
                </Box>
              </Box>

              {/* Third Box: Write a wiki from scratch */}
              <Box
                sx={{ 
                  p: 4, 
                  border: `1px solid ${theme.palette.divider}`,
                  borderRadius: '8px',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Box sx={{ textAlign: 'left', flex: 1, pr: 3 }}>
                  <Typography variant="h6" sx={{ mb: 1, fontWeight: 'medium' }}>
                    Write a wiki from scratch
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    You can write a wiki from scratch, also you can paste your current documentation from Confluence, Notion, or any other source.
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Button 
                    variant="contained" 
                    color="secondary"
                    onClick={handleStartFromScratch}
                    disabled={!repoFolder}
                    size="large"
                  >
                    Start from scratch
                  </Button>
                </Box>
              </Box>
            </Box>
          </Paper>
        );
    }
  };

  return renderContent();
};

export default CreateFileCard; 