import {
  AddApiKeyParams,
  SentryOauthParams,
  AddSentryWorkaroundParams,
} from "../../pages/Settings/components/Integrations/types";
import { blarClient } from "./Client";
import { AxiosResponse } from "axios";
import { CodeErrorPut } from "@/pages/Chat/components/types";

const PER_PAGE = 30;

interface CheckIntegration {
  is_integrated: boolean;
  git_provider: string;
}

export const check_integration_exists = async (source: string) => {
  return blarClient.request("get", `/integrations/${source}`);
};

export const check_code_git_integration_exists = async (): Promise<AxiosResponse<CheckIntegration>> => {
  return blarClient.request("get", `/integrations/git/check`);
};

export const check_code_error_config_exists = async () => {
  return blarClient.request("get", `/integrations/code-error/config/check`);
};

export const list_sentry_projects = async () => {
  return blarClient.request("get", `/integrations/sentry/list-sentry-projects`);
};

export const save_sentry_projects = async (sentryProjectMap: any) => {
  return blarClient.request("put", `/integrations/sentry/put-sentry-integration-map`, sentryProjectMap);
};

export const sentry_projects_repos = async () => {
  return blarClient.request("get", `/integrations/sentry/project-repo-map`);
};

export const save_api_key = async (params: AddApiKeyParams) => {
  return blarClient.request("post", `/integrations/create`, params);
};

export const save_sentry_workaround = async (
  params: AddSentryWorkaroundParams
) => {
  return blarClient.request("post", `/integrations/sentry/create`, params);
};

export const list_integrations = async () => {
  return blarClient.request("get", `/integrations/list`);
};

export const delete_integration = async (id: string) => {
  return blarClient.request("post", `/integrations/delete`, { id });
};

export const slack_oauth = async () => {
  return blarClient.request("get", `/integrations/slack/oauth/`);
};

export const sentry_oauth = async (payload: SentryOauthParams) => {
  return blarClient.request("post", `/integrations/sentry/oauth`, payload);
};

export const git_oauth = async (code: string, git_provider: string): Promise<any> => {
  return blarClient.request(
    "post",
    `/integrations/git/oauth`,
    {
      code,
      git_provider,
    }
  );
}

export const github_app_installation = async (payload: {
  installation_id: string;
  code: string;
}) => {
  return blarClient.request(
    "post",
    `/integrations/git/app_installation`,
    payload
  );
};

export const git_list_repos = async () => {
  return blarClient.request("get", `/integrations/git/repositories`, null, {
    page: 1,
    per_page: PER_PAGE,
  });
};

export const search_git_repos = async (query: string) => {
  return blarClient.request("get", `/integrations/git/repositories/search`, null, {
    search_query: query,
  });
};

export const git_list_repo_branches = async (repository: string) => {
  return blarClient.request(
    "get",
    `/integrations/git/repositories/branches`,
    undefined,
    {
      repository,
      page: 1,
      per_page: PER_PAGE
    }
  );
};

export const search_git_repo_branches = async (repository: string, query: string) => {
  return blarClient.request(
    "get",
    `/integrations/git/repositories/branches/search`,
    undefined,
    {
      repository,
      search_query: query
    }
  );
};

export const list_user_comments = async (repo_name: string) => {
  const payload = {
    repo_name
  }
  return blarClient.request("get", `/integrations/user-comments`, null, payload);
};

export const generate_ai_content = async (comment: string) => {
  return blarClient.request("post", `/integrations/user-comments/gen-ai-content`, {
    comment
  });
}

export const add_comment_ai_content_to_wiki = async (comment: string) => {
  return blarClient.request("post", `/integrations/user-comments/add-to-wiki`, {
    comment
  });
}

export const reject_comment = async (comment: string) => {
  return blarClient.request("post", `/integrations/user-comments/reject`, {
    comment
  });
}

export const manually_get_user_comments = async (repo_name: string) => {
  return blarClient.request("get", `/integrations/user-comments/manually`, null, {
    repo_name
  });
}

export const git_repository_info = async (
  org: string,
  repo: string
): Promise<AxiosResponse> => {
  return blarClient.request(
    "get",
    `/integrations/git/repositories/${org}/${repo}`
  );
};

export const code_error_put = async (payload: CodeErrorPut) => {
  return blarClient.request("put", `/integrations/code-error`, payload);
};
