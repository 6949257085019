import React, {useState, useEffect, useContext} from 'react';
import { 
  TableContainer, Table, TableHead, 
  TableRow, TableCell, TableBody, Checkbox, Avatar, Paper, TablePagination,
  Skeleton
} from '@mui/material';
import { GitUser } from '../../pages/Auth/types/common';
import { fetchGithubMembers } from '@/services/Blar/Auth';
import { Context } from '@/contexts/ContextProvider';
import { count_selected_seats, select_seat, update_externals_profiles } from '@/services/Blar/Git';

type SelectUsersSeatsProps = {
  onEdit?: () => void
}

const SelectUsersSeats: React.FC<SelectUsersSeatsProps> = ({ onEdit }: SelectUsersSeatsProps) => {
  const [gitUsers, setGitUsers] = useState<GitUser[]>([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [totalCount, setTotalCount] = useState(0);
  const [selectedSeatsCount, setSelectedSeatsCount] = useState<number>(0);
  const { showMessage } = useContext(Context);

  
  const fetchPagedGitMembers = async () => {
    setLoading(true);
    try {
      const usersRequest = await fetchGithubMembers(page + 1, rowsPerPage);
      const users = usersRequest.data.results;
      const total_users = usersRequest.data.total_count;
      setTotalCount(total_users);
      setGitUsers(users);
    } catch (error) {
      showMessage("error", "Failed to fetch Git usernames");
    } finally {
      setLoading(false);
    }
  };

  const countSelectedSeats = async () => {
    try {
      const currentCount = await count_selected_seats()
      setSelectedSeatsCount(currentCount.data.result);
    } catch (error) {
      showMessage("error", "Failed to get selected seats");
    }
  }
  useEffect(() => {
    countSelectedSeats();
    fetchPagedGitMembers();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage]);
  
  useEffect(() => {
    let isMounted = true;
    
    const updateProfiles = async () => {
      try {
        await update_externals_profiles();
        if (isMounted) {
          countSelectedSeats();
          fetchPagedGitMembers();
        }
      } catch (error) {
        if (isMounted) {
          showMessage("error", "Failed to update Git users");
        }
      }
    };
    
    updateProfiles();
    
    return () => {
      isMounted = false;
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePageChange = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleUserSelection = async (user: GitUser, event?: React.MouseEvent) => {
    // Only proceed if this is a click event or no event was provided
    if (event && event.type !== 'click') return;
    
    // Store previous state for rollback if needed
    const previousUsers = [...gitUsers];
    const previousCount = selectedSeatsCount;
    
    try {
      // Make API call
      await select_seat({ seat: user.external_id });
      // Optimistic UI update
      const newSelectedSeatsCount = user.seat_selected ? selectedSeatsCount - 1 : selectedSeatsCount + 1;
      setGitUsers(gitUsers.map((u) => u.external_id === user.external_id ? { ...u, seat_selected: !u.seat_selected } : u));
      setSelectedSeatsCount(newSelectedSeatsCount);
      // Show success message
      showMessage("success", `Successfully ${user.seat_selected ? 'unselected' : 'selected'} seat for ${user.username}`);
    } catch (error) {
      // Rollback on error
      setGitUsers(previousUsers);
      setSelectedSeatsCount(previousCount);
      showMessage("error", "Failed to select user seat");
    }
  };

  const handleToggleAll = async (event?: React.MouseEvent | React.ChangeEvent<HTMLInputElement>) => {
    // Only proceed if this is a click event or no event was provided
    if (event && event.type !== 'click') return;
    
    // Store previous state for rollback if needed
    const previousUsers = [...gitUsers];
    const previousCount = selectedSeatsCount;
    
    const isUnselectingAll = selectedSeatsCount === totalCount;
    
    // Optimistic UI update
    if (isUnselectingAll) {
      setSelectedSeatsCount(0);
      setGitUsers(gitUsers.map((user) => ({ ...user, seat_selected: false })));
    } else {
      setSelectedSeatsCount(totalCount);
      setGitUsers(gitUsers.map((user) => ({ ...user, seat_selected: true })));
    }

    try {
      // Make API call
      await select_seat({ [isUnselectingAll ? 'unselect_all' : 'select_all']: true });
      // Show success message
      showMessage("success", `Successfully ${isUnselectingAll ? 'unselected all' : 'selected all'} seats`);
    } catch (error) {
      // Rollback on error
      setGitUsers(previousUsers);
      setSelectedSeatsCount(previousCount);
      showMessage("error", `Failed to ${isUnselectingAll ? 'unselect' : 'select'} all seats`);
    }
  };

  // Refined useEffect to call onEdit when seat selections change
  useEffect(() => {
    // Only call onEdit if selectedSeatsCount has been initialized (not on first render)
    if (onEdit && typeof onEdit === 'function') {
      onEdit();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSeatsCount]);

  const areAllSelected = totalCount === selectedSeatsCount;
  const someSelected = selectedSeatsCount > 0 && selectedSeatsCount < totalCount;

  // Loading skeleton rows
  const renderSkeletonRows = () => {
    return Array(5).fill(0).map((_, index) => (
      <TableRow key={`skeleton-row-${index}`}>
        <TableCell>
          <Skeleton variant="circular" width={40} height={40} />
        </TableCell>
        <TableCell>
          <Skeleton variant="text" width="80%" height={20} />
        </TableCell>
        <TableCell align="center">
          <Skeleton variant="rectangular" width={24} height={24} sx={{ mx: 'auto' }} />
        </TableCell>
      </TableRow>
    ));
  };

  return (
    <>
      <TableContainer component={Paper} sx={{ minHeight: '300px', maxHeight: '500px' }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Avatar</TableCell>
              <TableCell>Username</TableCell>
              <TableCell align="center">
                Select
                <Checkbox 
                  checked={areAllSelected}
                  indeterminate={someSelected}
                  onChange={(e) => handleToggleAll(e.nativeEvent as unknown as React.MouseEvent)}
                  disabled={loading || gitUsers.length === 0}
                  sx={{ ml: 1 }}
                />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!loading && gitUsers.map((user) => (
              <TableRow 
                key={user.external_id} 
                hover 
                onClick={(e) => handleUserSelection(user, e)}
              >
                <TableCell>
                  <Avatar src={user.avatar_url} alt={user.username}>
                    {user.username?.charAt(0) || '?'}
                  </Avatar>
                </TableCell>
                <TableCell>{user.username}</TableCell>
                <TableCell align="center">
                  <Checkbox 
                    checked={user.seat_selected} 
                    onChange={() => {}} // Handled by row click
                  />
                </TableCell>
              </TableRow>
            ))}
            {!loading && gitUsers.length === 0 && (
              <TableRow key="no-users-found">
                <TableCell colSpan={3} align="center">
                  No users found
                </TableCell>
              </TableRow>
            )}
            {loading && renderSkeletonRows()}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={totalCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
        />
      </TableContainer>
    </>
  );
};

export default SelectUsersSeats;
