import { TableRowData } from "@/components/Tables/types";
import { InvitationLink } from "./types";
import CopyableTextField from "@/components/TextField/CopyableTextField";
import { ExternalProfile } from "@/interfaces/ICompany";
import Avatar from "@mui/material/Avatar";

export function convertUsersToRow(
  data: ExternalProfile[],
  setCreatedObject: (object: number) => void
): TableRowData[] {
  return data.map((user: ExternalProfile) => ({
    _id: { content: user.external_id, type: "hidden" },
    _disabled_key: { content: user.user !== null, type: "hidden" },
    Avatar: { content: (
      <Avatar src={user.avatar_url} alt={user.username}>
        {user.username?.charAt(0) || '?'}
      </Avatar>
    ), type: "react" },
    "Username": {
      content: user.username,
      type: "string",
    },
    "Has Blar User": {
      content: user.user !== null,
      type: "boolean",
    },
  }));
}

export function convertInvitationLinkToRow(
  data: InvitationLink[],
  showMessage: (
    type: "success" | "error" | "info" | "warning",
    message: string
  ) => void,
  baseurl: string
): TableRowData[] {
  return data.map((invitationLink: InvitationLink) => ({
    _id: { content: invitationLink.token, type: "hidden" },
    _token: { content: invitationLink.token, type: "hidden" },
    "Created by": { content: invitationLink.user_email, type: "string" },
    Link: {
      type: "react",
      content: (
        <CopyableTextField
          sx={{ marginBottom: "20px" }}
          text={`${baseurl}/invitation?token=${invitationLink.token}`}
          showMessage={showMessage}
          messageText="Link copied"
        />
      ),
    },
    "Expiration Date": {
      content: new Date(invitationLink.expiry_date),
      type: "date",
      options: { dateFormat: "dd MMMM yyyy HH:mm:SS" },
    },
    Active: {
      content: new Date(invitationLink.expiry_date) > new Date(),
      type: "boolean",
    },
  }));
}
