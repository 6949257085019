import React, { useState, useContext, useEffect } from "react";
import {
  Box,
  CircularProgress,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import GitHubIcon from "@mui/icons-material/GitHub";

import BaseModal from "@/components/Modal/BaseModal";
import { Context } from "@/contexts/ContextProvider";
import BranchSelector from "@/components/Selectors/BranchSelector";
import { Repo, UpdateRepoParams } from "../types";
import { edit_repo } from "@/services/Blar/Repo_graph";

interface EditRepoModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  setCreatedObject: (createdObject: number) => void;
  selectedRepo: Repo;
}

const EditRepoModal = ({
  open,
  setOpen,
  setCreatedObject,
  selectedRepo,
}: EditRepoModalProps) => {
  const [loading, setLoading] = useState(false);
  const { showMessage } = useContext(Context);
  const [selectedBranches, setSelectedBranches] = useState<string[]>(() => {
    // If main and development branches are the same, only include it once
    return selectedRepo.main_branch === selectedRepo.development_branch 
      ? [selectedRepo.main_branch]
      : [selectedRepo.main_branch, selectedRepo.development_branch];
  });

  // Reset branches when selectedRepo changes
  useEffect(() => {
    setSelectedBranches(
      selectedRepo.main_branch === selectedRepo.development_branch
        ? [selectedRepo.main_branch]
        : [selectedRepo.main_branch, selectedRepo.development_branch]
    );
  }, [selectedRepo]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleUpdateRepository = async () => {
    try {
      setLoading(true);
      const updateRepoParams: UpdateRepoParams = {
        repo_id: selectedRepo.repo_id,
        main_branch: selectedBranches[0],
        development_branch: selectedBranches.length === 1 
          ? selectedBranches[0] 
          : selectedBranches[1],
      };
      const updateRepoResponse = await edit_repo(updateRepoParams);
      
      if (updateRepoResponse.status === 204) {
        handleClose();
        setCreatedObject(Date.now());
        showMessage("success", "Repository updated successfully");
      }
    } catch (error) {
      showMessage("error", "Failed to update repository");
    } finally {
      setLoading(false);
    }
  };

  const getRepoFullName = () => {
    // Extract full name from URL (owner/repo)
    const urlParts = selectedRepo.url.split('/');
    return `${urlParts[urlParts.length - 2]}/${urlParts[urlParts.length - 1]}`;
  };

  return (
    <BaseModal
      open={open}
      onClose={handleClose}
      title="Edit Repository Branches"
      style={{ width: "50%" }}
    >
      {loading ? (
        <CircularProgress />
      ) : (
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <BranchSelector
            repositoryFullName={getRepoFullName()}
            selectedBranches={selectedBranches}
            onBranchesChange={setSelectedBranches}
          />

          <LoadingButton
            variant="contained"
            color="secondary"
            startIcon={<GitHubIcon />}
            style={{ fontSize: "20px", margin: "16px 0" }}
            onClick={handleUpdateRepository}
            loading={loading}
            disabled={selectedBranches.length === 0}
          >
            Update Repository
          </LoadingButton>
        </Box>
      )}
    </BaseModal>
  );
};

export default EditRepoModal;
